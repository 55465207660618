import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IDecalType } from 'src/app/core/models/decal-type.model';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class RolloverRepository{
  constructor(private http: HttpClient) {}

  public rolloverDecals(decalList: IDecalType[]): Observable<Response> {
    return this.http.post<Response>(`${environment.apiUrl}/rollover`, decalList);
  }
}
