<h2 data-cy="title">View Vehicle Makes</h2>
<p>This is a list of vehicle makes. To add a new vehicle make, click the "Add Vehicle Make" button at the bottom of the list.</p>

<div class="headerContainer">
  <h3 class="makeColumn">Vehicle Make</h3>
  <h3 class="typeColumn">Vehicle Type</h3>
  <h3 class="editColumn"></h3>
</div>

<ul *ngIf="vehicleMakes">
  <li *ngFor="let make of vehicleMakes" data-cy="vehicle-make">
    <div class="makeColumn" data-cy="vehicle-make-text">{{make.text}}</div>
    <div class="typeColumn" data-cy="vehicle-make-type">{{make.vehicleType.text}}</div>
    <a class="editColumn" id="right" (click)="openModal(make)">
      <img id="edit-icon" src="assets/edit.svg" alt="Edit">
      <span id="edit-text">Edit</span>
    </a>
  </li>
</ul>

<button *ngIf="vehicleMakes" (click)="openModal(undefined)" data-cy="add-vehicle-make">Add Make</button>

<app-modal (closeModalEvent)="closeModal()" *ngIf="isModalOpen" appTrapFocus>
  <form class="modalContent" [formGroup]="makeForm" (ngSubmit)="submitForm()">
    <h3>Enter Vehicle Make</h3>
    <label>Vehicle Type</label>
    <select formControlName="vehicleType" (change)="changeVehicleType(vehicleType)">
      <option *ngFor="let vehicleType of vehicleTypes" [ngValue]="vehicleType">{{vehicleType.text}}</option>
    </select>
    <label>Make</label>
    <input type="text" formControlName="vehicleMake" data-cy="input">
    <button type="submit">Submit</button>
  </form>
</app-modal>
