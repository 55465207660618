<h2 data-cy="title">Edit Text Configuration</h2>

<form [formGroup]="textConfigCodeForm" class="textConfigCodeForm">
  <select formControlName="code" (change)="changeTextConfigCode(code)" data-cy="text-select">
    <option *ngFor="let textBlockSpec of textBlockSpecs" [ngValue]="textBlockSpec.code">{{textBlockSpec.description}}</option>
  </select>
</form>

<div *ngIf="code" data-cy="text-block">
  <app-text-block [editOpen]="!isEditorOpen" [code]=code (_appTextblock)="getAppTextblock($event)"></app-text-block>
</div>

<div *ngIf="isEditorOpen" data-cy="editor">
  <form [formGroup]="textBlockEditorForm" (ngSubmit)="submitTextBlockForm()">
    <ckeditor id="textBlockEditor" formControlName="documentText" [(ngModel)]="appTextblock.documentText" [editor]="Editor" (ngModelChange)="changeTextBlockContent($event)"></ckeditor>
  </form>
</div>

<div class="textConfigBtns">
  <button type="submit" (click)="submitTextBlockForm()" data-cy="submit">Submit</button>
  <button (click)="openEditor()" data-cy="edit">{{isEditorOpen ? 'Cancel' : 'Edit'}}</button>
</div>
