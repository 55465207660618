import { Component, OnInit, Output } from '@angular/core';
import {IVehicleColor} from "../../../core/models/vehicle-color.model";
import {VehicleColorService} from "../../../core/service/vehicle-color.service";
import {UntypedFormBuilder} from "@angular/forms";

@Component({
  selector: 'app-vehicle-color',
  templateUrl: './vehicle-color.component.html',
  styleUrls: ['./vehicle-color.component.scss']
})
export class VehicleColorComponent implements OnInit {
  public vehicleColors: IVehicleColor[] = [];
  public stagedColor: IVehicleColor | undefined;
  public isModalOpen: boolean = false;

  public colorForm = this.formBuilder.group({
    name: ''
  });

  constructor(private vehicleColorService: VehicleColorService, private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.getVehicleColors();
  }

  /**
   * Get list of vehicle colors for staff configuration.
   * @private
   */
  private getVehicleColors(): void {
    this.vehicleColorService.getVehicleColors().subscribe((colors: IVehicleColor[]) => {
      this.vehicleColors = colors;
    });
  }

  /**
   * Opens a modal for creating and editing vehicle colors for submission.
   * @param color
   * */
  public openModal(color: IVehicleColor | undefined) {
    this.isModalOpen = true;
    if (color != undefined) {
      this.stagedColor = color;
      this.colorForm.controls['name'].setValue(color.text);
    }
  }

  /**
   * Close the modal if there is one open in the view.
   */
  public closeModal() {
      this.stagedColor = undefined;
      this.isModalOpen = false;
      this.colorForm.reset();
  }

  /**
   * Submit a form with the staged color if there is one.
   */
  public submitForm(): void {
    if (this.stagedColor) {
      this.vehicleColorService.update({...this.stagedColor, text: this.colorForm.value.name})
        .subscribe((updatedColor: IVehicleColor) => {
          this.vehicleColors = this.vehicleColors.map((color: IVehicleColor) => color.id === updatedColor.id ? updatedColor : color);
          this.closeModal();
      });
    } else {
      this.vehicleColorService.add(this.colorForm.value.name).subscribe((color: IVehicleColor) => {
        this.vehicleColors.push(color);
        this.closeModal();
      })
    }
  }
}
