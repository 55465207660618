<h2 data-cy="title">View Decal Types</h2>

<form id="yearForm" [formGroup]="yearForm">
  <select formControlName="year" class="yearSelect" data-cy="year-select">
    <option *ngFor="let yearSelectOption of yearSelectOptions" [ngValue]="yearSelectOption.year">{{yearSelectOption.range}}</option>
  </select>
  <label class="inactiveDecalType">
  <input type="checkbox" class="input-checkbox" (click)="toggleFilterInactive()" data-cy="inactive-checkbox">
    Include Inactive?
  </label>
</form>

  <div class="headerContainer">
    <h3 class="decalTypeCodeColumn">Code</h3>
    <h3 class="decalTypeDescriptionColumn">Description</h3>
    <h3 class="decalTypePriceColumn">Price</h3>
    <h3 class="decalTypeBillingFreqColumn">Freq</h3>
    <h3 class="decalTypeStudentColumn">Student</h3>
    <h3 class="decalTypeStaffColumn">Staff</h3>
    <h3 class="decalTypeFacultyColumn">Faculty</h3>
    <h3 class="decalTypeRestrictions">RST</h3>
    <h3 class="decalTypeStartEndColumn">ST/END</h3>
    <h3 class="editColumn"></h3>
  </div>

  <ul id="decalType-table">
    <li *ngFor="let decalType of filteredDecalTypes()" [ngClass]="decalType.active === false ? 'inactiveDecalType' : ''" data-cy="decal-type">
      <div class="decalTypeCodeColumn" data-cy="decal-type-code">{{decalType.code}}</div>
      <div class="decalTypeDescriptionColumn" data-cy="decal-type-desc">{{decalType.description}}</div>
      <div class="decalTypePriceColumn">${{decalType.price}}</div>
      <div class="decalTypeBillingFreqColumn">{{decalType.billingFrequency}}</div>
      <div class="decalTypeStudentColumn">{{decalType.students}}</div>
      <div class="decalTypeStaffColumn">{{decalType.staff}}</div>
      <div class="decalTypeFacultyColumn">{{decalType.faculty}}</div>
      <div class="decalTypeRestrictions" data-cy="decal-type-restrictions">
        <a [routerLink]="[decalType.id, 'restriction']">{{decalType.restrictions ? decalType.restrictions.length : '0'}}</a>
      </div>
      <div class="decalTypeStartEndColumn">
        {{decalType.startDate}}
        <br>
        {{decalType.endDate}}
      </div>
      <a class="editColumn" id="right" (click)="openModal(decalType)">
        <img src="assets/edit.svg" alt="Edit" id="edit-icon">
        <span id="edit-text">Edit</span>
      </a>
    </li>
  </ul>

<button *ngIf="decalTypes" (click)="openModal(undefined)" data-cy="add-decal-type">Add Decal Type</button>

<app-modal (closeModalEvent)="closeModal()" *ngIf="isModalOpen" appTrapFocus>
  <form class="modalContent" [formGroup]="decalTypeForm" (ngSubmit)="submitForm()" data-cy="decal-type-form">
    <h3 [innerHtml]="modalTitle"></h3>
    <label>Code</label>
    <input
      type="text"
      formControlName="decalTypeCode"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['decalTypeCode'].errors}"
      required
      data-cy="input-code"
    >
    <div *ngIf="formSubmitted && form['decalTypeCode'].errors" class="invalid-feedback">
      <div *ngIf="form['decalTypeCode'].errors['required']">Please enter a code.</div>
    </div>

    <label>Description</label>
    <input
      type="text"
      formControlName="decalTypeDescription"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['decalTypeDescription'].errors}"
      required
      data-cy="input-desc"
    >
    <div *ngIf="formSubmitted && form['decalTypeDescription'].errors" class="invalid-feedback">
      <div *ngIf="form['decalTypeDescription'].errors['required']">Please enter a description.</div>
    </div>

    <label>First Decal Number</label>
    <input
      type="text"
      formControlName="decalTypeFirstNumber"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['decalTypeFirstNumber'].errors}"
      required
      data-cy="input-first-number"
    >
    <div *ngIf="formSubmitted && form['decalTypeFirstNumber'].errors" class="invalid-feedback">
      <div *ngIf="form['decalTypeFirstNumber'].errors['required']">Please enter a number.</div>
    </div>

    <label>Students</label>
    <fieldset>
      <input type="radio" [value]="true" formControlName="decalTypeStudents" data-cy="input-students-yes">
      <span>Yes</span>
      <input type="radio" [value]="false" formControlName="decalTypeStudents" data-cy="input-students-no">
      <span>No</span>
    </fieldset>
    <label>Staff</label>
    <fieldset>
      <input type="radio" [value]="true" formControlName="decalTypeStaff" data-cy="input-staff-yes">
      <span>Yes</span>
      <input type="radio" [value]="false" formControlName="decalTypeStaff" data-cy="input-staff-no">
      <span>No</span>
    </fieldset>
    <label>Faculty</label>
    <fieldset>
      <input type="radio" [value]="true" formControlName="decalTypeFaculty" data-cy="input-faculty-yes">
      <span>Yes</span>
      <input type="radio" [value]="false" formControlName="decalTypeFaculty" data-cy="input-faculty-no">
      <span>No</span>
    </fieldset>
    <label>Active</label>
    <fieldset>
      <input type="radio" [value]="true" formControlName="decalTypeActive" data-cy="input-active-yes">
      <span>Yes</span>
      <input type="radio" [value]="false" formControlName="decalTypeActive" data-cy="input-active-no">
      <span>No</span>
    </fieldset>
    <label>Start Date</label>
    <input
      type="text"
      formControlName="decalStartDate"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['decalStartDate'].errors}"
      required
      data-cy="input-start-date"
    >
    <div *ngIf="formSubmitted && form['decalStartDate'].errors" class="invalid-feedback">
      <div *ngIf="form['decalStartDate'].errors['required']">Please enter a start date.</div>
    </div>

    <label>End Date</label>
    <input
      type="text"
      formControlName="decalEndDate"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['decalEndDate'].errors}"
      required
      data-cy="input-end-date"
    >
    <div *ngIf="formSubmitted && form['decalEndDate'].errors" class="invalid-feedback">
      <div *ngIf="form['decalEndDate'].errors['required']">Please enter an end date.</div>
    </div>

    <label>Price</label>
    <input
      type="text"
      formControlName="decalTypePrice"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['decalTypePrice'].errors}"
      required
      data-cy="input-price"
    >
    <div *ngIf="formSubmitted && form['decalTypePrice'].errors" class="invalid-feedback">
      <div *ngIf="form['decalTypePrice'].errors['required']">Please enter a number.</div>
    </div>

    <label>Billing Frequency</label>
    <select formControlName="decalTypeBillingFreq" (change)="changeDecalTypeBillingFreq(decalTypeBillingFreq)" data-cy="input-billing">
      <option *ngFor="let decalTypeBillingFreq of decalTypeBillingFreqs" [ngValue]="decalTypeBillingFreq">{{decalTypeBillingFreq}}</option>
    </select>

    <label>Color</label>
    <select
      formControlName="decalColor"
      (change)="changeDecalColor(decalColor)"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['decalColor'].errors}"
      required
      data-cy="input-decal-color"
    >
      <option *ngFor="let decalColor of decalColors" [ngValue]="decalColor">{{decalColor.description}}</option>
    </select>
    <div *ngIf="formSubmitted && form['decalColor'].errors" class="invalid-feedback">
      <div *ngIf="form['decalColor'].errors['required']">Please select a color.</div>
    </div>

    <label>Parking Image URL</label>
    <input
      type="text"
      formControlName="decalTypeImageURL"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['decalTypeImageURL'].errors}"
      required
      data-cy="input-url"
    >
    <div *ngIf="formSubmitted && form['decalTypeImageURL'].errors" class="invalid-feedback">
      <div *ngIf="form['decalTypeImageURL'].errors['required']">Please enter an image url.</div>
    </div>
    <div>
      <div *ngIf="hasError" class="text-danger">Cannot Edit Vehicle</div>
      <button type="submit" data-cy="submit">Submit</button>
    </div>
  </form>
</app-modal>
