<h2 data-cy="title">Auto Assign Decals</h2>
<p>Select the decal types to auto-assign.</p>

<form id="yearForm" [formGroup]="yearForm">
  <select formControlName="year">
    <option *ngFor="let yearSelectOption of yearSelectOptions" [ngValue]="yearSelectOption.year">{{yearSelectOption.range}}</option>
  </select>
  <label class="selections">
  <input type="checkbox" data-cy="select-all" class="input-checkbox" (click)="selectAllDecalTypes()" [checked]="isSelectAllChecked">
    Select All?
  </label>
  <label class="selections">
    <input type="checkbox" data-cy="clear-all" class="input-checkbox" (click)="clearAllDecalTypes()" [checked]="isClearAllChecked" >
    Clear All?
  </label>
</form>

<div class="headerContainer">
  <h3 class="decalTypeCodeColumn">Code</h3>
  <h3 class="decalTypeDescriptionColumn">Description</h3>
</div>

<div id="decalTypes">
    <ul *ngIf="decals; else noActiveDecals">
      <li *ngFor="let decal of decals" data-cy="decal-type">
        <label class="selectDecalType">
          <input data-cy="decal-type-checkbox" type="checkbox" [checked]="isDecalSelected(decal)" (click)="toggleSelectDecal(decal)" class="decal-checkbox">
        </label>
        <div><app-decal-type-icon [decalColor]="decal.decalColor.code" [decalCode]="decal.code" [decalDescription]="decal.description"></app-decal-type-icon></div>
        <div class="selectDecalType" data-cy="decal-type-desc">{{decal.description}}</div>
      </li>
    </ul>
</div>

<ng-template #noActiveDecals>
  No Active Decal Types
</ng-template>

<div class="assign-button-container" matTooltip="Please Choose a Decal" [matTooltipDisabled]="isEnabled" [matTooltipPosition]="matPosition">
    <button mat-button class="assign-button" data-cy="assign-button" [disabled]="!isEnabled" (click)="autoAssignDecals()"> Auto Assign Decals </button>
</div>
<div *ngIf="isRunning">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div id="logs">
  <p>Log:</p>
  <ul *ngIf="logMessages">
    <li *ngFor="let log of logMessages" data-cy="log">
      <div class="logColumn">{{log.message}}</div>
    </li>
  </ul>
</div>
