import {Component, OnInit} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {isCommuterSelector} from "../../../core/store/auth/auth.selectors";
import { Observable, Subscription, take, tap } from 'rxjs';
import {IAddress} from "../../../core/models/address.model";
import {UserRepository} from "../../../core/repository/user.repository";

@Component({
  selector: 'app-commuter-address',
  templateUrl: './commuter-address.component.html',
  styleUrls: ['./commuter-address.component.scss']
})
export class CommuterAddressComponent implements OnInit {

  isCommuter$: Observable<boolean | undefined>;
  address: IAddress;

  constructor(private store: Store,
              private userRepo: UserRepository) { }

  ngOnInit(): void {
    this.isCommuter$ = this.store.pipe(
      select(isCommuterSelector),
      tap(isCommuter => {
        if (isCommuter) {
          this.userRepo.getAddress().pipe(take(1))
            .subscribe(address => this.address = address);
        }
      })
    );
  }

}
