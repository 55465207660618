import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {TooltipPosition} from '@angular/material/tooltip';
import { catchError, interval, of, switchMap, takeWhile, tap } from 'rxjs';
import { AutoAssignService } from 'src/app/core/service/autoAssign.service';
import { DecalTypeService } from '../../../core/service/decal-type.service';
import { LogMessageService } from '../../../core/service/logMessage.service';
import { IDecalType } from 'src/app/core/models/decal-type.model';
import { ILogMessage } from '../../../core/models/logMessage.model';

@Component({
  selector: 'app-auto-assign',
  templateUrl: './auto-assign.component.html',
  styleUrls: ['./auto-assign.component.scss'],
})
export class AutoAssignComponent implements OnInit {
  public logMessages: ILogMessage[];
  public decals: IDecalType[];
  public selectedDecals: IDecalType[] = [];

  public isRunning = false;
  public isEnabled = false;

  declare matPosition: TooltipPosition;

  public currentYear = new Date().getUTCFullYear();
  public yearSelectOptions: Array<{ range: string; year: number }> = [];
  public yearForm = new FormGroup({
    year: new FormControl(this.currentYear),
  });

  public isSelectAllChecked = false;
  public isClearAllChecked = false;

  constructor(
    private logMessageService: LogMessageService,
    private decalTypeService: DecalTypeService,
    private autoAssignService: AutoAssignService
  ) {}

  ngOnInit(): void {
    this.initialize();
  }

  public initialize(){
    this.initYearForm();
    this.setCurrentYear();
    this.initYearSelectOptions();
    this.getLogMessage();
    this.matPosition = "right";
  }

  public getLogMessage() {
    this.logMessageService.getLogMessage().subscribe((logMessage: ILogMessage[])=>{
        this.logMessages = logMessage;
      });
  }

  public getCurrentDecals(selectedYear: number) {
    this.decalTypeService.getDecalTypes(selectedYear).subscribe((decalList: IDecalType[])=>{
        this.decals = decalList.filter((decalType: IDecalType) => decalType.active)
    });
  }

  public isDecalSelected(decal: IDecalType): boolean {
    return this.selectedDecals.includes(decal);
  }

  public toggleSelectDecal(decal: IDecalType) {
    const isDecalSelected = this.selectedDecals.includes(decal);
    if (isDecalSelected) {
      this.selectedDecals = this.selectedDecals.filter(d => d !== decal);
    } else {
      this.selectedDecals.push(decal);
    }
    this.isEnabled = this.selectedDecals.length > 0;
    this.isClearAllChecked = this.isEnabled && !isDecalSelected;
  }


  public initYearForm(): void {
    this.yearForm.get('year')?.valueChanges.subscribe((year: number | null) => {
      if (year !== null) {
       this.getCurrentDecals(year);
       this.isClearAllChecked = false;
       this.isSelectAllChecked = false;
      }
    });
  }

  public setCurrentYear(): void {
    if (this.isBeforeJuly()) {
      this.yearForm.get('year')?.setValue(this.currentYear - 1);
    } else {
      this.yearForm.get('year')?.setValue(this.currentYear);
    }
  }

  public isBeforeJuly(): boolean {
    return new Date().getUTCMonth() < 6;
  }

  public initYearSelectOptions(): void {
    for(let year = this.currentYear; year >= this.currentYear - 1; year--) {
      this.yearSelectOptions.push({
        range: year + " - " + (year + 1),
        year: year
      });
    }
  }

  public selectAllDecalTypes() {
    this.isSelectAllChecked = !this.isSelectAllChecked;

    if (this.isSelectAllChecked) {
      this.selectedDecals = this.decals;
    } else {
      this.selectedDecals = [];
    }

    this.isEnabled = this.selectedDecals.length > 0;
    this.isClearAllChecked = false;
  }

  public clearAllDecalTypes() {
    this.isClearAllChecked = !this.isClearAllChecked
    if (this.isClearAllChecked) {
      this.selectedDecals = [];
      this.isSelectAllChecked = false;
      this.isEnabled = false;
    }
  }

  public autoAssignDecals() {
    this.isRunning = true;
    this.autoAssignService.autoAssignDecals(this.selectedDecals).subscribe((response)=> {
      interval(1000).pipe(
       switchMap(()=> this.logMessageService.getLogMessage().pipe(
        tap((messages)=> this.logMessages = messages),
        catchError((error: Error)=> of(error))
       )),
       takeWhile((logMessage: Error | ILogMessage[])=> ((logMessage instanceof Error || (!logMessage[logMessage.length-1].finishedIndicator)))
       )
      ).subscribe();
    },
    (error) => {
      console.error(error);
    },
    () => {
      this.isRunning = false;
      this.clearSelectedDecals();
    }
    );
  }

  public clearSelectedDecals() {
    this.logMessages = [];
    this.selectedDecals = [];
    this.initYearForm();
    this.getLogMessage();
    this.isEnabled = false;
  }
}


