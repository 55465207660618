import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { OrderProcessComponent } from './order-process/order-process.component';
import { CommuterAddressComponent } from './commuter-address/commuter-address.component';
import { SelectVehiclesComponent } from './select-vehicles/select-vehicles.component';
import { SelectDecalsComponent } from './select-decals/select-decals.component';
import { AgreementsComponent } from './agreements/agreements.component';
import { ReceiptComponent } from './receipt/receipt.component';
import { MatStepperModule } from "@angular/material/stepper";
import { StoreModule } from "@ngrx/store";
import { orderDecalsReducers } from "./store/order-decals.reducers";
import { EffectsModule } from "@ngrx/effects";
import { StepNumberEffect } from "./store/effects/stepNumber.effect";
import { SharedModule } from "../../shared/shared.module";
import { OrderVehicleListComponent } from './order-process-vehicle-list/order-process-vehicle-list.component';
import { SetAgreementInfoEffect } from "./store/effects/setAgreementInfo.effect";

@NgModule({
  declarations: [
    OrderProcessComponent,
    CommuterAddressComponent,
    SelectVehiclesComponent,
    SelectDecalsComponent,
    AgreementsComponent,
    ReceiptComponent,
    OrderVehicleListComponent
  ],
  imports: [
    CommonModule,
    MatStepperModule,
    StoreModule.forFeature('order-decals', orderDecalsReducers),
    EffectsModule.forFeature([StepNumberEffect, SetAgreementInfoEffect]),
    SharedModule,
    MatProgressSpinnerModule
  ]
})
export class OrderProcessModule {
}
