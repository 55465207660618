import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, exhaustMap, map, of } from "rxjs";
import { VehicleRepository } from "src/app/core/repository/vehicle.repository";
import { getVehicleListAction, getVehicleListFailureAction, getVehicleListSuccessAction } from "../order-decals.actions";

@Injectable()
export class GetVehicleListEffect {

    // noinspection TypeScriptValidateTypes
    getVehicleList$ = createEffect(() => this.actions$.pipe(
        ofType(getVehicleListAction),
        exhaustMap(() => {
            return this.vehicleRepo.getVehicles().pipe(
                map(response => getVehicleListSuccessAction({ vehicleList: response })),
                catchError((errorResponse: HttpErrorResponse) =>
                    of(getVehicleListFailureAction({ error: errorResponse })))
            );
        })
    ));

    constructor(private actions$: Actions,
        private vehicleRepo: VehicleRepository
    ) {
    }
}