<!-- eslint-disable @angular-eslint/template/no-negated-async -->
<div *ngIf="(carTruckList$ | async)?.length">
    <div class="type-header">
        <input data-cy="carTruck-checkbox" type="checkbox" id="cars" class="input-checkbox" [(ngModel)]="carSelected" (change)="toggleCar($event)">
        <label for="cars"><span aria-label="Select all vehicles listed below">Car/Truck</span></label>
    </div>

  <div class="vehicleListWrapper">
    <table class="table table-responsive table-responsive-sm">
      <tbody>
      <app-order-vehicle-list [vehicleList]="carTruckList$ | async" [showDelete]="true" [showEdit]="true" [showHeader]="false" [showCheckbox]="true" [isChecked]="carSelected" (handleEditEvent)="openModal($event)"></app-order-vehicle-list>
      </tbody>
    </table>
  </div>
</div>

<div *ngIf="(motorcycleList$ | async)?.length">
  <div class="type-header">
      <input type="checkbox" id="motorcycle" class="input-checkbox" [(ngModel)]="motorcycleSelected" (change)="toggleMotorcycle($event)">
      <label for="motorcycle"><span aria-label="Select all motorcycles listed below">Motorcycle</span></label>
  </div>

  <div class="vehicleListWrapper">
    <table class="table table-responsive table-responsive-sm">
      <tbody>
      <app-order-vehicle-list [vehicleList]="motorcycleList$ | async" [showDelete]="true" [showEdit]="true" [showHeader]="false" [showCheckbox]="true" [isChecked]="motorcycleSelected" (handleEditEvent)="openModal($event)">></app-order-vehicle-list>
      </tbody>
    </table>
  </div>
</div>

<div *ngIf="(bikeList$ | async)?.length">
    <div class="type-header">
        <input type="checkbox" id="bike" class="input-checkbox" [(ngModel)]="bikeSelected" (change)="toggleBike($event)">
        <label for="bike"><span aria-label="Select all bikes listed below">Bike</span></label>
    </div>

  <div class="vehicleListWrapper">
    <table class="table table-responsive table-responsive-sm">
      <tbody>
      <app-order-vehicle-list [vehicleList]="bikeList$ | async" [showDelete]="true" [showEdit]="true" [showHeader]="false" [showCheckbox]="true" [isChecked]="bikeSelected" (handleEditEvent)="openModal($event)">></app-order-vehicle-list>
      </tbody>
    </table>
  </div>
</div>

<div class="add-button-container">
    <button type="button" class ="addButton" aria-haspopup="true" aria-label="Register a new vehicle." (click)="openModal(undefined)">Add Vehicle</button>
</div>

<hr>

<div class="nav-button-container">
  <div>
    <button mat-button matStepperPrevious class="backButton" *ngIf="!(step1Disabled$ | async)">Back</button>
  </div>
  <div matTooltip="Please Select a Vehicle" [matTooltipDisabled]="isEnabled | async">
    <button data-cy="select-decals-button" mat-button matStepperNext class="nextButton" (click)="proceedToDecals()" [disabled]="!(isEnabled | async)">Select Decals</button>
  </div>
</div>

<app-modal (closeModalEvent)="closeModal()" *ngIf="showModal" appTrapFocus>
  <form class="modalContent" [formGroup]="vehicleAddForm" (ngSubmit)="submitAddForm()">
    <h3 [innerHtml]="modalTitle"></h3>
    <label>Vehicle Type</label>
    <select
      formControlName="vehicleType"
      (change)="changeVehicleType(vehicleType)"
      (click)="handleMakeOptionChange()"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['vehicleType'].errors}"
      required
    >
      <option [ngValue]="selectedVehicleOption" selected disabled>Select Vehicle Type</option>
      <option *ngFor="let vehicleType of vehicleTypes" [ngValue]="vehicleType">{{vehicleType.text}}</option>
    </select>
    <div *ngIf="formSubmitted && form['vehicleType'].errors" class="invalid-feedback">
      <div *ngIf="form['vehicleType'].errors['required']">Please select a vehicle type.</div>
    </div>

    <label>Vehicle Make</label>
    <select
      formControlName="vehicleMake"
      (change)="changeVehicleMake(vehicleMake)"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['vehicleMake'].errors}"
      required
    >
      <option [ngValue]="selectedVehicleOption" selected disabled>Select Vehicle Make</option>
      <option *ngFor="let vehicleMake of filteredMakes" [ngValue]="vehicleMake" >{{vehicleMake.text}}</option>
    </select>
    <div *ngIf="formSubmitted && form['vehicleMake'].errors" class="invalid-feedback">
      <div *ngIf="form['vehicleMake'].errors['required']">Please select a vehicle make. If you cannot find your Make, select "Other".</div>
    </div>

    <label>Vehicle Model</label>
    <select
      formControlName="vehicleModel"
      (change)="changeVehicleModel(vehicleModel)"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['vehicleModel'].errors}"
      required
    >
      <option [ngValue]="selectedVehicleOption" selected disabled>Select Vehicle Model</option>
      <option *ngFor="let vehicleModel of vehicleModels" [ngValue]="vehicleModel">{{vehicleModel.text}}</option>
    </select>
    <div *ngIf="formSubmitted && form['vehicleModel'].errors" class="invalid-feedback">
      <div *ngIf="form['vehicleModel'].errors['required']">Please select a vehicle model. If you cannot find your Model, select "Other".</div>
    </div>

    <label *ngIf="showOtherModel">Other Model</label>
    <input *ngIf="showOtherModel"
      type="text"
      placeholder=""
      formControlName="otherModel"
      class="form-control"
    />

    <label>Vehicle Color</label>
    <select
      formControlName="vehicleColor"
      (change)="changeVehicleColor(vehicleColor)"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['vehicleColor'].errors}"
      required
    >
      <option [ngValue]="selectedVehicleOption" selected disabled>Select Vehicle Color</option>
      <option *ngFor="let vehicleColor of vehicleColors" [ngValue]="vehicleColor">{{vehicleColor.text}}</option>
    </select>
    <div *ngIf="formSubmitted && form['vehicleColor'].errors" class="invalid-feedback">
      <div *ngIf="form['vehicleColor'].errors['required']">Please select a vehicle color.</div>
    </div>

    <label>Vehicle Year</label>
    <input
      type="text"
      placeholder="Year"
      formControlName="yearOfVehicle"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['yearOfVehicle'].errors}"
      required
    />
    <div *ngIf="formSubmitted && form['yearOfVehicle'].errors" class="invalid-feedback">
      <div *ngIf="form['yearOfVehicle'].errors['required']">Please enter a 4 digit year.</div>
    </div>

    <label>License Plate Number</label>
    <input
      type="text"
      placeholder="License Plate #"
      formControlName="licenseNumber"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['licenseNumber'].errors}"
      required
    />
    <div *ngIf="formSubmitted && form['licenseNumber'].errors" class="invalid-feedback">
      <div *ngIf="form['licenseNumber'].errors['required']">Please enter your license plate number.</div>
    </div>

    <label>License State</label>
    <select
      formControlName="vehicleStateCode"
      (change)="changeStateCode(vehicleStateCode)"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['vehicleStateCode'].errors}"
      required
    >
      <option [ngValue]="selectedVehicleOption" selected disabled>Select State</option>
      <option *ngFor="let vehicleStateCode of stateCodes" [ngValue]="vehicleStateCode.abbreviation">{{vehicleStateCode.name}}</option>
    </select>
    <div *ngIf="formSubmitted && form['vehicleStateCode'].errors" class="invalid-feedback">
      <div *ngIf="form['vehicleStateCode'].errors['required']">Please select a State/Province.</div>
    </div>

    <fieldset>
      <input type="checkbox" formControlName="vehicleInsured" [value]="vehicleInsured" [checked]="vehicleInsured" />
      <label class="insured">This vehicle has insurance</label>
    </fieldset>
    <label>Electric</label>
    <select
      formControlName="electricVehicle"
      (change)="changeElectricVehicle(electricVehicle)"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['electricVehicle'].errors}"
      required
    >
      <option [ngValue]="selectedVehicleOption" selected disabled>Is this an electric vehicle?</option>
      <option *ngFor="let electricVehicle of electricVehicles" [ngValue]="electricVehicle">{{electricVehicle}}</option>
    </select>
    <div *ngIf="formSubmitted && form['electricVehicle'].errors" class="invalid-feedback">
      <div *ngIf="form['electricVehicle'].errors['required']">Please select yes or no.</div>
    </div>

    <button type="submit">Submit</button>
  </form>
</app-modal>
