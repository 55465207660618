import {Injectable} from "@angular/core";
import {VehicleDecalRepository} from '../repository/vehicle-decal.repository';
import {Observable} from 'rxjs';
import {IDecalExpiration, IDecalNumberRegistration, IVehicleDecal} from "../models/vehicle-decal.model";
import {map} from "rxjs/operators";
import {ISelectedVehicleDecalPair} from "../models/selected-vehicle-decal-pair.model";

@Injectable({
  providedIn: 'root'
})
export class VehicleDecalService {

  constructor(private vehicleDecalRepo: VehicleDecalRepository) { }

  public getVehicleDecals(): Observable<IVehicleDecal[]> {
    return this.vehicleDecalRepo.getVehicleDecals().pipe(
      map(decals => decals.map(decal => ({
        ...decal,
        decalEndDate: this.setFormattedDate(decal.decalEndDate)
      })))
    );
  }

  public requestVehicleDecals(decalPairs: ISelectedVehicleDecalPair[]): Observable<ISelectedVehicleDecalPair[]> {
    return this.vehicleDecalRepo.insertVehicleList(decalPairs);
  }

  public issueVehicleDecal(decal: IDecalNumberRegistration): Observable<IVehicleDecal[]> {
    return this.vehicleDecalRepo.issueDecal(decal);
  }

  public expireDecal(decal: IDecalExpiration): Observable<IVehicleDecal[]> {
    return this.vehicleDecalRepo.expireDecal(decal);
  }

  private setFormattedDate(inputDate: string) {
    const date = new Date(inputDate)
    return `${date.toLocaleString('default', { month: 'long' })} ${date.getDate()}, ${date.getFullYear()}`
  }
}
