import {createAction, props} from '@ngrx/store';
import { HttpErrorResponse } from "@angular/common/http";

export const getUserNameAction = createAction(
  '[Auth] Get User Name'
);

export const getUserNameSuccessAction = createAction(
  '[Auth] Get User Name Success',
  props<{ data: string }>()
);

export const getUserNameFailureAction = createAction(
  '[Auth] Get User Name Failure',
  props<{ error: HttpErrorResponse }>()
);

export const getUserRolesAction = createAction(
  '[Auth] Get User Roles'
);

export const getUserRolesSuccessAction = createAction(
  '[Auth] Get User Roles Success',
  props<{ data: string[] }>()
);

export const getUserRolesFailureAction = createAction(
  '[Auth] Get User Roles Failure',
  props<{ error: HttpErrorResponse }>()
);

export const getCommuterStatusAction = createAction(
  '[Auth] Get Commuter Status'
);

export const getCommuterStatusSuccessAction = createAction(
  '[Auth] Get Commuter Status Success',
  props<{ isCommuter: boolean }>()
);

export const getCommuterStatusFailureAction = createAction(
  '[Auth] Get Commuter Status Failure',
  props<{ error: HttpErrorResponse }>()
);

export const getEmployeeStatusAction = createAction(
  '[Auth] Get Employee Status'
);

export const getEmployeeStatusSuccessAction = createAction(
  '[Auth] Get Employee Status Success',
  props<{ isEmployee: boolean }>()
);

export const getEmployeeStatusFailureAction = createAction(
  '[Auth] Get Employee Status Failure',
  props<{ error: HttpErrorResponse }>()
);

export const impersonateUserAction = createAction(
  '[Auth] Impersonate',
  props<{username: string}>()
);

export const impersonateUserSuccessAction = createAction(
  '[Auth] Impersonate Success',
  props<{data: string}>()
);

export const impersonateUserFailureAction = createAction(
  '[Auth] Impersonate Failure',
  props<{error: HttpErrorResponse}>()
);

export const endImpersonateUserAction = createAction(
  '[Auth] End Impersonate'
);

export const endImpersonateUserSuccessAction = createAction(
  '[Auth] End Impersonate Success',
  props<{data: string}>()
);

export const endImpersonateUserFailureAction = createAction(
  '[Auth] End Impersonate Failure',
  props<{error: HttpErrorResponse}>()
);
