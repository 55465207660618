<h2 data-cy="title">View Vehicle Models</h2>
<p>This is a list of all the models that have been created for each make. Select a make to then either edit an existing model or create a new model.</p>

<label for="vehicle-make-selector">Choose a vehicle make:</label>
<select id="vehicle-make-selector" (change)="onMakeSelected($event)">
  <option *ngFor="let make of vehicleMakes" [value]="make.id">{{ make.text }}</option>
</select>

<div class="headerContainer">
  <h3 class="modelColumn">Vehicle Model</h3>
  <h3 class="makeColumn ">Vehicle Make</h3>
  <h3 class="editColumn"></h3>
</div>

<ul id="vehicle-table" *ngIf="vehicleModels">
  <li *ngFor="let model of vehicleModels" data-cy="vehicle-model">
    <div class="modelColumn" data-cy="vehicle-model-text">{{model.text}}</div>
    <div class="makeColumn">{{model.make.text}}</div>
    <a class="editColumn" id="right" (click)="openModal(model)">
      <img src="assets/edit.svg" alt="Edit" id="edit-icon">
      <span id="edit-text">Edit</span>
    </a>
  </li>
</ul>

<button *ngIf="vehicleModels" (click)="openModal(undefined)" data-cy="add-vehicle-model">Add Model</button>

<app-modal (closeModalEvent)="closeModal()" *ngIf="isModalOpen" appTrapFocus>
  <form class="modalContent" [formGroup]="modelForm" (ngSubmit)="submitForm()">
    <h3>Enter Vehicle Model</h3>
    <label>Vehicle Model</label>
    <input type="text" formControlName="vehicleModel" data-cy="input">
    <button type="submit">Submit</button>
  </form>
</app-modal>
