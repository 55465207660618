/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import {MatMenuTrigger} from '@angular/material/menu';
import { Store } from "@ngrx/store";
import { take } from "rxjs";
import { IStateCode } from "src/app/core/models/statecode.model";
import { IVehicleColor } from "src/app/core/models/vehicle-color.model";
import { IVehicleMake, IVehicleType } from "src/app/core/models/vehicle-make.model";
import { IVehicleModel } from "src/app/core/models/vehicle-model.model";
import { IVehicle } from "src/app/core/models/vehicle.model";
import { VehicleColorService } from "src/app/core/service/vehicle-color.service";
import { VehicleMakeService } from "src/app/core/service/vehicle-make.service";
import { VehicleService } from "src/app/core/service/vehicle.service";
import { getVehicleListAction } from "src/app/feature/order-process/store/order-decals.actions";

@Component({
    selector: 'app-vehicle-add-modal',
    templateUrl: './vehicle-add-modal.component.html',
    styleUrls: ['./vehicle-add-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VehicleAddModal implements OnInit {
    @Input()
    public editVehicle: IVehicle | undefined;
    @Input()
    public showModal: boolean;
    @Output()
    public showModalChange = new EventEmitter<boolean>();

    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

    public makeList: IVehicleMake[] = [];
    public modelList: IVehicleModel[] = [];
    public typeList: IVehicleType[] = [];
    public stateList: IStateCode[] = [];
    public colorList: IVehicleColor[] = [];
    public otherMakeList: IVehicleMake[] = [];

    public selectedType: IVehicleType;
    public selectedMake: IVehicleMake;
    public selectedModel: IVehicleModel;
    public selectedColor: IVehicleColor;
    public selectedElectric: string;
    public selectedInsured: string = "N";
    public selectedState: string;
    public selectedYear: string;
    public selectedLicense: string;
    public selectedOtherModel: string;
    public pidm: number = 1; //just needs a value, the api always overrides with the pidm of the logged in user
    public id: number;
    public carpool: string;
    public otherModel: string;
    public savedVehicle: IVehicle;
    public editingVehicle: boolean = false;

    public selectedTypeText = "Select vehicle type";
    public selectedMakeText = "Select vehicle make";
    public selectedModelText = "Select vehicle models";
    public selectedColorText = "Select color";
    public selectedElectricText = "Is this an electric vehicle?"
    public selectedStateText = "Select state"


    constructor(private makeService: VehicleMakeService, private colorService: VehicleColorService,
        private vehicleService: VehicleService, private store: Store) { }

    ngOnInit(): void {
        if (this.editVehicle != undefined) {
            this.editingVehicle = true;

            //set the text fields on the form
            this.selectedTypeText = this.editVehicle.vehicleType.text as string;
            this.selectedMakeText = this.editVehicle.make.text as string;
            this.selectedModelText = this.editVehicle.model.text as string;
            this.selectedColorText = this.editVehicle.color.text;
            this.selectedStateText = this.editVehicle.stateCode;
            this.selectedElectricText = this.editVehicle.electric == "Y" ? "Yes" : "No";

            //set the actual values
            this.id = this.editVehicle.id;
            this.pidm = this.editVehicle.pidm;
            this.selectedType = this.editVehicle.vehicleType;
            this.selectedMake = this.editVehicle.make;
            this.selectedModel = this.editVehicle.model;
            this.selectedColor = this.editVehicle.color;
            this.selectedElectric = this.editVehicle.electric;
            this.selectedInsured = this.editVehicle.insured;
            this.selectedState = this.editVehicle.stateCode;
            this.selectedYear = this.editVehicle.vehicleYear.toString();
            this.selectedLicense = this.editVehicle.licenseNumber;
            this.editVehicle.otherModel ?  this.selectedOtherModel : null;

        } else {
            this.makeService.getVehicleTypes().subscribe(results => {
                this.typeList = results;
            });
        }

        this.colorService.getVehicleColors().subscribe(reults => {
            this.colorList = reults;
        });

        this.vehicleService.getStateCodes().pipe(take(1))
            .subscribe(results => this.stateList = results);

    }

    public getMakes(type: IVehicleType) {
        //TODO: add api endpoint to get makes for a specific vehicle type
        this.makeService.getVehicleMakes().subscribe(results => {
            this.makeList = results.filter(make => make.vehicleType.id == type.id);
        });
    }

    public getModels(make: IVehicleMake) {
      // make is other so model type is other
      if (make.text?.includes("Other") || make.text?.includes("other")) {
        this.makeService.getModels(make.id).subscribe(results => {
            this.modelList = results;
        });
      } else {
        // get all models for the make and add the model for other
        this.makeService.getModels(make.id).subscribe(resultsModels => {
          this.makeService.getVehicleMakes().subscribe(resultsMakes => {
            this.otherMakeList = resultsMakes.filter(makes =>
              (makes.text?.includes("Other") || makes.text?.includes("other")) && makes.vehicleType.id == make.vehicleType.id);
            if (this.otherMakeList.length > 0) {
              this.makeService.getModels(resultsMakes[0].id).subscribe(otherModels => {
                this.modelList = resultsModels.concat(otherModels);
              });
            } else {
              this.modelList = resultsModels;
            }
          });
        });
      }
    }

    public closeModal() {
        this.showModalChange.emit(false);
    }

    public setType(type: IVehicleType) {
        this.selectedType = type;
        this.selectedTypeText = type.text as string;
        this.selectedMakeText = "Select vehicle make";
        this.selectedModelText = "Select vehicle models";
        this.getMakes(type);
    }

    public setMake(make: IVehicleMake) {
        this.selectedMake = make;
        this.selectedMakeText = make.text as string;
        this.selectedModelText = "Select vehicle models";
        this.getModels(make);
    }

    public setModel(model: IVehicleModel) {
        this.selectedModel = model;
        this.selectedModelText = model.text as string;
    }

    public setColor(color: IVehicleColor) {
        this.selectedColor = color;
        this.selectedColorText = color.text;
    }

    public setElectric(electric: string) {
        this.selectedElectric = electric == "Yes" ? "Y" : "N";
        this.selectedElectricText = electric;
    }

    public insuredChange(event: any) {
        this.selectedInsured = event.target.checked == true ? "Y" : "N";
    }

    public setState(state: IStateCode) {
        this.selectedState = state.abbreviation;
        this.selectedStateText = state.name;
    }

    public addVehicle() {
        if (this.isFormValid()) {
            let vehicleToSave: IVehicle = {
                id: this.id,
                pidm: this.pidm,
                vehicleType: this.selectedType,
                make: this.selectedMake,
                model: this.selectedModel,
                color: this.selectedColor,
                vehicleYear: parseInt(this.selectedYear),
                licenseNumber: this.selectedLicense,
                stateCode: this.selectedState,
                insured: this.selectedInsured,
                carpool: this.carpool,
                electric: this.selectedElectric,
                otherModel: this.selectedOtherModel,
                active: "Y",
                printModel: null
            }

            this.vehicleService.saveVehicle(vehicleToSave).subscribe(result => {
                this.savedVehicle = result;
                this.store.dispatch(getVehicleListAction());
            });

            this.showModalChange.emit(false);
        }
    }

    public isFormValid(): boolean {
        return this.selectedYear != undefined && this.selectedLicense != undefined && this.selectedTypeText != "Select vehicle type"
            && this.selectedMakeText != "Select vehicle make" && this.selectedModelText != "Select vehicle models" && this.selectedColorText != "Select color"
            && this.selectedElectricText != "Is this an electric vehicle?" && this.selectedStateText != "Select state"
    }

}
