<h1 data-cy="title">Decal Information</h1>
<br>
<div class="contents" [class.show]="showLess" >
  <app-text-block [code]=code></app-text-block>
</div>
<div class="show-button-container">
  <button class="show-button" (click)="showLess = !showLess">{{showLess ? 'Show Less': 'Show More' }}</button>
</div>
<hr>
<br>


  <app-loading-spinner [givenFlag$]="isLoading$"></app-loading-spinner>


<ng-template [ngIf]="(vehicleDecals$ | async)?.length" [ngIfElse]="noDecals">
  <p data-cy="decals-exist">
    Below is a complete list of your parking decals and decal requests.
    To request a new decal, click "Order Decals".
    To change a requested or issued decal, you will need to visit the LUPD office.
  </p>
  <br>

  <div class="vehicleListWrapper">
    <table class="table table-responsive table-responsive-sm">
      <tbody *ngFor="let decal of (vehicleDecals$ | async)" data-cy="vehicle-decal">
      <tr>
        <td class="headerCell">
          <app-decal-type-icon [decalColor]="decal.decalColor" [decalCode]="decal.code" [decalDescription]="decal.description" data-cy="decal-type-icon"></app-decal-type-icon>
        </td>
        <td class="headerCell" data-cy="decal-label">{{setStatusText(decal)}}</td>
        <td>
          <button (click)="toggleVehicleDetails(decal)" class="btn dropdown-toggle" data-toggle="dropdown" data-cy="show-vehicles"
                  [attr.aria-label]="showVehiclesForDecal(decal) ? 'Hide vehicles for'+ decal.description + ', status ' + decal.dispositionLabel + decal.decalEndDate
                  : 'Show vehicles for'+ decal.description + ', status ' + decal.dispositionLabel + decal.decalEndDate">
            {{showVehiclesForDecal(decal) ? 'Hide Vehicles' : 'Show Vehicles'}}
          </button>
        </td>
      </tr>
      <app-vehicle-list *ngIf="showVehiclesForDecal(decal)" data-cy="vehicle-list"
                        [vehicleList]="decal.vehicleList" [showHeader]=false [showDelete]="false" [showEdit]="true"
                        group="vehicleStatus" width="vehicleListWidth" btnFormat="vehicleEditBtn" tableRowFormat="customRow"
                        (handleEditEvent)="openModal($event)"></app-vehicle-list>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #noDecals>
  <p data-cy="decals-empty">
    You have not yet requested any parking decals.
    To request a new decal, click "Order Decals".
  </p>
</ng-template>

<br>
<div class="order-button-container">
    <button type="button" class ="orderButton"
            *ngIf="(hasDecalSelectionRole$ | async) && (requestedDecals$ | async)?.length && (isImpersonating$ | async)"
            (click)="openRegisterModal()" data-cy="issue-button">
      Issue Decal
    </button>

    <button type="button" class ="orderButton"
            *ngIf="(hasDecalSelectionRole$ | async) && (expirableDecals$ | async)?.length && (isImpersonating$ | async)"
            (click)="openExpirationModal()" data-cy="expire-button">
      Expire Decal
    </button>
</div>

<app-modal (closeModalEvent)="closeModal()" *ngIf="isModalOpen" appTrapFocus>
  <form class="modalContent" [formGroup]="vehicleEditForm" (ngSubmit)="submitForm()">
    <h3 [innerHtml]="modalTitle"></h3>

    <label>Vehicle Type</label>
    <select
      formControlName="vehicleType"
      class="form-control"
      data-cy="vehicle-form-type"
    >
      <!-- <option [ngValue]="selectedVehicleOption" selected disabled>Select Vehicle Type</option> -->
      <option *ngFor="let vehicleType of vehicleTypes" [ngValue]="vehicleType">{{vehicleType.text}}</option>
    </select>

    <label>Vehicle Make</label>
    <select
      formControlName="vehicleMake"
      class="form-control"
      data-cy="vehicle-form-make"
      >
      <!-- <option [ngValue]="selectedVehicleOption" selected disabled>Select Vehicle Make</option> -->
      <option *ngFor="let vehicleMake of filteredMakes" [ngValue]="vehicleMake" >{{vehicleMake.text}}</option>
    </select>

    <label>Vehicle Model</label>
    <select
      formControlName="vehicleModel"
      class="form-control"
      data-cy="vehicle-form-model"
      >
      <!-- <option [ngValue]="selectedVehicleOption" selected disabled>Select Vehicle Model</option> -->
      <option *ngFor="let vehicleModel of vehicleModels" [ngValue]="vehicleModel">{{vehicleModel.text}}</option>
    </select>

    <label *ngIf="showOtherModel">Other Model</label>
    <input *ngIf="showOtherModel" type="text" placeholder="" formControlName="otherModel" data-cy="vehicle-form-other" />

    <label>Vehicle Color</label>
    <select
      formControlName="vehicleColor"
      (change)="changeVehicleColor(vehicleColor)"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['vehicleColor'].errors}"
      required
      data-cy="vehicle-form-color"
      >
      <option [ngValue]="selectedVehicleOption" selected disabled>Select Vehicle Color</option>
      <option *ngFor="let vehicleColor of vehicleColors" [ngValue]="vehicleColor">{{vehicleColor.text}}</option>
    </select>
    <div *ngIf="formSubmitted && form['vehicleColor'].errors" class="invalid-feedback">
      <div *ngIf="form['vehicleColor'].errors?.['required']">Please select a vehicle color.</div>
    </div>

    <label>Vehicle Year</label>
    <input
      type="text"
      formControlName="yearOfVehicle"
      class="form-control"
      data-cy="vehicle-form-year"
    />

    <label>License Plate Number</label>
    <input
      type="text"
      placeholder="License Plate #"
      formControlName="licenseNumber"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['licenseNumber'].errors}"
      required
      data-cy="vehicle-form-license"
    />
    <div *ngIf="formSubmitted && form['licenseNumber'].errors" class="invalid-feedback">
      <div *ngIf="form['licenseNumber'].errors?.['required']">Please enter your license plate number.</div>
    </div>

    <label>License State</label>
    <select
      formControlName="vehicleStateCode"
      (change)="changeStateCode(vehicleStateCode)"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['vehicleStateCode'].errors}"
      required
      data-cy="vehicle-form-state"
    >
      <option [ngValue]="selectedVehicleOption" selected disabled>Select State</option>
      <option *ngFor="let vehicleStateCode of stateCodes" [ngValue]="vehicleStateCode.abbreviation">{{vehicleStateCode.name}}</option>
    </select>
    <div *ngIf="formSubmitted && form['vehicleStateCode'].errors" class="invalid-feedback">
      <div *ngIf="form['vehicleStateCode'].errors?.['required']">Please select a State/Province.</div>
    </div>

    <fieldset>
      <input type="checkbox" formControlName="vehicleInsured" [value]="vehicleInsured" [checked]="vehicleInsured" data-cy="vehicle-form-insured"/>
      <label class="insured">This vehicle has insurance</label>
    </fieldset>

    <label>Electric</label>
    <select
      formControlName="electricVehicle"
      (change)="changeElectricVehicle(electricVehicle)"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['electricVehicle'].errors}"
      required
      data-cy="vehicle-form-electric"
    >
      <option [ngValue]="selectedVehicleOption" selected disabled>Is this an electric vehicle?</option>
      <option *ngFor="let electricVehicle of electricVehicles" [ngValue]="electricVehicle">{{electricVehicle}}</option>
    </select>
    <div *ngIf="formSubmitted && form['electricVehicle'].errors" class="invalid-feedback">
      <div *ngIf="form['electricVehicle'].errors?.['required']">Please select yes or no.</div>
    </div>

    <button type="submit" data-cy="vehicle-form-submit-button">Save</button>
  </form>
</app-modal>

<app-modal (closeModalEvent)="closeRegisterModal()" *ngIf="isRegisterModalOpen" appTrapFocus>
  <div data-cy="issue-modal">
    <span class="input-margin"><b>Choose a decal:</b></span>
    <mat-form-field>
      <mat-select [(ngModel)]="selectedDecal" data-cy="register-decal-type">
        <mat-option *ngFor="let decal of (requestedDecals$ | async)" [value]="decal">
          <app-decal-type-icon [decalColor]="decal.decalColor" [decalCode]="decal.code" [decalDescription]="decal.description"></app-decal-type-icon> {{decal.description}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div>
      <label for="decal-number" class="input-margin">Enter Decal Number:</label>
      <input type="text" id="decal-number" data-cy="register-decal-number" [(ngModel)]="decalNumber" >
    </div>

    <div>
      <label for="paid-in-person" class="input-margin">Paid in Person?</label>
      <input type="checkbox" id="paid-in-person" data-cy="register-paid-in-person" [(ngModel)]="paidInPerson" >
    </div>

    <hr>

    <button (click)="registerDecal()" data-cy="register-submit-button">Issue</button>
  </div>
</app-modal>

<app-modal (closeModalEvent)="closeExpirationModal()" *ngIf="isExpireModalOpen" appTrapFocus>
  <div data-cy="expire-modal">
    <span class="input-margin"><b>Choose a decal:</b></span>
    <mat-form-field class="wide-select">
      <mat-select [(ngModel)]="selectedDecal" data-cy="expire-decal-type">
        <mat-option *ngFor="let decal of (expirableDecals$ | async)" [value]="decal">
          <app-decal-type-icon [decalColor]="decal.decalColor" [decalCode]="decal.code" [decalDescription]="decal.description"></app-decal-type-icon>
          {{decal.description}} <ng-container *ngIf="!!decal.decalNumber"># {{decal.decalNumber}}</ng-container>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div>
      <label for="clear-number" class="input-margin">Clear Decal Number?</label>
      <input type="checkbox" id="clear-number" data-cy="expire-clear-number" [(ngModel)]="clearDecalNumber" >
    </div>

    <hr>

    <button (click)="expireDecal()" data-cy="expire-submit-button">Expire</button>
  </div>
</app-modal>
