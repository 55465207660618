import {createFeatureSelector, createSelector} from "@ngrx/store";
import {IVehicleDecalsState} from "./vehicle-decals.state";

export const vehicleDecalsSelector = createFeatureSelector<IVehicleDecalsState>('vehicleDecals');

export const getVehicleDecalsSelector = createSelector(
  vehicleDecalsSelector,
  state => state.vehicleDecals
);

export const getRequestedDecalsSelector = createSelector(
  getVehicleDecalsSelector,
  decals => !!decals ? decals.filter(decal => decal.dispositionLabel == 'Requested') : []
);

export const getExpirableDecalsSelector = createSelector(
  getVehicleDecalsSelector,
  decals => !!decals ? decals.filter(decal => !decal.dispositionLabel.toLowerCase().includes('expired')) : []
);

export const isLoadingVehicleDecalsSelector = createSelector(
  vehicleDecalsSelector,
  state => state.isLoading
);
