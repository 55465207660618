import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { IAgreement } from '../../core/models/agreement.model';
import { UserRepository } from '../../core/repository/user.repository';

export enum PayrollSuccess {
  dialogTitle = "Payroll Deduction Successful",
  dialogMessage = "Payroll Deduction has been authorized."
}

export enum PayrollFailure {
  dialogTitle = "Payroll Deduction NOT Successful",
  dialogMessage = "Payroll Deduction has NOT been authorized."
}

@Component({
  selector: 'app-payroll-deduction',
  templateUrl: './payroll-deduction.component.html',
  styleUrls: ['./payroll-deduction.component.scss']
})
export class PayrollDeductionComponent implements OnInit {

  public payrollAgreement: IAgreement | undefined;

  public formattedDate: string | undefined;

  public isAccepted: boolean = false;

  readonly code = 'PAYROL';

  @ViewChild('dialogTemplate')
  public template: TemplateRef<any>;
  public dialogTitle: string;
  public dialogMessage: string;

  constructor(private userRepo: UserRepository, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.loadAgreementDate();
  }

  public loadAgreementDate() {
    this.userRepo.getUserAgreement(this.code).subscribe((agreement) => {
      if (agreement?.activityDate) {
        this.payrollAgreement = agreement
        this.formattedDate = this.setFormattedDate(agreement.activityDate)
      }
    })
  }

  public setFormattedDate(signatureDate: string) {
    const date = new Date(signatureDate)
    return `${date.toLocaleString('default', { month: 'long' })} ${date.getDate()}, ${date.getFullYear()}`
  }

  public postAgreement() {
    this.userRepo.postUserAgreement(this.code).subscribe((agreement) => {
      this.isAccepted = false;
      this.loadAgreementDate();
    },
      (error) => {
        console.error(error);
        this.showResponseDialog(PayrollFailure);
        this.isAccepted = false;
      },
      () => {
        this.showResponseDialog(PayrollSuccess);
        this.isAccepted = false;
      });
  }

  public showResponseDialog(PayrollMessages: typeof PayrollFailure | typeof PayrollSuccess) {
    this.dialogTitle = PayrollMessages.dialogTitle;
    this.dialogMessage = PayrollMessages.dialogMessage;
    this.dialog.open(this.template, {
      disableClose: true,
      hasBackdrop: true,
      minWidth: 400
    });
  }

  public closeDialog() {
    this.dialog.closeAll();
  }
}
