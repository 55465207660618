import { Injectable } from '@angular/core';
import { IDecalColor } from '../models/decal-color.model';
import { Observable } from 'rxjs';
import { DecalColorRepository } from '../repository/decal-color.repository';

@Injectable({
  providedIn: 'root'
})

export class DecalColorService {
  constructor(private decalColorRepo: DecalColorRepository) {}

  public getDecalColors(): Observable<IDecalColor[]> {
    return this.decalColorRepo.getDecalColors();
  }

  public addDecalColor(decalColor: IDecalColor | Partial<IDecalColor>): Observable<IDecalColor> {
    return this.decalColorRepo.addDecalColor(decalColor);
  }

  public updateDecalColor(decalColor: IDecalColor): Observable<IDecalColor> {
     return this.decalColorRepo.updateDecalColor(decalColor);
  }
}
