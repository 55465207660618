import { Component, Input, Output, EventEmitter, OnInit, ViewEncapsulation, Renderer2, OnChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ITextBlock } from 'src/app/core/models/textblock.model';
import { TextblockRepository } from 'src/app/core/repository/textblock.repository';

@Component({
  selector: 'app-text-block',
  templateUrl: './textblock.component.html',
  styleUrls: ['./textblock.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TextblockComponent implements OnInit, OnChanges {
  public appTextblock: ITextBlock | undefined;
  public appText: SafeHtml;
  private _code: string;

  @Output() _appTextblock = new EventEmitter();

  @Input()
  set code(value: string) {
    this._code = value;
    this.textblockRepo.getTextblock(this.code).subscribe((textblock) => {
      this.appTextblock = textblock
      this.appText = this.domSanitizer.bypassSecurityTrustHtml(textblock.documentText)
      this._appTextblock.emit(this.appTextblock);
    })
  }

  @Input()
  editOpen?: boolean;

  get code() {
    return this._code;
  }

  constructor(private textblockRepo: TextblockRepository,
    private domSanitizer: DomSanitizer,
    private renderer: Renderer2) { }

  ngOnInit(): void {
    if (this.editOpen == undefined) {
      this.editOpen = true;
    }
  }

  public ngAfterViewChecked() {
    var textBlock = document.querySelector('#textBlock');
    var link = textBlock?.querySelectorAll('a');

    link?.forEach((el: any) => this.renderer.setAttribute(el, 'target', `_blank`));
  }

  ngOnChanges() {
    this.textblockRepo.getTextblock(this.code).subscribe((textblock) => {
      this.appTextblock = textblock
      this.appText = this.domSanitizer.bypassSecurityTrustHtml(textblock.documentText)
    })
  }
}
