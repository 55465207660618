import {Component, OnInit} from '@angular/core';
import {HelloRepository} from "./core/repository/hello.repository";
import {Store} from "@ngrx/store";
import {getCommuterStatusAction, getUserNameAction} from "./core/store/auth/auth.actions";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public title = 'parking-ui';

  constructor(private store: Store){}

  ngOnInit(): void {
    this.store.dispatch(getUserNameAction());
    this.store.dispatch(getCommuterStatusAction());
  }

}
