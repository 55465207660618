<!-- eslint-disable @angular-eslint/template/no-negated-async -->
<div>
    <app-text-block [code]="text"></app-text-block>
</div>

<div *ngIf="(carTruckList$ | async)?.length">
    <div class="vehicle-header">
        <h2 class="list-header-vehicle">Car/Truck</h2>
        <mat-form-field *ngIf="validCarTruckDecalTypes?.length">
            <mat-select data-cy="mat-select" disableOptionCentering placeholder="Choose A Decal" aria-label="Choose a decal" [compareWith]="compare" [(ngModel)]="selectedCarTruckDecal" (ngModelChange)="toggleSelection()">
                <mat-option data-cy="mat-option" *ngFor="let type of validCarTruckDecalTypes" [value]="type" #matOption>
                    <app-decal-type-icon [decalColor]="type.decalColor.code" [decalCode]="type.code" [decalDescription]="type.description"></app-decal-type-icon> {{type.description}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div *ngIf="!validCarTruckDecalTypes && vehicleLoaded">
            <h2 class="list-header-vehicle decalErrorMsg">"No decals are currently available"</h2>
        </div>
    </div>
    <table class="vehicle-table table table-responsive table-responsive-sm">
      <tbody data-cy="vehicle-decal">
        <tr *ngFor="let vehicle of (carTruckList$ | async)" data-cy="vehicle-color">
          <td class="list-vehicle-data">{{vehicle.make.text}} {{vehicle.model.text}}</td>
          <td class="list-decal-valid" *ngIf="selectedCarTruckDecal">
            <img class="filter-green" id="valid-carTruck-icon" src="assets/valid icon.svg" alt="Edit">
            <span class="valid-text">
              Valid
            </span>
          </td>
          <td class="list-year-data">{{vehicle.vehicleYear}}</td>
          <td class="list-license-data">{{vehicle.licenseNumber}}</td>
          <td class="list-color-data">{{vehicle.color.text}}</td>
          <div class="decal-type-wrapper">
            <td class="list-decalIcon-data" *ngIf="selectedCarTruckDecal">
              <app-decal-type-icon data-cy="decal-type-icon" [decalColor]="selectedCarTruckDecal.decalColor.code" [decalCode]="selectedCarTruckDecal.code" [decalDescription]="selectedCarTruckDecal.description"></app-decal-type-icon>
              <span>
                {{selectedCarTruckDecal.description}}
              </span>
            </td>
        </div>
        </tr>
      </tbody>
    </table>
</div>

<div *ngIf="(motorcycleList$ | async)?.length">
    <div class="vehicle-header">
        <h2 class="list-header-vehicle">Motorcycle</h2>
        <mat-form-field *ngIf="validMotorcycleDecalTypes?.length">
            <mat-select disableOptionCentering placeholder="Choose A Decal" [(ngModel)]="selectedMotorcycleDecal" (ngModelChange)="toggleSelection()" [compareWith]="compare">
                <mat-option *ngFor="let type of validMotorcycleDecalTypes" [value]="type" #matOption>
                    <app-decal-type-icon [decalColor]="type.decalColor.code" [decalCode]="type.code" [decalDescription]="type.description"></app-decal-type-icon> {{type.description}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div *ngIf="!validMotorcycleDecalTypes && vehicleLoaded">
            <h2 class="list-header-vehicle decalErrorMsg">"No decals are currently available"</h2>
        </div>
    </div>
    <table class="vehicle-table table table-responsive table-responsive-sm">
      <tbody>
        <tr *ngFor="let vehicle of (motorcycleList$ | async)" data-cy="vehicle-color">
            <td class="list-vehicle-data">{{vehicle.make.text}} {{vehicle.model.text}}</td>
            <td class="list-decal-valid" *ngIf="selectedMotorcycleDecal">
                <img class="filter-green" id="valid-motorcycle-icon" src="assets/valid icon.svg" alt="Edit">
                <span class="valid-text">
                    Valid
                </span>
              </td>
            <td class="list-year-data">{{vehicle.vehicleYear}}</td>
            <td class="list-license-data">{{vehicle.licenseNumber}}</td>
            <td class="list-color-data">{{vehicle.color.text}}</td>
            <div class="decal-type-wrapper">
              <td *ngIf="selectedMotorcycleDecal">
                  <app-decal-type-icon [decalColor]="selectedMotorcycleDecal.decalColor.code" [decalCode]="selectedMotorcycleDecal.code" [decalDescription]="selectedMotorcycleDecal.description"></app-decal-type-icon>
                  <span>
                      {{selectedMotorcycleDecal.description}}
                  </span>
              </td>
            </div>
          </tr>
        </tbody>
    </table>
</div>

<div *ngIf="(bikeList$ | async)?.length">
    <div class="vehicle-header">
        <h2 class="list-header-vehicle">Bike</h2>
        <mat-form-field *ngIf="validBikeDecalTypes?.length">
            <mat-select disableOptionCentering placeholder="Choose A Decal" [compareWith]="compare" [(ngModel)]="selectedBikeDecal" (ngModelChange)="toggleSelection()">
                <mat-option *ngFor="let type of validBikeDecalTypes" [value]="type" #matOption>
                    <app-decal-type-icon [decalColor]="type.decalColor.code" [decalCode]="type.code" [decalDescription]="type.description"></app-decal-type-icon> {{type.description}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div *ngIf="!validBikeDecalTypes && vehicleLoaded">
            <h2 class="list-header-vehicle decalErrorMsg">"No decals are currently available"</h2>
        </div>
    </div>
    <table class="vehicle-table table table-responsive table-responsive-sm">
        <tbody>
          <tr *ngFor="let vehicle of (bikeList$ | async)" data-cy="vehicle-color">
              <td class="list-vehicle-data">{{vehicle.make.text}} {{vehicle.model.text}}</td>
              <td class="list-decal-valid" *ngIf="selectedBikeDecal">
                  <img class="filter-green" id="valid-bike-icon" src="assets/valid icon.svg" alt="Edit">
                  <span class="valid-text">
                      Valid
                  </span>
              </td>
              <td class="list-year-data">{{vehicle.vehicleYear}}</td>
              <td class="list-license-data">{{vehicle.licenseNumber}}</td>
              <td class="list-color-data">{{vehicle.color.text}}</td>
              <div class="decal-type-wrapper">
                <td *ngIf="selectedBikeDecal">
                    <app-decal-type-icon [decalColor]="selectedBikeDecal.decalColor.code" [decalCode]="selectedBikeDecal.code" [decalDescription]="selectedBikeDecal.description"></app-decal-type-icon>
                    <span>
                        {{selectedBikeDecal.description}}
                    </span>
                  </td>
              </div>
            </tr>
        </tbody>
    </table>
</div>

<div class="nav-button-container">
  <button mat-button matStepperPrevious class="backButton">Back</button>
  <div matStepperNext [matTooltipDisabled]="isEnabled | async" matTooltip="Please Choose a Decal">
    <button data-cy="confirmation-button" mat-button matStepperNext class="nextButton" (click)="confirmChosenDecals()" [disabled]="!(isEnabled | async)">Confirmation</button>
  </div>
</div>
