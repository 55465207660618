import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { Store } from "@ngrx/store";
import { IVehicle } from "src/app/core/models/vehicle.model";
import { VehicleService } from "src/app/core/service/vehicle.service";
import { getVehicleListAction } from "src/app/feature/order-process/store/order-decals.actions";

@Component({
    selector: 'app-vehicle-delete-modal',
    templateUrl: './vehicle-delete-modal.component.html',
    styleUrls: ['./vehicle-delete-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VehicleDeleteModal implements OnInit {
    @Input()
    public deleteVehicle: IVehicle;
    @Input()
    public showModal: boolean;

    @Output()
    public showModalChange = new EventEmitter<boolean>();

    constructor(private vehicleService: VehicleService, private store: Store) { }

    ngOnInit(): void {

    }

    public closeModal() {
        this.showModalChange.emit(false);
    }

    public archiveVehicle() {
        this.vehicleService.deleteVehicle(this.deleteVehicle.id).subscribe(() => {
            this.store.dispatch(getVehicleListAction());
        });
        this.showModalChange.emit(false);
    }

}