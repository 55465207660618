import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {IDecalExpiration, IDecalNumberRegistration, IVehicleDecal} from "../models/vehicle-decal.model";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ISelectedVehicleDecalPair } from "../models/selected-vehicle-decal-pair.model";

@Injectable({
  providedIn: 'root'
})

export class VehicleDecalRepository {

  constructor(private http: HttpClient) { }

  /**
   * gets user vehicle decals (a decal type for a user and the list of vehicles)
   * @returns vehicle decals
   */
  public getVehicleDecals(): Observable<IVehicleDecal[]> {
    return this.http.get<IVehicleDecal[]>(`${environment.apiUrl}/user/decals`);
  }

  public requestDecal(decal: ISelectedVehicleDecalPair): Observable<IVehicleDecal[]> {
    return this.http.post<IVehicleDecal[]>(`${environment.apiUrl}/user/decals`, decal)
  }

  public issueDecal(decal: IDecalNumberRegistration): Observable<IVehicleDecal[]> {
    return this.http.put<IVehicleDecal[]>(`${environment.apiUrl}/user/decals/register`, decal)
  }

  public expireDecal(decal: IDecalExpiration): Observable<IVehicleDecal[]> {
    return this.http.put<IVehicleDecal[]>(`${environment.apiUrl}/user/decals/expire`, decal)
  }

  public insertVehicleList(decalPairs: ISelectedVehicleDecalPair[]): Observable<ISelectedVehicleDecalPair[]> {
    return this.http.post<ISelectedVehicleDecalPair[]>(`${environment.apiUrl}/user/decals/request`, decalPairs)
  }
}
