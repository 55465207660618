<link rel="stylesheet" href="navbar.component.scss">
<nav role="navigation">
  <div class="menu-trigger" (click)="toggleMenu()">
    <i class="material-icons">{{isMenuOpen ? "close" : "menu"}}</i>
  </div>
  <div [ngClass]="isMenuOpen ? 'menu-overlay' : ''"></div>
  <ul [class.is-menu-open]="isMenuOpen">
    <li id="decals" [ngClass]="{'active': navLinks['decals']}" (click)="toggleMenu()">
      <a role="tab" [routerLink]="['decals']" aria-current="page">
        <app-icon icon="decal-information" class="decalInfo-icon"></app-icon>
        Decal Information
      </a>
    </li>
    <li id="order-process" [ngClass]="{'active': navLinks['orderProcess']}" (click)="toggleMenu()">
      <a role="tab" [routerLink]="['order-process']" aria-current="page">
        <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
        </svg>
        Order Decals
      </a>
    </li>
    <li id="vehicles" [ngClass]="{'active': navLinks['vehicles']}" (click)="toggleMenu()">
      <a role="tab" [routerLink]="['vehicles']" aria-current="page">
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Choose-Decal" transform="translate(-749.000000, -69.000000)">
              <g id="vehicles" transform="translate(749.000000, 69.000000)">
                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                <path d="M18.92,6.01 C18.72,5.42 18.16,5 17.5,5 L6.5,5 C5.84,5 5.29,5.42 5.08,6.01 L3,12 L3,20 C3,20.55 3.45,21 4,21 L5,21 C5.55,21 6,20.55 6,20 L6,19 L18,19 L18,20 C18,20.55 18.45,21 19,21 L20,21 C20.55,21 21,20.55 21,20 L21,12 L18.92,6.01 Z M6.5,16 C5.67,16 5,15.33 5,14.5 C5,13.67 5.67,13 6.5,13 C7.33,13 8,13.67 8,14.5 C8,15.33 7.33,16 6.5,16 Z M17.5,16 C16.67,16 16,15.33 16,14.5 C16,13.67 16.67,13 17.5,13 C18.33,13 19,13.67 19,14.5 C19,15.33 18.33,16 17.5,16 Z M5,11 L6.5,6.5 L17.5,6.5 L19,11 L5,11 Z" id="Shape" fill="currentColor" fill-rule="evenodd"></path>
              </g>
            </g>
          </g>
        </svg>
        Vehicles
      </a>
    </li>
    <li id="payroll-deduction" *ngIf="isEmployee$ | async" [ngClass]="{'active': navLinks['payrollDeduction']}" (click)="toggleMenu()">
      <a role="tab" [routerLink]="['payroll-deduction']" aria-current="page">
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Deduction" transform="translate(-792.000000, -71.000000)">
              <g id="payroll-deduction" transform="translate(792.000000, 71.000000)">
                <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                <path d="M14,2 L6,2 C4.9,2 4.01,2.9 4.01,4 L4,20 C4,21.1 4.89,22 5.99,22 L18,22 C19.1,22 20,21.1 20,20 L20,8 L14,2 Z M15,12 L11,12 L11,13 L14,13 C14.55,13 15,13.45 15,14 L15,17 C15,17.55 14.55,18 14,18 L13,18 L13,19 L11,19 L11,18 L9,18 L9,16 L13,16 L13,15 L10,15 C9.45,15 9,14.55 9,14 L9,11 C9,10.45 9.45,10 10,10 L11,10 L11,9 L13,9 L13,10 L15,10 L15,12 Z M13,8 L13,3.5 L17.5,8 L13,8 Z" id="Shape" fill="currentColor" fill-rule="evenodd"></path>
              </g>
            </g>
          </g>
        </svg>
        Payroll Deduction
      </a>
    </li>
    <li id="configuration" *ngIf="(isAdmin$ | async) && (isImpersonating$ | async) === false && !(isMobileView$ | async)?.matches" [ngClass]="{'active': navLinks['configuration']}">
      <a role="tab" [routerLink]="['configuration']" aria-current="page">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-gear-fill" viewBox="0 0 16 16">
          <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
        </svg>
        Configuration
      </a>
    </li>
  </ul>
</nav>
