<h2 data-cy="title">Roll Over Decals</h2>
<p>Select the decal types to roll over to next year.</p>

<form id="yearForm" [formGroup]="yearForm">
  <select formControlName="year" data-cy="select-year">
    <option *ngFor="let yearSelectOption of yearSelectOptions" [ngValue]="yearSelectOption.year">{{yearSelectOption.range}}</option>
  </select>
  <label class="selections">
  <input type="checkbox" class="input-checkbox" (click)="SelectAllDecalTypes()" [checked]="isSelectAllChecked" data-cy="select-all">
    Select All?
  </label>
  <label class="selections">
    <input type="checkbox" class="input-checkbox" (click)="ClearAllDecalTypes()" [checked]="isClearAllChecked" data-cy="clear-all">
    Clear All?
  </label>
  <label class="selections">
    <input type="checkbox" class="input-checkbox" (click)="toggleFilterInactive()" [checked]="isShowInActiveChecked" data-cy="inactive">
    Include Inactive?
  </label>
</form>

<div class="headerContainer">
  <h3 class="decalTypeCodeColumn">Code</h3>
  <h3 class="decalTypeDescriptionColumn">Description</h3>
</div>

<div id="decalTypes">
    <ul *ngIf="decals">
      <li *ngFor="let decal of filteredDecalTypes()" [ngClass]="decal.active === false ? 'inactiveDecalType' : ''" data-cy="decal-type">
        <label class="selectDecalType">
          <input type="checkbox" [checked]="isDecalSelected(decal)" (click)="toggleSelectDecal(decal)" class="decal-checkbox" data-cy="decal-type-checkbox">
        </label>
        <div><app-decal-type-icon [decalColor]="decal.decalColor.code" [decalCode]="decal.code" [decalDescription]="decal.description"></app-decal-type-icon></div>
        <div class="decal-description" data-cy="decal-desc">{{decal.description}}</div>
      </li>
    </ul>
</div>
<div class="rollover-button-container">
  <button type="button" class ="rollover-button" (click)="rolloverDecals()" data-cy="rollover-button"> Roll Over Decals </button>
</div>

<ng-template #dialogTemplate>
  <h2 mat-dialog-title>{{dialogTitle}}</h2>
  <div mat-dialog-content>
    {{dialogMessage}}
  </div>
  <div class="OKButton" mat-dialog-actions align="end">
    <button style="background-color: #246EC0 !important; color: white;"  mat-raised-button (click)="closeDialog()" data-cy="ok-button">OK</button>
  </div>
</ng-template>
