<div class="vehicle-table" *ngIf="showHeader">
    <h2 class="list-header-vehicle">Vehicle</h2>
    <h2 class="list-header-year">Year</h2>
    <h2 class="list-header-license">License</h2>
    <h2 class="list-header-color">Color</h2>
</div>
<tr *ngFor="let vehicle of vehicleList" data-cy="vehicle-color" class="spacer">
  <td *ngIf="showCheckbox" id="input-checkbox">
    <input type="checkbox" class="input-checkbox" disabled [ngModel]="isChecked">
  </td>
  <td class="list-vehicle-data">{{vehicle.make.text}} {{vehicle.model.text}}</td>
  <td class="list-year-data">{{vehicle.vehicleYear}}</td>
  <td class="list-license-data">{{vehicle.licenseNumber}}</td>
  <td class="list-color-data">{{vehicle.color.text}}</td>
  <td class="buttonWrapper">
    <div class="list-button">
      <button type="button" class="deleteButton" (click)="openDelete(vehicle)" *ngIf="showDelete" attr.aria-label="Delete {{vehicle.make.text}} {{vehicle.model.text}} from list">
        <img id="delete-icon" src="assets/delete.svg" alt="Delete">
        Archive
      </button>
    </div>
    <div class="list-button">
      <button type="button" class="editButton" (click)="openEdit(vehicle)" *ngIf="showEdit" attr.aria-label="Edit {{vehicle.make.text}} {{vehicle.model.text}} information">
        <img id="edit-icon" src="assets/edit.svg" alt="Edit">
        Edit
      </button>
    </div>
  </td>
</tr>

  <div *ngIf="showEditModal">
    <app-vehicle-add-modal [(showModal)]="showEditModal" [editVehicle]="vehicleToEdit" appTrapFocus></app-vehicle-add-modal>
  </div>

  <div *ngIf="showDeleteModal">
    <app-vehicle-delete-modal [(showModal)]="showDeleteModal" [deleteVehicle]="vehicleToDelete" appTrapFocus></app-vehicle-delete-modal>
  </div>
