import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Observable, of } from "rxjs";
import { UserService } from "../core/service/user/user.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss']
})
export class HeaderComponent implements OnInit {
  public user$: Observable<string | undefined>;
  public canImpersonate$: Observable<boolean>;
  public isImpersonating$: Observable<boolean>;
  public isMobileView$: Observable<BreakpointState>;
  public isModalOpen = false;

  public impersonateForm: FormGroup;

  @ViewChild('impersonateInput')
  public impersonateInput: ElementRef;

  constructor(private userService: UserService,
    private formBuilder: FormBuilder,
    private breakpointObserver: BreakpointObserver) { }

  ngOnInit() {
    this.user$ = this.userService.getCurrentUser();
    this.canImpersonate$ = this.userService.canImpersonate();
    this.isImpersonating$ = this.userService.isImpersonating();
    this.isMobileView$ = this.breakpointObserver.observe(["(max-width: 700px)"])

    this.impersonateForm = this.formBuilder.group({
      input: ['', Validators.required]
    });
  }

  public signOut(): void {
    this.userService.signOut();
  }

  public showImpersonateModal(): void {
    this.isModalOpen = true;
    setTimeout(() => this.impersonateInput.nativeElement.focus(), 0);
  }

  public closeModal() {
    this.isModalOpen = false;
    this.impersonateForm.reset();
  }

  public submitForm(): void {
    const input = this.impersonateForm.get('input')?.value;
    this.userService.impersonate(input);
    this.closeModal();
  }

  public stopImpersonating(): void {
    this.userService.stopImpersonating();
  }
}
