import { Component, OnInit } from '@angular/core';
import { VehicleMakeService } from '../../../core/service/vehicle-make.service';
import { IVehicleModel } from '../../../core/models/vehicle-model.model';
import { IVehicleMake } from '../../../core/models/vehicle-make.model';
import { UntypedFormBuilder} from "@angular/forms";

@Component({
  selector: 'app-vehicle-model',
  templateUrl: './vehicle-model.component.html',
  styleUrls: ['./vehicle-model.component.scss']
})
export class VehicleModelComponent implements OnInit {

  public vehicleModels: IVehicleModel[] = [];
  public vehicleMakes: IVehicleMake[] = [];
  public selectedMake: IVehicleMake | undefined;
  public isModalOpen: boolean = false;
  public stagedModel: IVehicleModel | undefined;

  public modelForm = this.formBuilder.group({
    vehicleModel: '',
    vehicleMake: ''
  })

  constructor(private vehicleMakeService: VehicleMakeService, private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.getVehicleMakes();
  }

  public get vehicleModel(): any {
    return this.modelForm.get('vehicleModel');
  }

  public get vehicleMake(): any {
    return this.modelForm.get('vehicleMake')
  }

  public changeVehicleMake(vmake: any) {
    this.vehicleMake.setValue(vmake);
  }

  /**
   * Get vehicle makes from the vehicle make service.
   * Set initial make to the first make in the list.
   */
  public getVehicleMakes(): void {
    this.vehicleMakeService.getVehicleMakes().subscribe((makes: IVehicleMake[]) => {
      this.vehicleMakes = makes;
      this.selectedMake = this.vehicleMakes[0];
      this.onMakeSelected({target: {value: this.selectedMake.id}});
    })
  }

  /**
   *  Get list of vehicle models for make selection.
   * @param $event
   */
  public onMakeSelected($event: any) {
    let makeId = Number($event.target.value);
    let vehicleMake = this.vehicleMakes.find((vmake: IVehicleMake) => vmake.id === makeId);
    this.changeVehicleMake(vehicleMake);

    this.vehicleMakeService.getModels(makeId).subscribe((models: IVehicleModel[]) => {
      this.vehicleModels = models;
    })

  }

  /**
   * Method to submit the model form. If a model is being edited, it will update the model.
   * Otherwise, it will add a new model to the list of vehicle models.
   */
  public submitForm(): void {
    if (this.stagedModel) {
      this.vehicleMakeService.updateModel({...this.stagedModel, text: this.vehicleModel.value, make: this.vehicleMake.value})
      .subscribe((updatedModel: IVehicleModel) => {
        this.vehicleModels = this.vehicleModels.map((model: IVehicleModel) => model.id === updatedModel.id ? updatedModel : model)
        this.closeModal();
      })
    } else {
      let newModel: IVehicleModel = {
        id: null,
        text: this.vehicleModel.value,
        make: this.vehicleMake.value
      }

      this.vehicleMakeService.addModel(newModel).subscribe((model: IVehicleModel) => {
        this.vehicleModels.push(model);
        this.closeModal();
      })
    }
  }

  /**
   * Method to open the modal for adding or editing a new vehicle model.
   * @param model
   */
  public openModal(model: IVehicleModel | undefined) {
    this.isModalOpen = true;
    if(model !== undefined) {
      this.stagedModel = model;
      let indexOfVehicleMake = this.vehicleMakes.findIndex(vmake => vmake.id === model.make.id)
      this.modelForm.setValue({vehicleModel: model.text, vehicleMake: this.vehicleMakes[indexOfVehicleMake]});
    }
  }

  public closeModal() {
    this.stagedModel = undefined;
    this.isModalOpen = false;
    this.modelForm.reset();
  }
}
