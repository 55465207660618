import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ILogMessage } from '../models/logMessage.model';
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class LogMessageRepository{
  constructor(private http: HttpClient) {}

  public getLogMessage(): Observable< ILogMessage[]> {
    return this.http.get<ILogMessage[]>(`${environment.apiUrl}/decal-assignment/log`);
  }

  public getTestErrorMessage(): Observable<Response> {
    return this.http.get<Response>(`${environment.apiUrl}/generateError2`);
  }

  public getfirstTestErrorMessage(): Observable<String> {
    return this.http.get<String>(`${environment.apiUrl}/generateError`);
  }
}
