import { Component, OnDestroy, OnInit } from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {NavigationEnd, Router} from "@angular/router";

const defaultNavLinks: any = {
  vehicleColor: false,
  vehicleType: false,
  vehicleMake: false,
  vehicleModel: false,
  decalColor: false,
  decalType: false,
  autoAssign: false,
  rollover: false,
  textConfiguration: false
}

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})

export class ConfigurationComponent implements OnInit, OnDestroy {
  public navLinks: any = {
    vehicleColor: window.location.href.includes('vehicle-color'),
    vehicleType: window.location.href.includes('vehicle-type'),
    vehicleMake: window.location.href.includes('vehicle-make'),
    vehicleModel: window.location.href.includes('vehicle-model'),
    decalColor: window.location.href.includes('decal-color'),
    decalType: window.location.href.includes('decal-type'),
    autoAssign: window.location.href.includes('auto-assign'),
    rollover: window.location.href.includes('rollover'),
    textConfiguration: window.location.href.includes('text-configuration')
  }

  public endSubscriptions$ = new Subject();

  constructor(private router: Router) { }

  ngOnInit() {
    this.router.events.pipe(takeUntil(this.endSubscriptions$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let nextSegment = event.urlAfterRedirects.indexOf('/configuration', 1);
        if (nextSegment > 0) {
          this.updateSelected(event.urlAfterRedirects.substring(1, nextSegment));
        } else {
          this.updateSelected(event.urlAfterRedirects.substring(1));
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.endSubscriptions$.next(true);
    this.endSubscriptions$.unsubscribe();
  }

  updateSelected(val: string) {
    if (val == 'configuration/vehicle-color') {
      val = 'vehicleColor';
    } else if (val == 'configuration/vehicle-type') {
      val = 'vehicleType';
    } else if (val == 'configuration/vehicle-make') {
      val = 'vehicleMake';
    } else if (val == 'configuration/vehicle-model') {
      val = 'vehicleModel';
    } else if (val == 'configuration/decal-color') {
      val = 'decalColor';
    } else if (val == 'configuration/decal-type') {
      val = 'decalType';
    } else if (val == 'configuration/auto-assign') {
      val = 'autoAssign';
    } else if (val == 'configuration/rollover') {
      val = 'rollover';
    } else if (val == 'configuration/text-configuration') {
      val = 'textConfiguration';
    }
    this.navLinks = {...defaultNavLinks, [val]: true}
  }
}
