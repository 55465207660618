import { Injectable } from "@angular/core";
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})

export class MessageService{

  constructor(private messageBar: MatSnackBar) { }

  public showMessage(message: string, action?: string) {
    if (typeof action !== 'undefined') {
      this.messageBar.open(message, action);
    } else {
      this.messageBar.open(message, "OK");
    }
  }

  public showErrorMessage(message: string, action?: string) {
    if (typeof action !== 'undefined') {
      this.messageBar.open(message, action, {
        panelClass: ['alert-red'],
      });
    } else {
      this.messageBar.open(message, "OK", {
        panelClass: ['alert-red'],
      });
    }
  }

}
