import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { IVehicle } from "src/app/core/models/vehicle.model";

@Component({
    selector: 'app-vehicle-list',
    templateUrl: './vehicle-list.component.html',
    styleUrls: ['./vehicle-list.component.scss'],
})
export class VehicleListComponent implements OnInit {

  @Input()
  public vehicleList: IVehicle[];

  @Input()
  public showDelete: boolean;

  @Input()
  public showEdit: boolean;

  @Input()
  public showHeader: boolean;

  @Input()
  public width: 'vehicleListWidth';

  @Input()
  public group: 'vehicleStatus';

  @Input()
  public btnFormat: 'vehicleEditBtn';

  @Input()
  public tableRowFormat: 'customRow';

  @Output() handleEditEvent = new EventEmitter<IVehicle>();
  @Output() handleDeleteEvent = new EventEmitter<IVehicle>();

  constructor(){}

  //TODO: might need to do something here to update if the lists change
  ngOnInit() {
  }

  public editVehicle(vehicle: IVehicle): void {
    this.handleEditEvent.emit(vehicle);
  }

  public deleteVehicle(vehicle: IVehicle): void {
    this.handleDeleteEvent.emit(vehicle);
  }

}
