<div class="modalBackground">
    <div class="modal">
      <h2>Are you sure you want to archive this vehicle?</h2>
      <div class="rowHolder">
          <div>{{deleteVehicle.make.text}} {{deleteVehicle.model.text}}</div>
          <div>{{deleteVehicle.vehicleYear}}</div>
          <div>{{deleteVehicle.licenseNumber}}</div>
          <div>{{deleteVehicle.color.text}}</div>
      </div>
      <div class="rowHolder">
          <button (click)="closeModal()">Cancel</button>
          <button (click)="archiveVehicle()">Confirm</button>
      </div>
    </div>
</div>
