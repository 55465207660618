import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class ModalComponent implements AfterViewInit{

  @Input() height?: number;

  @Output()
  closeModalEvent = new EventEmitter<null>();

  @ViewChild('closeButton')
  public closeRef: ElementRef;

  constructor() {}

  closeModal() {
    this.closeModalEvent.emit();
  }

  ngAfterViewInit() {
    this.closeRef.nativeElement.focus();
    document.addEventListener('keydown', this.escapeHandler)
  }

  private escapeHandler = (e: { key: string; }) => {
    if(e.key === 'Escape'){
      this.closeModal();
    }
  }
}
