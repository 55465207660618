import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from "@ngrx/store";
import { catchError, delay, filter, switchMap, tap } from "rxjs/operators";
import {
  setAgreementInfoAction,
  submitDecalsAction,
  submitDecalsActionSuccess,
  submitGeneralAgreementAction,
  submitGeneralAgreementActionFailure,
  submitGeneralAgreementActionSuccess,
  submitPayrollAgreementAction,
  submitPayrollAgreementActionSuccess,
  submitPhoneAction,
  submitPhoneActionFailure,
  submitPhoneActionSuccess
} from "../order-decals.actions";
import { getVehicleDecalPairsSelector, isAgreementStepCompleteSelector, isAgreementStepDataCompleteSelector, getEmergencyPhone, getPayrollContractAgreedSelector } from '../order-decals.selectors';
import { combineLatest, map, of, take } from "rxjs";
import { UserRepository } from "../../../../core/repository/user.repository";
import { HttpErrorResponse } from "@angular/common/http";
import { IEmergencyPhone } from "../../../../core/models/emergency-phone.model";
import { ISelectedVehicleDecalPair } from "src/app/core/models/selected-vehicle-decal-pair.model";
import { VehicleDecalRepository } from "src/app/core/repository/vehicle-decal.repository";

@Injectable()
export class SetAgreementInfoEffect {

  // noinspection TypeScriptValidateTypes
  dataCompleteToSubmitPhone$ = createEffect(() => this.actions$.pipe(
    ofType(setAgreementInfoAction),
    switchMap(() => {
      return this.store.pipe(
        select(isAgreementStepDataCompleteSelector),
        take(1),
        filter(dataComplete => dataComplete),
        map(() => submitPhoneAction())
      )
    })
  ));

  // noinspection TypeScriptValidateTypes
  submitPhone$ = createEffect(() => this.actions$.pipe(
    ofType(submitPhoneAction),
    concatLatestFrom(() => this.store.pipe(select(getEmergencyPhone))),
    switchMap(([,phone]) => {
      return this.userRepo.postEmergencyPhone(<IEmergencyPhone>{ phone: phone }).pipe(
        map(() => submitPhoneActionSuccess()),
        catchError((errorResponse: HttpErrorResponse) => of(submitPhoneActionFailure(errorResponse)))
      )
    })
  ));

  // noinspection TypeScriptValidateTypes
  dataCompleteToSubmitGeneralAgreement$ = createEffect(() => this.actions$.pipe(
    ofType(setAgreementInfoAction),
    switchMap(() => {
      return this.store.pipe(
        select(isAgreementStepDataCompleteSelector),
        take(1),
        filter(dataComplete => dataComplete),
        map(() => submitGeneralAgreementAction())
      )
    })
  ));

  // noinspection TypeScriptValidateTypes
  submitGeneralAgreement$ = createEffect(() => this.actions$.pipe(
    ofType(submitGeneralAgreementAction),
    switchMap(() => {
      return this.userRepo.postUserAgreement('PARKIN').pipe(
        map(() => submitGeneralAgreementActionSuccess()),
        catchError((errorResponse: HttpErrorResponse) => of(submitGeneralAgreementActionFailure(errorResponse)))
      )
    })
  ));

  // noinspection TypeScriptValidateTypes
  dataCompleteToSubmitPayrollAgreement$ = createEffect(() => this.actions$.pipe(
    ofType(setAgreementInfoAction),
    concatLatestFrom(() => this.store.pipe(select(getPayrollContractAgreedSelector))),
    switchMap(([,agreedPayroll]) => {
      return this.store.pipe(
        select(isAgreementStepDataCompleteSelector),
        take(1),
        // this is only applicable to some users, so we only want to submit if they actually agreed to it
        filter(dataComplete => dataComplete && agreedPayroll),
        map(() => submitPayrollAgreementAction())
      )
    })
  ));

  // noinspection TypeScriptValidateTypes
  submitPayrollAgreement$ = createEffect(() => this.actions$.pipe(
    ofType(submitPayrollAgreementAction),
    switchMap(() => {
      return this.userRepo.postUserAgreement('PAYROL').pipe(
        map(() => submitPayrollAgreementActionSuccess()),
        catchError((errorResponse: HttpErrorResponse) => of(submitGeneralAgreementActionFailure(errorResponse)))
      )
    })
  ));

  // noinspection TypeScriptValidateTypes
  dataCompleteToSubmitDecalAction$ = createEffect(() => this.actions$.pipe(
    ofType(setAgreementInfoAction),
    concatLatestFrom(() => this.store.pipe(select(isAgreementStepDataCompleteSelector))),
    filter(([, isAgreementComplete]) => isAgreementComplete),
    switchMap(() => {
      return this.store.pipe(
        select(getVehicleDecalPairsSelector),
        take(1),
        map((decalPairs) => submitDecalsAction({ vehicleDecalPairs: decalPairs }))
      )
    })
  ));

  // noinspection TypeScriptValidateTypes
  submitDecals$ = createEffect(() => this.actions$.pipe(
    ofType(submitDecalsAction),
    switchMap(({ vehicleDecalPairs }) => {
      return this.decalRepo.insertVehicleList(vehicleDecalPairs).pipe(
        map((results) => submitDecalsActionSuccess({ results: results }))
      )
    })
  ));

  //copy this for each data field
  //add effects for the new actions that talk to their repos and submit the data

  constructor(private actions$: Actions,
    private store: Store,
    private userRepo: UserRepository,
    private decalRepo: VehicleDecalRepository) {
  }
}
