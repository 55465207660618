import {Component, OnDestroy, OnInit, ElementRef, Renderer2} from '@angular/core';
import { select, Store } from '@ngrx/store';
import {Observable, Subject, takeUntil, of} from "rxjs";
import {UserService} from "../core/service/user/user.service";
import {NavigationEnd, Router} from "@angular/router";
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";
import { isImpersonatingSelector } from '../core/store/auth/auth.selectors';

const defaultNavLinks: any = {
  decals: false,
  vehicles: false,
  payrollDeduction: false,
  configuration: false,
  orderProcess: false
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  public navLinks: any = {
    decals: window.location.href.includes('decals'),
    orderProcess: window.location.href.includes('order-process'),
    vehicles: window.location.href.includes('vehicles'),
    payrollDeduction: window.location.href.includes('payroll-deduction'),
    configuration: window.location.href.includes('configuration')
  }

  public isAdmin$: Observable<boolean>;
  public isEmployee$: Observable<boolean>;
  public isImpersonating$: Observable<boolean>;
  public isMobileView$: Observable<BreakpointState>;
  public endSubscriptions$ = new Subject();
  public isMenuOpen = false;

  constructor(private router: Router,
              private userService: UserService,
              private store: Store,
              private el: ElementRef,
              private renderer: Renderer2,
              private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    this.isAdmin$ = this.userService.isAdmin();
    this.isEmployee$ = this.userService.isEmployee();
    this.isImpersonating$ = this.store.pipe(select(isImpersonatingSelector));
    this.isMobileView$ = this.breakpointObserver.observe(["(max-width: 700px)"])

    this.router.events.pipe(takeUntil(this.endSubscriptions$))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          let nextSegment = event.urlAfterRedirects.indexOf('/', 1);
          if (nextSegment > 0) {
            this.updateSelected(event.urlAfterRedirects.substring(1, nextSegment));
          } else {
            this.updateSelected(event.urlAfterRedirects.substring(1));
          }

        }
      });
  }

  ngOnDestroy(): void {
    this.endSubscriptions$.next(true);
    this.endSubscriptions$.unsubscribe();
  }

  updateSelected(val: string) {
    if (val == 'order-process') {
      val = 'orderProcess';
    } else if (val == 'payroll-deduction') {
      val = 'payrollDeduction';
    }
    this.navLinks = {...defaultNavLinks, [val]: true}
  }

  public toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;

    if (this.isMenuOpen) {
      this.renderer.setStyle(document.body, 'overflow', 'hidden');
    } else {
      this.renderer.removeStyle(document.body, 'overflow');
    }
  }
}
