import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, exhaustMap, map, of } from "rxjs";
import { VehicleRepository } from "src/app/core/repository/vehicle.repository";
import { setVehicleListAction, setVehicleListFailureAction } from "../order-decals.actions";

@Injectable()
export class SetVehicleListEffect {

    // noinspection TypeScriptValidateTypes
    setVehicleList$ = createEffect(() => this.actions$.pipe(
        ofType(setVehicleListAction),
        exhaustMap(() => {
            return this.vehicleRepo.getVehicles().pipe(
                map(response => setVehicleListAction({ vehicleList: response })),
                catchError((errorResponse: HttpErrorResponse) => of(setVehicleListFailureAction({ error: errorResponse })))
            );
        })
    ));

    constructor(private actions$: Actions,
        private vehicleRepo: VehicleRepository
    ) {
    }
}
