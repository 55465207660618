import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DecalsComponent } from "./feature/decals/decals.component";
import { VehiclesComponent } from "./feature/vehicles/vehicles.component";
import { PayrollDeductionComponent } from "./feature/payroll-deduction/payroll-deduction.component";
import { ConfigurationComponent } from "./feature/configuration/configuration.component"
import { VehicleColorComponent } from "./feature/configuration/vehicle-color/vehicle-color.component";
import { VehicleMakeComponent } from "./feature/configuration/vehicle-make/vehicle-make.component";
import { VehicleModelComponent } from "./feature/configuration/vehicle-model/vehicle-model.component";
import { DecalColorComponent } from './feature/configuration/decal-color/decal-color.component';
import { DecalTypeComponent } from './feature/configuration/decal-type/decal-type.component';
import { DecalRestrictionComponent } from './feature/configuration/decal-restriction/decal-restriction.component';
import { OrderProcessComponent } from "./feature/order-process/order-process/order-process.component";
import { AutoAssignComponent } from './feature/configuration/auto-assign/auto-assign.component';
import { RolloverComponent } from './feature/configuration/rollover/rollover.component';
import { TextConfigurationComponent } from './feature/configuration/text-configuration/text-configuration.component';
import { guards } from './guards.module';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'decals',
    pathMatch: 'full'
  }, {
    path: 'myParkingDecal',
    redirectTo: 'decals',
    pathMatch: 'full'
  }, {
    path: 'decals',
    component: DecalsComponent
  }, {
    path: 'vehicles',
    component: VehiclesComponent
  }, {
    path: 'payroll-deduction',
    component: PayrollDeductionComponent
  }, {
    path: 'configuration',
    component: ConfigurationComponent,
    children: [
      {
        path: 'vehicle-color',
        component: VehicleColorComponent
      }, {
        path: 'vehicle-make',
        component: VehicleMakeComponent
      }, {
        path: 'vehicle-model',
        component: VehicleModelComponent
      }, {
        path: 'decal-color',
        component: DecalColorComponent
      }, {
        path: 'decal-type',
        component: DecalTypeComponent,
        /* children: [
          {
            path: ':id/restriction',
            component: DecalRestrictionComponent
          }
        ] */
      }, {
        path: 'decal-type/:id/restriction',
        component: DecalRestrictionComponent
      },  {
        path: 'auto-assign',
        component: AutoAssignComponent
      },  {
        path: 'rollover',
        component: RolloverComponent
      }, {
        path: 'text-configuration',
        component: TextConfigurationComponent
      }
    ]
  }, {
    path: 'order-process',
    component: OrderProcessComponent,
    canActivate: [guards.OrderProcessGuard]
  }

];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
  })
],
  exports: [RouterModule]
})
export class AppRoutingModule { }
