import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, Subject, take } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment'
import { Router } from "@angular/router";
import { IAgreement } from '../models/agreement.model';
import { IAddress } from "../models/address.model";
import { IEmergencyPhone } from "../models/emergency-phone.model";
import { IDecalType } from '../models/decal-type.model';

//this subject is used to bust the identity cache
const identityCacheBuster = new Subject<void>();

@Injectable({ providedIn: 'root' })
export class UserRepository {
  public readonly impersonateUrl: string = `${environment.apiUrl}/switch-user?username=`;
  public readonly unauthenticatedUrl: string = environment.unauthenticatedUrl();

  constructor(
    private http: HttpClient,
    private router: Router,
    // private storageService: StorageService,
  ) { }

  /**
   * Get list of user roles.
   */
  public getUserRoles(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiUrl}/roles.json`);
  }

  /**
   * Identifies the current user
   *
   * @returns {Observable<string>} the username being impersonated, or the actually logged in user
   * @memberof UserRepository
   */
  public getPerson(): Observable<any> {
    return forkJoin(
      this.http.get<any>(`${environment.apiUrl}/hello-user`),
      this.http.get<Array<string>>(`${environment.apiUrl}/roles.json`),
    ).pipe(
      map(([hello, roles]: [any, any]) => Object.assign({}, hello, { roles })),
      tap((hello: any) => {
        console.log(hello);
      })
    );
  }

  /**
   * get a user agreement based on code type (ie. PARKIN, PAYROL)
   * @param code
   * @returns
   */
  public getUserAgreement(code: string): Observable<IAgreement> {
    return this.http.get<IAgreement>(`${environment.apiUrl}/user/agreement?id=${code}`);
  };

  public postUserAgreement(code: string): Observable<IAgreement> {
    return this.http.post<IAgreement>(`${environment.apiUrl}/user/agreement`, code);
  };

  public getEmployeeStatus(): Observable<boolean> {
    return this.http.get<boolean>(`${environment.apiUrl}/user/employee-status`);
  };

  public getEmergencyPhone(): Observable<IEmergencyPhone> {
    return this.http.get<IEmergencyPhone>(`${environment.apiUrl}/user/emergency-phone`);
  }

  public postEmergencyPhone(phone: IEmergencyPhone): Observable<IEmergencyPhone> {
    return this.http.post<IEmergencyPhone>(`${environment.apiUrl}/user/emergency-phone`, phone);
  }

  public getCommuterStatus(): Observable<boolean> {
    return this.http.get<boolean>(`${environment.apiUrl}/user/commuter-status`);
  }

  public getAddress(): Observable<IAddress> {
    return this.http.get<IAddress>(`${environment.apiUrl}/user/address`);
  }

  public impersonateUser(username: string): Observable<string> {
    return this.http.post(`${this.impersonateUrl}${username}`, null, { responseType: "text" });
  }

  public stopImpersonating(): Observable<string> {
    return this.http.post(`${environment.apiUrl}/exit-user`, null, { responseType: 'text' });
  }

  public getUsername(userId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/user?id=${userId}`, { responseType: 'text' });
  }

  public getAvailableDecalTypes(vehicleTypeId: number): Observable<IDecalType[]> {
    return this.http.get<IDecalType[]>(`${environment.apiUrl}/user/user-decals?vehicleTypeId=${vehicleTypeId}`);
  }

  public getLuid(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/user/luid`, { responseType: 'text' });
  }

  //   public getUserRoles(): Observable<any> {
  //     return this.getPerson().pipe(
  //       map((hello: IHello) => hello.roles)
  //     );
  //   }
  //
  //   //because of a Spring Security update, this is now a POST instead of a GET
  //   public impersonateUser(username: string): Observable<IHello> {
  //     return this.http.post<IHello>(`${this.impersonateUrl}${username}`, null);
  //   }
  //
  //   //because of a Spring Security update, this is now a POST instead of a GET
  //   public stopImpersonating(): Observable<any> {
  //     return this.http.post(`${environment.apiUrl}/exit-user`, null);
  //   }
  //
  //   public getLogoutUrl(): string {
  //     this.bustIdentityCache(); //TODO: it works to do this here, but this method is technically just returning the url - refactor!
  //
  //     return `${environment.apiUrl}/logout`;
  //   }
  //
  //   public bustIdentityCache(): void {
  //     this.storageService.loggedInUser = null;
  //     identityCacheBuster.next();
  //   }
  //
  //   private getLoggedInUser(hello: IHello): string {
  //     return (hello.impersonatingUser || hello.currentUser).username;
  //   }
}
