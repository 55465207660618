import { Injectable } from "@angular/core";
import { LogMessageRepository } from '../repository/logMessage.repository';
import { Observable } from 'rxjs';
import { ILogMessage } from "../models/logMessage.model";

@Injectable({
  providedIn: 'root'
})

export class LogMessageService{

  constructor(private logMessageRepo: LogMessageRepository) {}

  public getLogMessage(): Observable<ILogMessage[]> {
    return this.logMessageRepo.getLogMessage();
  }
}
