import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IDecalRestriction } from '../models/decal-restriction.model';
import { IRestrictionType } from '../models/restriction-type.model';
import { environment } from '../../../environments/environment';

@Injectable ({
  providedIn: 'root'
})

export class DecalRestrictionRepository {
  constructor(private http: HttpClient) {}

  public getDecalRestriction(decalId: number): Observable<IDecalRestriction[]> {
    return this.http.get<IDecalRestriction[]>(`${environment.apiUrl}/decal-types/${decalId}/restrictions`);
  }

  public getRestrictionTypes(): Observable<IRestrictionType[]> {
    return this.http.get<IRestrictionType[]>(`${environment.apiUrl}/restriction-types`);
  }

  public addRestriction(decalRestriction: IDecalRestriction): Observable<IDecalRestriction> {
    return this.http.post<IDecalRestriction>(`${environment.apiUrl}/decal-types/${decalRestriction.decalType.id}/restrictions`, decalRestriction);
  }

  public updateRestriction(decalRestrictionType: IDecalRestriction): Observable<IDecalRestriction> {
    return this.http.put<IDecalRestriction>(`${environment.apiUrl}/decal-types/${decalRestrictionType.decalType.id}/restrictions/${decalRestrictionType.id}`, decalRestrictionType);
  }

  public deleteRestriction(decalRestriction: IDecalRestriction): Observable<{}> {
    return this.http.delete<IDecalRestriction>(`${environment.apiUrl}/decal-types/${decalRestriction.decalType.id}/restrictions/${decalRestriction.id}`);
  }
}
