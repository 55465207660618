import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { switchMap } from "rxjs/operators";
import { tap } from "rxjs";
import { setStepNumberAction } from "../order-decals.actions";
import { Router } from "@angular/router";
import { getStepNumberSelector } from "../order-decals.selectors";

@Injectable()
export class StepNumberEffect {

  //noinspection TypeScriptValidateTypes
  routeAfterSetStepNumber$ = createEffect(() => this.actions$.pipe(
    ofType(setStepNumberAction),
    switchMap(() => {
      return this.store.pipe(select(getStepNumberSelector)).pipe(
        tap((stepNumber) => {
          this.router.navigateByUrl(`/order-decals/step-${stepNumber}`)
        })
      )
    })
  ), { dispatch: false });

  constructor(private actions$: Actions,
    private router: Router,
    private store: Store,) {
  }
}
