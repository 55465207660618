<div class="header-bar">
  <div class="left">
    <img id="liberty-logo" src="assets/lu.svg" alt="Liberty Logo">
    <span>Parking</span>
  </div>

  <div class="right">
    <app-icon icon="user-secret" class="impersonate-icon" tabindex="0" aria-label="Impersonate a user push button" (keyup.enter)="showImpersonateModal()" (click)="showImpersonateModal()" *ngIf="(canImpersonate$ | async) && !(isMobileView$ | async)?.matches"></app-icon>
    <span>{{user$ | async}}</span>
    <img tabindex="0" aria-label="Sign Out" role="link" (keyup.enter)="signOut()" (click)="signOut()" id="box-arrow-in-right" src="assets/box-arrow-in-right.svg" alt="Close Right Icon">
  </div>
</div>

<div *ngIf="isImpersonating$ | async" class="impersonating-bar">
  <span class="impersonating-text">You are currently impersonating &nbsp;{{user$ | async}}.</span>
  <button mat-stroked-button class="stop-button" (click)="stopImpersonating()">Stop Impersonating</button>
</div>

<app-modal [height]="250" (closeModalEvent)="closeModal()" *ngIf="isModalOpen" appTrapFocus>
  <form class="modalContent" [formGroup]="impersonateForm" (ngSubmit)="submitForm()">
    <h3>Impersonate another user</h3>
    <label>Enter a username or ID</label>
    <input type="text" #impersonateInput formControlName="input" data-cy="input">
    <button type="submit" [disabled]="impersonateForm.invalid">Submit</button>
  </form>
</app-modal>
