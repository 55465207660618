import {Injectable} from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import {
  endImpersonateUserSuccessAction,
  getUserNameSuccessAction,
  impersonateUserSuccessAction
} from "../auth/auth.actions";
import {select, Store} from "@ngrx/store";
import {exhaustMap, filter, map, switchMap} from "rxjs/operators";
import {catchError, of} from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import {VehicleDecalRepository} from "../../repository/vehicle-decal.repository";
import {
  clearVehicleDecalsAction,
  getVehicleDecalsAction,
  getVehicleDecalsFailureAction,
  getVehicleDecalsSuccessAction
} from "./vehicle-decals.actions";
import {getVehicleDecalsSelector} from "./vehicle-decals.selectors";
import {submitDecalsActionSuccess} from "../../../feature/order-process/store/order-decals.actions";

@Injectable()
export class VehicleDecalsEffect {

  // noinspection TypeScriptValidateTypes
  getVehicleDecals$ = createEffect(() => this.actions$.pipe(
    ofType(getVehicleDecalsAction),
    // if it's already been loaded, don't refetch from the api; that's what these next 2 lines do
    concatLatestFrom(() => this.store.pipe(select(getVehicleDecalsSelector))),
    filter(([,decals]) => !decals),
    exhaustMap(() => {
      return this.vehicleDecalRepo.getVehicleDecals().pipe(
        map(response => getVehicleDecalsSuccessAction({data: response})),
        catchError((errorResponse: HttpErrorResponse) => of(getVehicleDecalsFailureAction()))
      );
    })
  ));

  // noinspection TypeScriptValidateTypes
  clearVehicleDecals$ = createEffect(() => this.actions$.pipe(
    ofType(
      getUserNameSuccessAction,
      impersonateUserSuccessAction,
      endImpersonateUserSuccessAction,
      submitDecalsActionSuccess,
    ),
    switchMap(() => of(clearVehicleDecalsAction()))
  ));

  // noinspection TypeScriptValidateTypes
  refetchVehicleDecals$ = createEffect(() => this.actions$.pipe(
    ofType(clearVehicleDecalsAction),
    switchMap(() => of(getVehicleDecalsAction()))
  ));

  constructor(private actions$: Actions,
              private store: Store,
              private vehicleDecalRepo: VehicleDecalRepository) {
  }
}
