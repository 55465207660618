import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { IVehicle } from "src/app/core/models/vehicle.model";

@Component({
    selector: 'app-order-vehicle-list',
    templateUrl: './order-process-vehicle-list.component.html',
    styleUrls: ['./order-process-vehicle-list.component.scss'],
})
export class OrderVehicleListComponent implements OnInit {
    @Input()
    public vehicleList: IVehicle[] | null;

    @Input()
    public showDelete: boolean;

    @Input()
    public showEdit: boolean;

    @Input()
    public showHeader: boolean;

    @Input()
    public showCheckbox: boolean;

    @Input()
    public isChecked: boolean;

    @Output() handleEditEvent = new EventEmitter<IVehicle>();

    public showEditModal: boolean = false;
    public showDeleteModal: boolean = false;

    public vehicleToEdit: IVehicle;
    public vehicleToDelete: IVehicle;

    //TODO: might need to do something here to update if the lists change
    ngOnInit() {

    }

    public openEdit(vehicle: IVehicle) {
        this.handleEditEvent.emit(vehicle);
    }

    public openDelete(vehicle: IVehicle) {
        this.vehicleToDelete = vehicle;
        this.showDeleteModal = true;
    }
}
