<!-- eslint-disable @angular-eslint/template/no-negated-async -->
<div *ngIf="!(isSubmitting$ | async)">
  <h2 data-cy="title">Emergency Phone Number</h2>
  <div class="phoneIntro">
    Please enter a phone number where LUPD may reach you in case of an emergency.
  </div>

  <form class="phoneInput" [formGroup]="phoneForm">
    <b>Phone: </b>
    <input
      data-cy="input"
      type="tel"
      placeholder="(###) ###-####"
      aria-label="Phone Number"
      class="input-text"
      (input)="formatPhoneNo($event.target)"
      (change)="togglePhone($event)"
      [value]="phoneValue === undefined ? '' : phoneValue"
      formControlName="phone"
      [ngClass]="{'is-invalid': form['phone'].errors}"
      required
    >
    <div *ngIf="form['phone'].errors" class="invalid-feedback">
      <div *ngIf="form['phone'].errors['pattern']">Please enter a valid phone number.</div>
      <div *ngIf="form['phone'].errors['required']">Please enter a phone number.</div>
    </div>
  </form>

  <hr>
  <h2 data-cy="confirm-decal-title">Confirm decal selection</h2>
  <div class="table-holder">
    <div class="blank-cell">Item/Description</div>
    <div class="header-text">Price</div>
  </div>

  <div>
    <table class="vehicle-table table table-responsive table-responsive-sm" >
    <tbody data-cy="vehicle-decal">
    <tr *ngFor="let pair of (vehicleDecalPairs$ | async)" class="decal-data-row" >
      <div class="tableWrapper">
        <div class="line-wrapper">
        <td class="header-decal">
          <app-decal-type-icon data-cy="decal-type-icon" [decalColor]="pair.decalType.decalColor.code" [decalCode]="pair.decalType.code" [decalDescription]="pair.decalType.description"></app-decal-type-icon>
          {{pair.decalType.description}}
        </td>
        <td class="header-item">
          <ng-template [ngIf]="pair.decalType.imageUrl.slice(0,pair.decalType.imageUrl.lastIndexOf('.'))" [ngIfElse]="blankSpace">
            <button (click)="openDecalMap(pair.decalType.imageUrl)" class="link-button">View Map</button>
          </ng-template>
          <ng-template #blankSpace></ng-template>
        </td>
        <td class="header-item">{{pair.decalType.description}} - ${{pair.decalType.price}}</td>
        </div> <!-- closes line wrapper which contains decal info -->
      <tr *ngFor=" let vehicle of pair.vehicleList" class="vehicle-data-row">
        <td class="list-vehicle-data">{{vehicle.make.text}} {{vehicle.model.text}}</td>
        <td class="list-decal-valid">
          <img class="filter-green" id="valid-icon" src="assets/valid icon.svg" alt="Edit">
          <span class="valid-text">
              Valid
            </span>
          </td>
        <td class="list-year-data">{{vehicle.vehicleYear}}</td>
        <td class="list-license-data">{{vehicle.licenseNumber}}</td>
        <td class="list-color-data">{{vehicle.color.text}}</td>
      </tr>
    </div>
    </tbody>
    </table>
  </div>

  <div class="table-holder">
    <div class="blank-cell"></div>
    <div class="header-text">Total - ${{totalDecalcost$ | async}}</div>
  </div>

  <hr>

  <h2>Agreements</h2>
  <div>
    <div class="warning">
      You must agree to the following terms and conditions in order to place your decal order:
    </div>

    <div class="agreement">
      <h3>General Parking Contract</h3>
      <div [innerHTML]="(generalAgreement$ | async)?.documentText"></div>
        <label style="cursor: pointer">
          <input data-cy="agreements-checkbox" type="checkbox" class="input-checkbox" (change)="toggleGeneral($event)" /> <b>I agree to the terms and conditions of the General Parking Contract</b>
        </label>
    </div>

    <br>
    <hr>
    <br>

    <div class="agreement" *ngIf="isEmployee$ | async">
      <h3>Payroll Deduction Enrollment Authorization</h3>
      <div [innerHTML]="(payrollAgreement$ | async)?.documentText"></div>
        <label style="cursor: pointer">
          <input type="checkbox" class="input-checkbox" (change)="togglePayroll($event)" /> <b>I agree to the terms and conditions of the Payroll Deduction Contract</b>
        </label>
      </div>
    </div>

    <br>
    <hr>
  </div>

<div class="nav-button-container" >
  <div>
    <button mat-button matStepperPrevious class="backButton">Back</button>
  </div>
  <div matTooltip="Please Acknowledge Agreements" [matTooltipDisabled]="(isEnabled | async)">
    <button data-cy="place-order-button" mat-button matStepperNext
      class="nextButton" (click)="checkInput()" [disabled]="(isSubmitting$ | async) || !(isEnabled | async)">Place Order</button>
  </div>
</div>

<app-loading-spinner [givenFlag$]="isSubmitting$"></app-loading-spinner>
