import { Injectable } from '@angular/core';
import {IVehicleMake, IVehicleType, VehicleTypes} from "../models/vehicle-make.model";
import {Observable, from} from "rxjs";
import {VehicleMakeRepository} from "../repository/vehicle-make.repository";
import {IVehicleModel} from '../models/vehicle-model.model';
import { switchMap, mergeMap,  toArray, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VehicleMakeService {

  constructor(private vehicleMakeRepo: VehicleMakeRepository) { }

  public getVehicleMakes(): Observable<IVehicleMake[]> {
    return this.vehicleMakeRepo.getMakes();
  }

   public add(make: IVehicleMake): Observable<IVehicleMake> {
    return this.vehicleMakeRepo.add(make);
  }

  public update(make: IVehicleMake): Observable<IVehicleMake> {
    return this.vehicleMakeRepo.update(make);
  }

  public getVehicleTypes(): Observable<IVehicleType[]> {
    return this.vehicleMakeRepo.getVehicleTypes();
  }

  public getModels(makeId: number | null): Observable<IVehicleModel[]> {
    return this.vehicleMakeRepo.getModels(makeId);
  }

  public getAllModelsForAllMakes(): Observable<IVehicleModel[]> {
    return this.getVehicleMakes().pipe(
      switchMap((makes: IVehicleMake[]) => {
        return from(makes).pipe(
          mergeMap((make: IVehicleMake) => {
            return this.getModels(make.id)
          }),
          toArray(),
           map((r: any) => {
            return r.flat(1)
          })
        )
      })
    )
  }

  public addModel(model: IVehicleModel): Observable<IVehicleModel> {
    return  this.vehicleMakeRepo.addModel(model);
  }

  public updateModel(model: IVehicleModel): Observable<IVehicleModel> {
    return this.vehicleMakeRepo.updateModel(model);
  }

  public addOtherModel(vehicleMake: IVehicleMake): IVehicleModel | null {
    switch(vehicleMake.vehicleType.id) {
      case VehicleTypes.MOTORCYCLE: {
        var tempModel: IVehicleModel = {
          id: 2655,
          text: "Other",
          make: vehicleMake
        }
        return (tempModel);
      }
      case VehicleTypes.CAR: {
        var tempModel: IVehicleModel = {
          id: 1,
          text: "Other",
          make: vehicleMake
        }
        return (tempModel);
      }
      case VehicleTypes.BIKE: {
        var tempModel: IVehicleModel = {
          id: 2654,
          text: "Other",
          make: vehicleMake
        }
        return (tempModel);
      }
      default: {
        return null;
      }
    }
  }

}
