<div class="modalBackground">
    <div class="modal">
        <div class="rowHolder">
           <div class="labelData">Add Vehicle</div>
        </div>
        <hr>

        <div class="rowHolder">
            <label class="rowLabel" for="selectType">Type</label>
            <div>
                <button class="menuButton" id="selectType" mat-button [matMenuTriggerFor]="types" [disabled]="editingVehicle">{{selectedTypeText}}</button>
            </div>
        </div>

        <div class="rowHolder">
            <label class="rowLabel" for="selectMake">Make</label>
            <span>
                <button class="menuButton" id="selectMake" mat-button [matMenuTriggerFor]="makes" [disabled]="editingVehicle">{{selectedMakeText}}</button>
            </span>
        </div>

        <div class="rowHolder">
            <label class="rowLabel" for="selectModel">Model</label>
            <span>
                <button class="menuButton" id="selectModel" mat-button [matMenuTriggerFor]="models" [disabled]="editingVehicle">{{selectedModelText}}</button>
            </span>
        </div>

        <div class="rowHolder">
          <div class="rowLabel">Other Model</div>
          <span class="rowLabel">
              <input type="text" [(ngModel)]="selectedOtherModel">
          </span>
        </div>

        <div class="rowHolder">
            <div class="rowLabel">Year</div>
            <span class="rowLabel">
                <input type="text" [(ngModel)]="selectedYear">
            </span>
        </div>

        <div class="rowHolder">
            <label class="rowLabel" for="selectColor">Color</label>
            <span>
                <button class="menuButton" id="selectColor" mat-button [matMenuTriggerFor]="colors">{{selectedColorText}}</button>
            </span>
        </div>

        <div class="rowHolder">
            <label class="rowLabel" for="selectState">License State</label>
            <span>
                <button class="menuButton" id="selectState" mat-button [matMenuTriggerFor]="states">{{selectedStateText}}</button>
            </span>
        </div>

        <div class="rowHolder">
            <div class="rowLabel">License Plate Number</div>
            <span class="rowLabel">
                <input type="text" [(ngModel)]="selectedLicense">
            </span>
        </div>

        <div class="rowHolder">
            <span></span>
            <span>
                <label for="insured" style="pointer:cursor"><h2>This vehicle has insurance</h2>
                  <input type="checkbox" id="insured" (change)="insuredChange($event)">
                </label>
            </span>
        </div>

        <div class="rowHolder">
            <label class="rowLabel" for="selectIsElectric">Electric Vehicle</label>
            <span>
                <button class="menuButton" id="selectIsElectric" mat-button [matMenuTriggerFor]="electric">{{selectedElectricText}}</button>
            </span>
        </div>

        <div class="rowHolder">
            <button (click)="closeModal()">Cancel</button>
            <button (click)="addVehicle()">Save</button>
        </div>
    </div>
</div>

<mat-menu #types="matMenu">
  <div *ngFor="let type of typeList">
    <button mat-menu-item (click)="setType(type)">{{type.text}}</button>
  </div>
</mat-menu>

<mat-menu #makes="matMenu">
    <div *ngFor="let make of makeList">
        <button mat-menu-item (click)="setMake(make)">{{make.text}}</button>
    </div>
</mat-menu>

<mat-menu #models="matMenu">
    <div *ngFor="let model of modelList">
        <button mat-menu-item (click)="setModel(model)">{{model.text}}</button>
    </div>
</mat-menu>

<mat-menu #colors="matMenu">
    <div *ngFor="let color of colorList">
        <button mat-menu-item (click)="setColor(color)">{{color.text}}</button>
    </div>
</mat-menu>

<mat-menu #states="matMenu">
    <div *ngFor="let state of stateList">
        <button mat-menu-item (click)="setState(state)">{{state.name}}</button>
    </div>
</mat-menu>

<mat-menu #electric="matMenu">
    <button mat-menu-item (click)="setElectric('Yes')">Yes</button>
    <button mat-menu-item (click)="setElectric('No')">No</button>
</mat-menu>
