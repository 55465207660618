function isDev(origin: any) {
  return (
      origin.indexOf('dev.liberty.edu') >= 0 ||
      origin.indexOf('localhost') >= 0 ||
      origin.indexOf('university.liberty.edu') >= 0
  );
}

function isTest(origin: any) {
  return origin.indexOf('test.liberty.edu') >= 0;
}

//OKD Route checks

function isDevOS4(origin: any) {
  return origin.indexOf('-dev.apps.lyn-cre01.liberty.edu') >= 0;
}

function isDevCiOS4(origin: any) {
  return origin.indexOf('-dev-ci.apps.lyn-cre01.liberty.edu') >= 0;
}

function isTestOS4(origin: any) {
  return (origin.indexOf('-test.apps.lyn-cre01.liberty.edu') >= 0);
}

function isPprdOS4(origin: any) {
  return origin.indexOf('-pprd.apps.lyn-cre01.liberty.edu') >= 0;
}

function isUpgdOS4(origin: any) {
  return origin.indexOf('-upgd.apps.lyn-cre01.liberty.edu') >= 0;
}

function isProdOS4(origin: any) {
  return origin.indexOf('parking.liberty.edu') >= 0 || origin.indexOf('www.liberty.edu') >= 0;
}

function getOrigin() {
  if (!('origin' in window.location) || !window.location.origin) {
    return window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '');
  }

  return window.location.origin;
}

function getEnvironmentApiUrl() {
  let origin = getOrigin();
  let apiUrl = 'https://www.liberty.edu/parking-api';

  if (isDev(origin)) {
      apiUrl = apiUrl.replace('www.', 'dev.');
  } else if (isTest(origin)) {
      apiUrl = apiUrl.replace('www.', 'test.');
  } else if (isDevOS4(origin)) {
      apiUrl = apiUrl.replace('www.', 'parking-rest-dev.apps.lyn-cre01.');
  } else if (isDevCiOS4(origin)) {
      apiUrl = apiUrl.replace('www.', 'parking-rest-dev-ci.apps.lyn-cre01.');
  } else if (isTestOS4(origin)) {
      apiUrl = apiUrl.replace('www.', 'parking-rest-test.apps.lyn-cre01.');
  } else if (isPprdOS4(origin)) {
      apiUrl = apiUrl.replace('www.', 'parking-rest-pprd.apps.lyn-cre01.');
  } else if (isUpgdOS4(origin)) {
      apiUrl = apiUrl.replace('www.', 'parking-rest-upgd.apps.lyn-cre01.');
  } else if (isProdOS4(origin)) {
      apiUrl = apiUrl.replace('www.', 'parking-rest.apps.lyn-cre01.');
  }
  return apiUrl;
}

export const environment = {
  production: true,
  appName: 'lu-serve',
  configPath: 'config.json',
  apiUrl: getEnvironmentApiUrl(),
  cookieDomain: '.liberty.edu',
  origin: getOrigin(),
  errorUrl: 'https://sorry.liberty.edu/badrequest/',
  unauthenticatedUrl: function() {
    return `${environment.apiUrl}/login/auth-and-redirect?url=${encodeURIComponent(window.location.href)}`;
  }
};
