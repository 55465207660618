<h1>Payroll Deduction Enrollment Authorization</h1>
<app-text-block [code]=code></app-text-block>
<hr>
<br>
<div *ngIf="formattedDate; then hasSignature else noSignature"></div>
<ng-template #hasSignature>
  <em>This document was last signed on {{formattedDate}}</em>
</ng-template>
<ng-template #noSignature>
  <em>No authorization on file</em>
</ng-template>

<label style="cursor: pointer">
  <input [(ngModel)]="isAccepted" type="checkbox" class="input-checkbox" name="isAccepted" />
  I agree to the terms and conditions of the {{payrollAgreement?.signatureCodeBean?.description}}
</label>
<div class="OKButton-container">
  <button *ngIf="isAccepted" (click)="postAgreement()"> Submit</button>
</div>

<ng-template #dialogTemplate>
  <h2 mat-dialog-title>{{dialogTitle}}</h2>
  <div mat-dialog-content>
    {{dialogMessage}}
  </div>
  <div class="OKButton" mat-dialog-actions align="end">
    <button class="actualButton" style="background-color: #246EC0 !important; color: white;" mat-raised-button (click)="closeDialog()">OK</button>
  </div>
</ng-template>
