import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IDecalColor } from '../models/decal-color.model';
import { environment } from '../../../environments/environment';

@Injectable ({
  providedIn: 'root'
})

export class DecalColorRepository {
  constructor(private http: HttpClient) {}

  public getDecalColors(): Observable<IDecalColor[]> {
    return this.http.get<IDecalColor[]>(`${environment.apiUrl}/decal-colors`);
  }

  public addDecalColor(decalColor: IDecalColor | Partial<IDecalColor>): Observable<IDecalColor> {
    return this.http.post<IDecalColor>(`${environment.apiUrl}/decal-colors`, decalColor);
  }

  public updateDecalColor(decalColor: IDecalColor): Observable<IDecalColor> {
    return this.http.put<IDecalColor>(`${environment.apiUrl}/decal-colors/${decalColor.id}`, decalColor);
  }
}
