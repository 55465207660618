import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of, catchError } from 'rxjs';
import { IDecalType } from '../models/decal-type.model';
import { IDecalRestriction } from '../models/decal-restriction.model';
import { environment } from '../../../environments/environment';
import { HttpError } from '../service/http-error/http-error.decorator';

@Injectable({
  providedIn: 'root'
})

export class DecalTypeRepository {
  constructor(private http: HttpClient) {}

  public getDecalTypes(year: number): Observable<IDecalType[]> {
    return this.http.get<IDecalType[]>(`${environment.apiUrl}/decal-types?year=${year}`);
  }

  public getDecalType(decalId: number): Observable<IDecalType> {
    return this.http.get<IDecalType>(`${environment.apiUrl}/decal-types/${decalId}`);
  }

  public getDecalRestriction(decalId: number): Observable<IDecalRestriction[]> {
    return this.http.get<IDecalRestriction[]>(`${environment.apiUrl}/decal-types/${decalId}/restrictions`);
  }

  public addDecalType(decalType: IDecalType): Observable<IDecalType> {
    return this.http.post<IDecalType>(`${environment.apiUrl}/decal-types`, decalType).pipe(
      catchError(err => {return of(err)})
    );
  }

  public updateDecalType(decalType: IDecalType): Observable<IDecalType> {
    return this.http.put<IDecalType>(`${environment.apiUrl}/decal-types/${decalType.id}`, decalType).pipe(
      catchError(err => {return of(err)})
    );
  }
}
