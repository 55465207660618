import { Component, OnInit, Injectable } from '@angular/core';
import { IDecalType } from '../../../core/models/decal-type.model';
import { IDecalColor } from '../../../core/models/decal-color.model';
import { IDecalRestriction } from '../../../core/models/decal-restriction.model';
import { DecalColorService } from '../../../core/service/decal-color.service';
import { DecalTypeService } from '../../../core/service/decal-type.service';
import { DecalRestrictionService } from '../../../core/service/decal-restriction.service';
import { UntypedFormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-decal-type',
  templateUrl: './decal-type.component.html',
  styleUrls: ['./decal-type.component.scss']
})

export class DecalTypeComponent implements OnInit {
  public decalTypes: IDecalType[] = [];
  public decalColors: IDecalColor[] = [];
  public decalRestrictionTypes: IDecalRestriction[] = [];
  public stagedDecalType: IDecalType | undefined;
  public isModalOpen: boolean = false;
  public filterInactiveCheckBox: boolean = false;

  public modalTitle: string = 'Enter Decal Type';
  public formSubmitted: boolean = false;
  public hasError: boolean = false;
  public date = new Date();
  public month = ('0' + (this.date.getUTCMonth() + 1)).slice(-2);
  public day = ('0' + (this.date.getUTCDate())).slice(-2);
  public currentYear = this.date.getUTCFullYear();
  public CurrentDate = this.currentYear + "-" + this.month + "-" + this.day;

  public decalTypeBillingFreqs = ["A","T","3"];
  public yearSelectOptions: Array<{range: string, year: number}> = [];
  public yearForm = new FormGroup({
    year: new FormControl(this.currentYear)
  });

  public decalTypeForm = this.formBuilder.group({
    decalTypeCode: ['', Validators.required],
    decalTypeDescription: ['', Validators.required],
    decalTypeFirstNumber: [0, Validators.required],
    decalTypeStudents: [false, Validators.required],
    decalTypeStaff: [false, Validators.required],
    decalTypeFaculty: [false, Validators.required],
    decalTypeImageURL: ['', Validators.required],
    decalStartDate: [this.CurrentDate, Validators.required],
    decalEndDate: [this.CurrentDate, Validators.required],
    decalTypeActive: [false, Validators.required],
    decalColor: ['', Validators.required],
    decalTypeBillingFreq: [this.decalTypeBillingFreqs[0], Validators.required],
    decalTypePrice: [0, Validators.required]
  });

  constructor(private decalColorService: DecalColorService, private decalTypeService: DecalTypeService, private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.getDecalColors();
    this.initYearForm();
    this.setCurrentYear();
    this.initYearSelectOptions();
    this.initRestrictions();
  }

  public get form() {
    return this.decalTypeForm.controls;
  }

  public get decalTypeCode(): string {
    return this.decalTypeForm.get('decalTypeCode')?.value;
  }

  public get decalTypeDescription(): string {
    return this.decalTypeForm.get('decalTypeDescription')?.value;
  }

  public get decalTypeFirstNumber(): number {
    return this.decalTypeForm.get('decalTypeFirstNumber')?.value;
  }

  public get decalTypeStudents(): boolean {
    return this.decalTypeForm.get('decalTypeStudents')?.value;
  }

  public get decalTypeStaff(): boolean {
    return this.decalTypeForm.get('decalTypeStaff')?.value;
  }

  public get decalTypeFaculty(): boolean {
    return this.decalTypeForm.get('decalTypeFaculty')?.value;
  }

  public get decalTypeActive(): boolean {
    return this.decalTypeForm.get('decalTypeActive')?.value;
  }

  public get decalStartDate(): string {
    return this.decalTypeForm.get('decalStartDate')?.value;
  }

  public get decalEndDate(): string {
    return this.decalTypeForm.get('decalEndDate')?.value;
  }

  public get decalColor(): IDecalColor {
    return this.decalTypeForm.get('decalColor')?.value;
  }

  public get decalTypeImageURL(): string {
    return this.decalTypeForm.get('decalTypeImageURL')?.value;
  }

  public get decalTypePrice(): number {
    return this.decalTypeForm.get('decalTypePrice')?.value;
  }

  public get decalTypeBillingFreq(): string {
    return this.decalTypeForm.get('decalTypeBillingFreq')?.value;
  }

  public changeDecalColor(dcolor: IDecalColor) {
    this.decalTypeForm.get('decalTypeForm')?.setValue(dcolor);
  }

  public changeDecalTypeBillingFreq(dtbilling: string) {
    this.decalTypeForm.get('decalTypeForm')?.setValue(dtbilling);
  }

  public setCurrentYear(): void {
    if (this.isBeforeJuly()) {
      this.yearForm.get('year')?.setValue(this.currentYear - 1);
    } else {
      this.yearForm.get('year')?.setValue(this.currentYear);
    }
  }

  public isBeforeJuly(): boolean {
    return this.date.getUTCMonth() < 6;
  }

  public getDecalRestriction(decalType: IDecalType): void {
    this.decalTypeService.getDecalRestriction(decalType.id).subscribe((decalRestrictionTypes: IDecalRestriction[]) => {
      decalType.restrictions = decalRestrictionTypes;
    });
  }

  public getDecalColors(): void {
    this.decalColorService.getDecalColors().subscribe((colors: IDecalColor[]) => {
      this.decalColors = colors;
    });
  }

  public getDecalTypes(year: number): void {
    this.decalTypeService.getDecalTypes(year).subscribe((decalTypes: IDecalType[]) => {
      this.decalTypes = decalTypes;
      this.decalTypeForm.patchValue({
         decalTypeCode: '',
         decalTypeDescription: '',
         decalTypeFirstNumber: 0,
         decalTypeStudents: false,
         decalTypeStaff: false,
         decalTypeFaculty: false,
         decalTypeActive: false,
         decalStartDate: this.CurrentDate,
         decalEndDate:this.CurrentDate,
         decalColor: this.decalColors[0],
         decalTypeImageURL: '',
         decalTypePrice: Number(0),
         decalTypeBillingFreq: this.decalTypeBillingFreqs[0]
      })
      this.initRestrictions();
    });
  }

  public submitForm(): void {
    if (this.stagedDecalType) {
      this.updateDecalType();
    } else {
      this.addDecalType();
    }
  }

  public updateDecalType(): void{
    this.decalTypeService
    .updateDecalType({
      ...this.stagedDecalType,
      code: this.decalTypeCode,
      description: this.decalTypeDescription,
      students: this.decalTypeStudents,
      staff: this.decalTypeStaff,
      faculty: this.decalTypeFaculty,
      imageUrl: this.decalTypeImageURL,
      startDate: this.decalStartDate,
      endDate: this.decalEndDate,
      active: this.decalTypeActive,
      decalColor: this.decalColor,
      firstNumber: this.decalTypeFirstNumber,
      billingFrequency: this.decalTypeBillingFreq,
      price: this.decalTypePrice
    } as IDecalType)
    .subscribe((updatedDecalType: IDecalType) => {
       this.decalTypes = this.decalTypes.map((decalType: IDecalType) => decalType.id === updatedDecalType.id ? updatedDecalType : decalType
       );
       this.closeModal();
    });
  }

  public addDecalType(): void {
     let newDecalType: IDecalType = {
      id: 0,
      code: this.decalTypeCode,
      description: this.decalTypeDescription,
      students: this.decalTypeStudents,
      staff: this.decalTypeStaff,
      faculty: this.decalTypeFaculty,
      imageUrl: this.decalTypeImageURL,
      startDate: this.decalStartDate,
      endDate: this.decalEndDate,
      active: this.decalTypeActive,
      decalColor: this.decalColor,
      firstNumber: this.decalTypeFirstNumber,
      billingFrequency: this.decalTypeBillingFreq,
      price: this.decalTypePrice
    }

    this.formSubmitted = true;

    this.decalTypeService.addDecalType(newDecalType).subscribe({next: (decalType: IDecalType) => {
      this.decalTypes.push(decalType);

      if (this.decalTypeForm.invalid) {
        return;
      }

      this.closeModal();
      }
    });
  }

  public openModal(decalType: IDecalType | undefined) {
    this.isModalOpen = true;
    this.modalTitle = 'Enter Decal Type';
    if (decalType !== undefined) {
    this.modalTitle = 'Edit Decal Type';
      this.stagedDecalType = decalType;
      let indexOfDecalColor = this.decalColors.findIndex(dcolor => dcolor.id === decalType.decalColor.id);
      this.decalTypeForm.patchValue({
        decalTypeCode: decalType.code,
        decalTypeDescription: decalType.description,
        decalTypeFirstNumber: decalType.firstNumber,
        decalTypeStudents: decalType.students,
        decalTypeStaff: decalType.staff,
        decalTypeFaculty: decalType.faculty,
        decalTypeImageURL: decalType.imageUrl,
        decalStartDate: decalType.startDate,
        decalEndDate: decalType.endDate,
        decalTypeActive: decalType.active,
        decalColor: this.decalColors[indexOfDecalColor],
        decalTypeBillingFreq: decalType.billingFrequency,
        decalTypePrice: decalType.price
      });
    }
  }

  public closeModal() {
    this.hasError = false;
    this.stagedDecalType = undefined;
    this.isModalOpen = false;
    this.resetDecalTypeForm();
  }

   public toggleFilterInactive() {
      this.filterInactiveCheckBox = !this.filterInactiveCheckBox;
  }

  public filteredDecalTypes(): Array<IDecalType> {
    return this.decalTypes.filter((decalType: IDecalType) => {
      if (decalType.active) return true;
      return !decalType.active && this.filterInactiveCheckBox;
    });
  }

  public resetDecalTypeForm() {
    this.decalTypeForm.get('decalTypeCode')?.setValue('');
    this.decalTypeForm.get('decalTypeDescription')?.setValue('');
    this.decalTypeForm.get('decalTypeFirstNumber')?.setValue(0);
    this.decalTypeForm.get('decalTypeStudents')?.setValue(false);
    this.decalTypeForm.get('decalTypeStaff')?.setValue(false);
    this.decalTypeForm.get('decalTypeFaculty')?.setValue(false);
    this.decalTypeForm.get('decalTypeActive')?.setValue(false);
    this.decalTypeForm.get('decalStartDate')?.setValue(this.CurrentDate);
    this.decalTypeForm.get('decalEndDate')?.setValue(this.CurrentDate);
    this.decalTypeForm.get('decalTypePrice')?.setValue(0);
    this.decalTypeForm.get('decalTypeBillingFreq')?.setValue(this.decalTypeBillingFreqs[0]);
    this.decalTypeForm.get('decalColor')?.setValue('');
    this.decalTypeForm.get('decalTypeImageURL')?.setValue('');
  }

  public initYearSelectOptions(): void {
    for(let year = this.currentYear; year >= this.currentYear - 4; year--) {
      this.yearSelectOptions.push({
        range: year + " - " + (year + 1),
        year: year
      });
    }
  }

  public initYearForm(): void {
    this.yearForm.get('year')?.valueChanges.subscribe((year: number | null) => {
      if (year !== null) {
       this.getDecalTypes(year);
      }
    });
  }

  public initRestrictions(): void {
    this.decalTypes.map((decalType: IDecalType) => {
      this.getDecalRestriction(decalType);
    });
  }
}
