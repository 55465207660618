import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';
import { ISelectedVehicleDecalPair } from 'src/app/core/models/selected-vehicle-decal-pair.model';
import { UserService } from 'src/app/core/service/user/user.service';
import { areAllStepsCompleteSelector, getDecalSubmissionResultsSelector, getFailedDecalRequestsSelector, getTotalSucessFulDecalCostSelector } from '../store/order-decals.selectors';
import { resetOrderProcessAction } from '../store/order-decals.actions';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit {

  sucessfulDecals$: Observable<ISelectedVehicleDecalPair[]>;
  failedDecals$: Observable<ISelectedVehicleDecalPair[]>;
  successfulDecalCost$: Observable<number>;
  sucessfulDecals: ISelectedVehicleDecalPair[];
  failedDecals: ISelectedVehicleDecalPair[];
  successfulDecalCost: number;
  user$: Observable<string | undefined>;
  luid: string;

  lupdEmail: string = "mailto:luparking@liberty.edu";

  constructor(private store: Store,
    private router: Router,
    private userService: UserService) { }

  ngOnInit(): void {
    this.sucessfulDecals$ = this.store.pipe(select(getDecalSubmissionResultsSelector));
    this.failedDecals$ = this.store.pipe(select(getFailedDecalRequestsSelector));
    this.successfulDecalCost$ = this.store.pipe(select(getTotalSucessFulDecalCostSelector));
    this.user$ = this.userService.getCurrentUser();
    this.userService.getLuid().pipe(take(1)).subscribe(res => {
      this.luid = res
    });

    this.store.pipe(
      select(areAllStepsCompleteSelector)
    ).subscribe(allComplete => {
      if (allComplete) {
        this.successfulDecalCost$.pipe(take(1)).subscribe(cost => {
          this.successfulDecalCost = cost
        })
        this.sucessfulDecals$.pipe(take(1)).subscribe(succeeded => {
          this.sucessfulDecals = succeeded
        })
        this.failedDecals$.pipe(take(1)).subscribe(failed => {
          this.failedDecals = failed
        })
        setTimeout(() => this.store.dispatch(resetOrderProcessAction()), 1000)
      }
    })
  }

  openDecalMap(url: string) {
    window.open(url, "_blank")
  }

  redirectToDecals() {
    this.router.navigate(['decals']);
  }

  printReciept() {
    const printArea = document.getElementById('reciept');
    const printWindw = window.open('', 'PRINT');
    printWindw?.document.write(`<html><head><title>Parking Decal - Order Decal - Reciept</title></head><body>${printArea?.innerHTML}</body>`);
    printWindw?.document.close();
    printWindw?.focus();
    printWindw?.print();
  }

}
