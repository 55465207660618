import {createAction, props} from "@ngrx/store";
import {IVehicleDecal} from "../../models/vehicle-decal.model";

export const getVehicleDecalsAction = createAction(
  '[Vehicle Decals] Get Vehicle Decals'
);

export const getVehicleDecalsSuccessAction = createAction(
  '[Vehicle Decals] Get Vehicle Decals Success',
  props<{data: IVehicleDecal[]}>()
);

export const getVehicleDecalsFailureAction = createAction(
  '[Vehicle Decals] Get Vehicle Decals Failure'
);

export const clearVehicleDecalsAction = createAction(
  '[Vehicle Decals] Clear Vehicle Decals'
);
