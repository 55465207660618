import { Injectable } from '@angular/core';
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import {
  canImpersonateSelector,
  getUserNameSelector,
  getUserRolesSelector,
  isAdminSelector,
  isEmployeeSelector, isImpersonatingSelector,
  isLupdStaffSelector
} from "../../store/auth/auth.selectors";
import { environment } from "../../../../environments/environment";
import { endImpersonateUserAction, impersonateUserAction } from "../../store/auth/auth.actions";
import { UserRepository } from "../../repository/user.repository";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private luidRegex = new RegExp(/^L\d{8}$/);
  private integersRegex = new RegExp(/^\d+$/);

  constructor(private store: Store,
    private userRepo: UserRepository) {
  }

  public getCurrentUser(): Observable<string | undefined> {
    return this.store.pipe(select(getUserNameSelector));
  }

  public getUserRoles(): Observable<string[] | undefined> {
    return this.store.pipe(select(getUserRolesSelector));
  }

  public isAdmin(): Observable<boolean> {
    return this.store.pipe(select(isAdminSelector));
  }

  public isLupdStaff(): Observable<boolean> {
    return this.store.pipe(select(isLupdStaffSelector));
  }

  public isEmployee(): Observable<boolean> {
    return this.store.pipe(select(isEmployeeSelector));
  }

  public canImpersonate(): Observable<boolean> {
    return this.store.pipe(select(canImpersonateSelector));
  }

  public isImpersonating(): Observable<boolean> {
    return this.store.pipe(select(isImpersonatingSelector));
  }

  /**
   * Directs user to CAS logout page after sign out.
   */
  public signOut(): void {
    window.location.replace(`${environment.apiUrl}/logout`);
  }

  private padLuid(digits: string): string {
    let chars = digits.length;

    if (chars > 8) throw new Error('Too many digits. The input is definitely not an username or LUID.');

    let response = digits;

    for (chars; chars < 8; chars++) {
      response = '0' + response;
    }
    return 'L' + response;
  }

  public impersonate(userOrId: string): void {
    if (this.integersRegex.test(userOrId)) {
      try {
        userOrId = this.padLuid(userOrId);
      } catch (e) {
        throw e;
      }
    }

    if (this.luidRegex.test(userOrId)) {
      //if an id, fetch a username then impersonate
      this.userRepo.getUsername(userOrId)
        .subscribe(name => this.store.dispatch(impersonateUserAction({ username: name })));
    } else {
      //if already a username, impersonate now
      this.store.dispatch(impersonateUserAction({ username: userOrId }));
    }
  }

  public stopImpersonating(): void {
    this.store.dispatch(endImpersonateUserAction());
  }

  public getLuid(): Observable<string> {
    return this.userRepo.getLuid();
  }
}
