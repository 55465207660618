<button routerLink="../../">Back</button>

<div class="restrictionHeaderContainer">
  <h3 class="decalRestrictionColumn">Restriction Type</h3>
  <h3 class="decalRestrictionEditColumn">Edit</h3>
  <h3 class="decalRestrictionDeleteColumn">Delete</h3>
</div>

<ul id="restrictionType-table">
  <li *ngFor="let decalRestrictionType of decalRestrictionTypes" data-cy="restriction">
    <div class="decalRestrictionColumn" data-cy="restriction-desc">{{decalRestrictionType.restrictionType.description}}</div>
    <a class="decalRestrictionEditColumn" data-cy="restriction-edit" id="right" (click)="openModal(decalRestrictionType, modalTitle='Edit Decal Restriction')">
      <img src="assets/edit.svg" alt="Edit" id="edit-icon">
      <span id="edit-text">Edit</span>
    </a>
    <a class="decalRestrictionDeleteColumn" data-cy="restriction-delete" (click)="deleteRestriction(decalRestrictionType)">
      <span id="delete-text">Delete</span>
    </a>
  </li>
</ul>

<button *ngIf="decalRestrictionTypes" data-cy="restriction-add" (click)="openModal(undefined, modalTitle='Add Decal Restriction')">Add Restriction</button>

<app-modal (closeModalEvent)="closeModal()" *ngIf="isModalOpen" appTrapFocus>
  <form class="modalContent" [formGroup]="restrictionTypeForm" (ngSubmit)="submitForm()">
    <h3 [innerHtml]="modalTitle"></h3>
    <label>Restriction Type</label>
    <select data-cy="input-type" formControlName="restrictionType" (change)="changeRestrictionType(restrictionType)">
      <option *ngFor="let restrictionType of restrictionTypes" [ngValue]="restrictionType">{{restrictionType.description}}</option>
    </select>
    <button type="submit" data-cy="restriction-submit">Submit</button>
  </form>
</app-modal>
