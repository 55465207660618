import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { IVehicle } from "../../core/models/vehicle.model";
import { IVehicleModel } from '../../core/models/vehicle-model.model';
import { IStateCode } from '../../core/models/statecode.model';
import { IVehicleMake, IVehicleType } from '../../core/models/vehicle-make.model';
import { IVehicleColor } from "../../core/models/vehicle-color.model";
import { VehicleService } from "../../core/service/vehicle.service";
import { VehicleMakeService } from "../../core/service/vehicle-make.service";
import { VehicleColorService } from "../../core/service/vehicle-color.service";

@Component({
  templateUrl: 'vehicles.component.html',
  styleUrls: ['vehicles.component.scss']
})
export class VehiclesComponent implements OnInit {
  public archivedVehicleList: IVehicle[] = [];
  public userVehicleList: IVehicle[] = [];
  public vehicleTypes: IVehicleType[] = [];
  public vehicleMakes: IVehicleMake[] = [];
  public vehicleModels: IVehicleModel[] = [];
  public vehicleColors: IVehicleColor[] = [];
  public stateCodes: IStateCode[] = [];
  public filteredMakes: any[] = [];
  public stagedVehicle: IVehicle | undefined;
  public isModalOpen: boolean = false;
  public formSubmitted: boolean = false;
  public showOtherModel: boolean = false;
  public modalTitle: string = 'Add Vehicle';
  public selectedVehicleOption: string = '';
  public electricVehicles = ['N', 'Y'];
  public vehicleAddForm = this.formBuilder.group({
    vehicleType: [{ value: this.selectedVehicleOption, disabled: false }, Validators.required],
    vehicleMake: [{ value: this.selectedVehicleOption, disabled: false }, Validators.required],
    vehicleModel: [{ value: this.selectedVehicleOption, disabled: false }, Validators.required],
    otherModel: [this.selectedVehicleOption],
    vehicleColor: [this.selectedVehicleOption, Validators.required],
    yearOfVehicle: [{ value: this.selectedVehicleOption, disabled: false }, Validators.required],
    licenseNumber: [this.selectedVehicleOption, Validators.required],
    vehicleStateCode: [this.selectedVehicleOption, Validators.required],
    vehicleInsured: false,
    electricVehicle: this.selectedVehicleOption,
    active: '',
    printModel: ''
  });

  constructor(private vehicleColorService: VehicleColorService, private vehicleMakeService: VehicleMakeService, private vehicleService: VehicleService, private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.getVehicles();
    this.getVehicleTypes();
    this.getVehicleMakes();
    this.getVehicleColors();
    this.getStateCodes();
    this.showOtherModel = false;
  }

  public get form() {
    return this.vehicleAddForm.controls;
  }

  public get vehicleType(): IVehicleType {
    return this.vehicleAddForm.get('vehicleType')?.value;
  }

  public get vehicleMake(): IVehicleMake {
    return this.vehicleAddForm.get('vehicleMake')?.value;
  }

  public get vehicleModel(): IVehicleModel {
    return this.vehicleAddForm.get('vehicleModel')?.value;
  }

  public get otherModel(): string {
    return this.vehicleAddForm.get('otherModel')?.value;
  }

  public get vehicleColor(): IVehicleColor {
    return this.vehicleAddForm.get('vehicleColor')?.value;
  }

  public get yearOfVehicle(): number {
    return this.vehicleAddForm.get('yearOfVehicle')?.value;
  }

  public get licenseNumber(): string {
    return this.vehicleAddForm.get('licenseNumber')?.value;
  }

  public get vehicleStateCode(): string {
    return this.vehicleAddForm.get('vehicleStateCode')?.value;
  }

  public get vehicleInsured(): boolean {
    return this.vehicleAddForm.get('vehicleInsured')?.value;
  }

  public set vehicleInsured(value: boolean) {
    this.vehicleAddForm.get('vehicleInsured')?.setValue(value);
  }

  public get electricVehicle(): string {
    return this.vehicleAddForm.get('electricVehicle')?.value;
  }

  public changeVehicleType(vtype: IVehicleType) {
    this.vehicleAddForm.get('vehicleType')?.enable();
    this.vehicleAddForm.get('vehicleAddForm')?.setValue(vtype);
    this.showOtherModel = false;

    this.handleMakeOptionChange();
  }

  public changeVehicleMake(vmake: IVehicleMake) {
    this.vehicleAddForm.get('vehicleMake')?.enable();
    this.vehicleAddForm.get('vehicleAddForm')?.setValue(vmake);
    this.vehicleMakeService.getModels(vmake.id).subscribe((models) => {
      this.vehicleModels = models;
      if (!vmake.text?.includes("Other") && !vmake.text?.includes("other")) {
        if (this.vehicleMakeService.addOtherModel(vmake) != null) {
          this.vehicleModels.push(this.vehicleMakeService.addOtherModel(vmake)!);
        }
      }
    });
  }

  public changeVehicleModel(vmodel: IVehicleModel) {
    this.vehicleAddForm.get('vehicleModel')?.enable();
    this.vehicleAddForm.get('vehicleAddForm')?.setValue(vmodel);
    this.showOtherModel = (vmodel.text?.includes("Other") || vmodel.text?.includes("other")) ? true : false;
  }

  public changeVehicleColor(vcolor: IVehicleColor) {
    this.vehicleAddForm.get('vehicleAddForm')?.setValue(vcolor);
  }

  public changeStateCode(scode: string) {
    this.vehicleAddForm.get('vehicleAddForm')?.setValue(scode);
  }

  public changeElectricVehicle(evehicle: string) {
    this.vehicleAddForm.get('vehicleAddForm')?.setValue(evehicle);
  }

  public getVehicleTypes(): void {
    this.vehicleMakeService.getVehicleTypes().subscribe((type: IVehicleType[]) => {
      this.vehicleTypes = type;
    });
  }

  public getVehicleMakes(): void {
    this.vehicleMakeService.getVehicleMakes().subscribe((make: IVehicleMake[]) => {
      this.vehicleMakes = make;
    });
  }

  public getAllModelsForAllMakes(): void {
    this.vehicleMakeService.getAllModelsForAllMakes().subscribe((model: IVehicleModel[]) => {
      this.vehicleModels = model;
    });
  }

  public getVehicleColors(): void {
    this.vehicleColorService.getVehicleColors().subscribe((color: IVehicleColor[]) => {
      this.vehicleColors = color;
    });
  }

  public getStateCodes(): void {
    this.vehicleService.getStateCodes().subscribe((code: IStateCode[]) => {
      this.stateCodes = code;
    });
  }

  public getVehicles(): void {
    this.vehicleService.getVehicles().subscribe((userVehicleList: IVehicle[]) => {
      this.userVehicleList = userVehicleList;
      this.userVehicleList = userVehicleList.filter(vehicle => vehicle.active == 'Y');
      this.archivedVehicleList = userVehicleList.filter(vehicle => vehicle.active == 'N');
      this.vehicleAddForm.setValue({
        vehicleType: this.selectedVehicleOption,
        vehicleMake: this.selectedVehicleOption,
        vehicleModel: this.selectedVehicleOption,
        otherModel: '',
        vehicleColor: this.selectedVehicleOption,
        yearOfVehicle: '',
        licenseNumber: '',
        vehicleStateCode: this.selectedVehicleOption,
        vehicleInsured: false,
        electricVehicle: this.selectedVehicleOption,
        active: '',
        printModel: ''
      })
    });
  }

  public deleteVehicle(vehicle: IVehicle) {
    this.vehicleService.deleteVehicle(vehicle.id).subscribe(() => {
      this.userVehicleList = this.userVehicleList.filter(filterVehicle => filterVehicle.id !== vehicle.id);
      this.archivedVehicleList.unshift(vehicle);
    });
  }

  public submitAddForm(): void {
    this.formSubmitted = true;
    if (this.vehicleAddForm.valid) {
      if (this.stagedVehicle) {
        this.updateVehicle();
      } else {
        this.saveVehicle();
      }
    } else {
      return;
    }
  }

  public updateVehicle(): void {
    this.vehicleService.updateVehicle({
      ...this.stagedVehicle,
      vehicleType: this.vehicleType,
      make: this.vehicleMake,
      model: this.vehicleModel,
      otherModel: this.otherModel, vehicleYear: this.yearOfVehicle,
      color: this.vehicleColor,
      licenseNumber: this.licenseNumber,
      stateCode: this.vehicleStateCode,
      insured: this.vehicleInsured ? 'Y' : 'N',
      electric: this.electricVehicle
    } as IVehicle).subscribe((updatedVehicle: IVehicle) => {
      this.userVehicleList = this.userVehicleList.map((vehicle: IVehicle) => vehicle.id === updatedVehicle.id ? updatedVehicle : vehicle);
      this.formSubmitted = false;
      this.closeModal();
    });
  }

  public saveVehicle(): void {
    let vehicleToSave: IVehicle = {
      id: 0,
      pidm: 1,
      vehicleType: this.vehicleType,
      make: this.vehicleMake,
      model: this.vehicleModel,
      otherModel: this.otherModel, color: this.vehicleColor,
      vehicleYear: this.yearOfVehicle,
      licenseNumber: this.licenseNumber,
      stateCode: this.vehicleStateCode,
      insured: this.vehicleInsured ? 'Y' : 'N',
      carpool: null,
      electric: this.electricVehicle,
      active: "Y",
      printModel: null
    }

    this.formSubmitted = true;

    this.vehicleService.saveVehicle(vehicleToSave).subscribe((vehicle: IVehicle) => {
      this.userVehicleList.unshift(vehicle);
      this.formSubmitted = false;

      if (this.vehicleAddForm.invalid) {
        return;
      }
      this.closeModal();
    });
  }

  public openModal(vehicle: IVehicle | undefined) {
    this.isModalOpen = true;
    this.modalTitle = 'Add Vehicle';
    if (vehicle !== undefined) {
      this.modalTitle = 'Edit Vehicle';

      this.vehicleMakeService.getModels(vehicle.make.id).subscribe((models) => {
        this.vehicleModels = models;
        if (!vehicle.make.text?.includes("Other") && !vehicle.make.text?.includes("other")) {
          if (this.vehicleMakeService.addOtherModel(vehicle.make) != null) {
            this.vehicleModels.push(this.vehicleMakeService.addOtherModel(vehicle.make)!);
          }
        }
        this.showOtherModel = (vehicle.model.text?.includes("Other") || vehicle.model.text?.includes("other")) ? true : false;

        this.stagedVehicle = vehicle;
        this.filteredMakes = this.vehicleMakes;

        let indexOfVehicleType = this.vehicleTypes.findIndex(vtIndex => vtIndex.id === vehicle.vehicleType.id);
        let indexOfVehicleMake = this.filteredMakes.findIndex(mkIndex => mkIndex.id === vehicle.make.id);
        let indexOfVehicleModel = this.vehicleModels.findIndex(modIndex => modIndex.id === vehicle.model.id);
        let indexOfVehicleColor = this.vehicleColors.findIndex(cIndex => cIndex.id === vehicle.color.id);

        this.vehicleAddForm.get('vehicleType')?.disable();
        this.vehicleAddForm.get('vehicleMake')?.disable();
        this.vehicleAddForm.get('vehicleModel')?.disable();
        this.vehicleAddForm.get('yearOfVehicle')?.disable();

        this.vehicleAddForm.setValue({
          vehicleType: this.vehicleTypes[indexOfVehicleType],
          vehicleMake: this.filteredMakes[indexOfVehicleMake],
          vehicleModel: this.vehicleModels[indexOfVehicleModel],
          otherModel: vehicle.otherModel,
          vehicleColor: this.vehicleColors[indexOfVehicleColor],
          yearOfVehicle: vehicle.vehicleYear,
          licenseNumber: vehicle.licenseNumber,
          vehicleStateCode: vehicle.stateCode,
          vehicleInsured: vehicle.insured === 'Y' ? true : false,
          electricVehicle: vehicle.electric,
          active: vehicle.active,
          printModel: vehicle.printModel
        });
      })
    }
  }

  public closeModal() {
    this.isModalOpen = false;
    this.formSubmitted = false;
    this.stagedVehicle = undefined;
    this.filteredMakes = [];
    this.vehicleModels = [];
    this.resetAddVehicleForm();
    this.enableFormInputs();
  }

  public resetAddVehicleForm() {
    this.vehicleAddForm.get('vehicleType')?.setValue(this.selectedVehicleOption);
    this.vehicleAddForm.get('vehicleMake')?.setValue(this.selectedVehicleOption);
    this.vehicleAddForm.get('vehicleModel')?.setValue(this.selectedVehicleOption);
    this.vehicleAddForm.get('otherModel')?.setValue('');
    this.vehicleAddForm.get('vehicleColor')?.setValue(this.selectedVehicleOption);
    this.vehicleAddForm.get('yearOfVehicle')?.setValue('');
    this.vehicleAddForm.get('licenseNumber')?.setValue('');
    this.vehicleAddForm.get('vehicleStateCode')?.setValue(this.selectedVehicleOption);
    this.vehicleAddForm.get('vehicleInsured')?.setValue(false);
    this.vehicleAddForm.get('electricVehicle')?.setValue(this.selectedVehicleOption);
    this.showOtherModel = false;
  }

  public enableFormInputs() {
    this.vehicleAddForm.get('vehicleType')?.enable();
    this.vehicleAddForm.get('vehicleMake')?.enable();
    this.vehicleAddForm.get('vehicleModel')?.enable();
    this.vehicleAddForm.get('yearOfVehicle')?.enable();
  }

  public handleMakeOptionChange(): void {
    this.vehicleAddForm.get('vehicleMake')?.setValue(this.selectedVehicleOption);
    this.vehicleAddForm.get('vehicleModel')?.setValue(this.selectedVehicleOption);
    this.filteredMakes = [];
    this.vehicleModels = [];
    if (this.vehicleType?.id) {
      this.vehicleMakes.map(make => {
        if (this.vehicleType.id === make.vehicleType.id) {
          let textOfMakes = make.text;
          this.filteredMakes.push(make);
        }
      });
    }
  }
}
