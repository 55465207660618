import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {ObjectUnsubscribedError, Observable} from "rxjs";
import {IVehicleMake, IVehicleType} from "../models/vehicle-make.model";
import {environment} from "../../../environments/environment";
import {IVehicleModel} from '../models/vehicle-model.model';

@Injectable({
  providedIn: 'root'
})
export class VehicleMakeRepository {

  constructor(private http: HttpClient) { }

  public getMakes(): Observable<IVehicleMake[]> {
    return this.http.get<IVehicleMake[]>(`${environment.apiUrl}/makes`);
  }

  public add(make: IVehicleMake): Observable<IVehicleMake> {
     return this.http.post<IVehicleMake>(`${environment.apiUrl}/makes`, make);
  }

  public update(make: IVehicleMake): Observable<IVehicleMake> {
     return this.http.put<IVehicleMake>(`${environment.apiUrl}/makes/${make.id}`, make);
  }

  public getVehicleTypes(): Observable<IVehicleType[]> {
    return this.http.get<IVehicleType[]>(`${environment.apiUrl}/vehicle-types`);
  }

  public getModels(makeId: number | null): Observable<IVehicleModel[]> {
    return this.http.get<IVehicleModel[]>(`${environment.apiUrl}/makes/${makeId}/models`);
  }

  public addModel(model: IVehicleModel): Observable<IVehicleModel> {
    return this.http.post<IVehicleModel>(`${environment.apiUrl}/makes/${model.make.id}/models`, model);
  }

  public updateModel(model: IVehicleModel): Observable<IVehicleModel> {
    return this.http.put<IVehicleModel>(`${environment.apiUrl}/makes/${model.make.id}/models/${model.id}`, model);
  }
}
