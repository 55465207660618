import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { ITextBlock } from 'src/app/core/models/textblock.model';
import { TextblockRepository } from 'src/app/core/repository/textblock.repository';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-text-configuration',
  templateUrl: './text-configuration.component.html',
  styleUrls: ['./text-configuration.component.scss']
})

export class TextConfigurationComponent implements OnInit {
  public isEditorOpen: boolean = false;
  public appText: ITextBlock[] = [];
  public appTextblock: ITextBlock;
  public textBlockSpecs = [
    { code: 'PARKIN', description: "Parking Agreement" },
    { code: 'PAYROL', description: "Payroll Deduction Agreement" },
    { code: 'PARK1', description: "Parking Intro Page" },
    { code: 'PARK2', description: "Parking Decal Step Instructions" }
  ];

  public textConfigCodeForm = this.formBuilder.group({
    code: this.textBlockSpecs[0].code
  })

  public textBlockEditorForm = this.formBuilder.group({
    signatureCodeBean: {
      code: '',
      description: '',
      activityDate: '',
      user: ''
    },
    revisionNumber: 0,
    currentIndicator: '',
    documentText: '',
    activityDate: '',
    user: ''
  })

  public Editor = ClassicEditor;

  constructor(private formBuilder: UntypedFormBuilder, private textblockRepo: TextblockRepository) { }

  ngOnInit(): void { }

  public getAppTextblock(ev: ITextBlock) {
    this.appTextblock = ev;
  }

  public get textBlockContent(): ITextBlock {
    return this.textBlockEditorForm.get('documentText')?.value;
  }

  public get code(): string {
    return this.textConfigCodeForm.get('code')?.value;
  }

  public changeTextBlockContent(tbc: string) {
    this.textBlockEditorForm.get('textBlockEditorForm')?.setValue(tbc);
  }

  public changeTextConfigCode(tcc: string) {
    this.textConfigCodeForm.get('textConfigCodeForm')?.setValue(tcc);
  }

  public openEditor(): void {
    this.isEditorOpen = !this.isEditorOpen;
  }

  public submitTextBlockForm(): void {
    let newTextblock: ITextBlock = {
      signatureCodeBean: {
        code: this.appTextblock.signatureCodeBean.code,
        description: this.appTextblock.signatureCodeBean.description,
        activityDate: this.appTextblock.activityDate,
        user: this.appTextblock.user
      },
      revisionNumber: 0,
      currentIndicator: this.appTextblock.currentIndicator,
      documentText: this.appTextblock.documentText,
      activityDate: this.appTextblock.activityDate,
      user: this.appTextblock.user
    }
    this.textblockRepo.updateTextblock(this.code, newTextblock).subscribe((textblock: ITextBlock) => {
      this.appText.push(textblock);
      this.isEditorOpen = false;
    });
  }
}
