import { Injectable } from "@angular/core";
import { RolloverRepository } from '../repository/rollover.repository';
import { IDecalType } from '../models/decal-type.model';
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class RolloverService{

  constructor(private rolloverRepo: RolloverRepository){}

  public rolloverDecals(decalList:IDecalType[]): Observable<Response> {
    return this.rolloverRepo.rolloverDecals(decalList);
  }

}
