import {Injectable} from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import {UserRepository} from "../../../repository/user.repository";
import {
  endImpersonateUserSuccessAction,
  getEmployeeStatusAction,
  getEmployeeStatusFailureAction,
  getEmployeeStatusSuccessAction,
  getUserNameAction,
  getUserNameFailureAction,
  getUserNameSuccessAction, getUserRolesAction,
  getUserRolesFailureAction,
  getUserRolesSuccessAction, impersonateUserSuccessAction
} from "../auth.actions";
import {exhaustMap, filter, map} from "rxjs/operators";
import {catchError, of} from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import {HelloRepository} from "../../../repository/hello.repository";
import {select, Store} from "@ngrx/store";
import {getUserNameSelector, getUserRolesSelector} from "../auth.selectors";

@Injectable()
export class GetUserNameEffect {

  // noinspection TypeScriptValidateTypes
  getUserName$ = createEffect(() => this.actions$.pipe(
    ofType(getUserNameAction),
    // if it's already been loaded, don't refetch from the api; that's what these next 2 lines do
    concatLatestFrom(() => this.store.pipe(select(getUserNameSelector))),
    filter(([,username]) => !username),
    exhaustMap(() => {
      return this.helloRepo.getUser().pipe(
        map(response => getUserNameSuccessAction({data: response})),
        catchError((errorResponse: HttpErrorResponse) => of(getUserNameFailureAction({error: errorResponse})))
      );
    })
  ));

  // noinspection TypeScriptValidateTypes
  getUserRoles$ = createEffect(() => this.actions$.pipe(
    ofType(
      getUserNameSuccessAction,
      getUserRolesAction,
      impersonateUserSuccessAction,
      endImpersonateUserSuccessAction,
    ),
    concatLatestFrom(() => this.store.pipe(select(getUserRolesSelector))),
    filter(([,roles]) => !roles),
    exhaustMap(() => {
      return this.userRepo.getUserRoles().pipe(
        map(response => getUserRolesSuccessAction({data: response})),
        catchError((errorResponse: HttpErrorResponse) => of(getUserRolesFailureAction({error: errorResponse})))
      );
    })
  ));

  // noinspection TypeScriptValidateTypes
  getEmployeeStatus$ = createEffect(() => this.actions$.pipe(
    ofType(
      getUserNameSuccessAction,
      getEmployeeStatusAction,
      impersonateUserSuccessAction,
      endImpersonateUserSuccessAction,
      ),
    exhaustMap(() => {
      return this.userRepo.getEmployeeStatus().pipe(
        map(response => getEmployeeStatusSuccessAction({isEmployee: response})),
        catchError((errorResponse: HttpErrorResponse) => of(getEmployeeStatusFailureAction({error: errorResponse})))
      );
    })
  ));

  constructor(private actions$: Actions,
              private store: Store,
              private helloRepo: HelloRepository,
              private userRepo: UserRepository) {
  }
}
