<h2 data-cy="title">View Vehicle Colors</h2>
<p>This is a list of vehicle colors. To add a new vehicle color, click the "Add Vehicle Color" button at the bottom of the list.</p>

<h3>Vehicle Color</h3>

<ul *ngIf="vehicleColors">
  <li *ngFor="let color of vehicleColors" data-cy="vehicle-color">
    <div data-cy="vehicle-color-text">{{color.text}}</div>
    <a id="right" (click)="openModal(color)">
      <img id="edit-icon" src="assets/edit.svg" alt="Edit">
      <span id="edit-text">Edit</span>
    </a>
  </li>
</ul>

<button *ngIf="vehicleColors" (click)="openModal(undefined)" data-cy="add-vehicle-color">Add Vehicle Color</button>

<app-modal (closeModalEvent)="closeModal()" *ngIf="isModalOpen" appTrapFocus>
  <form class="modalContent" [formGroup]="colorForm" (ngSubmit)="submitForm()">
    <h3>Enter Vehicle Color</h3>
    <label>Color</label>
    <input type="text" formControlName="name" data-cy="input">
    <button type="submit">Submit</button>
  </form>
</app-modal>


