<h1><b>Order Decal</b></h1>
<mat-stepper [linear]="true" [orientation]="(stepperOrientation | async)!" #stepper>
  <mat-step label="Verify Commuter Address" [completed]="true" [editable]="step1Editable && !(agreementsComplete$ | async)" *ngIf="step1Editable">
    <app-commuter-address></app-commuter-address>
  </mat-step>
  <mat-step label="Select Vehicles" [completed]="selectVehiclesComplete$ | async" [editable]="!(agreementsComplete$ | async)">
    <app-select-vehicles></app-select-vehicles>
  </mat-step>
  <mat-step label="Select Decals" [completed]="selectDecalsComplete$ | async" [editable]="!(agreementsComplete$ | async)">
    <app-select-decals></app-select-decals>
  </mat-step>
  <mat-step label="Confirmation & Agreements"  [completed]="agreementsComplete$ | async" [editable]="!(agreementsComplete$ | async)">
    <app-agreements></app-agreements>
  </mat-step>
  <mat-step label="Receipt" [completed]="false">
    <app-receipt></app-receipt>
  </mat-step>
</mat-stepper>
