import { Component, OnInit, ViewChild } from '@angular/core';
import { map, Observable, tap } from "rxjs";
import { MemoizedSelector, select, Store } from "@ngrx/store";
import {
  isDecalStepCompleteSelector,
  isStep1DisabledSelector,
  isVehicleStepCompleteSelector,
  isAgreementStepCompleteSelector
} from "../store/order-decals.selectors";
import { MatStepper, StepperOrientation } from "@angular/material/stepper";
import { BreakpointObserver } from "@angular/cdk/layout";
import { resetOrderProcessAction } from '../store/order-decals.actions';

@Component({
  selector: 'app-order-process',
  templateUrl: './order-process.component.html',
  styleUrls: ['./order-process.component.scss']
})
export class OrderProcessComponent implements OnInit {

  public selectVehiclesComplete$: Observable<boolean>;
  public selectDecalsComplete$: Observable<boolean>;
  public agreementsComplete$: Observable<boolean>;
  public step1Editable = true;
  public stepperOrientation: Observable<StepperOrientation>;

  @ViewChild('stepper')
  stepper: MatStepper;

  constructor(private breakpointObserver: BreakpointObserver,
    private store: Store) { }

  ngOnInit(): void {
    this.setupStepperOrientation();
    this.setupStepperSubscriptions();
  }

  // step 2 setup stepper orientation
  public setupStepperOrientation() {
    this.store.pipe(
      select(isStep1DisabledSelector),
    ).subscribe(isDisabled => {
      this.step1Editable = !isDisabled;

      this.getStepperOrientation(isDisabled)
    });
  }

  public getStepperOrientation(isStep1Disabled: boolean) {
    // if step one is disabled the stepper has 4 steps, otherwise it has 5
    const minWidth = isStep1Disabled ? '(min-width: 850px)' : '(min-width: 1100px)';

    this.stepperOrientation = this.breakpointObserver.observe(minWidth).pipe(
      map(({ matches }) => matches ? 'horizontal' : 'vertical')
    );
  }

  // step 3 setup stepper subscriptions
  public setupStepperSubscriptions() {
    this.selectVehiclesComplete$ = this.setupCompleteSubscriptions(isVehicleStepCompleteSelector)
    this.selectDecalsComplete$ = this.setupCompleteSubscriptions(isDecalStepCompleteSelector)
    this.agreementsComplete$ = this.setupCompleteSubscriptions(isAgreementStepCompleteSelector);
  }

  public setupCompleteSubscriptions(selector: MemoizedSelector<object, boolean>): Observable<boolean> {
    return this.store.pipe(
      select(selector),
      tap(isComplete => {
        if (isComplete) {
          setTimeout(() => this.stepper.next(), 500)
        }
      }));
  }
}
