import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IDecalColor } from '../../core/models/decal-color.model';
import { DecalColorRepository } from '../../core/repository/decal-color.repository';

@Component({
  selector: 'app-decal-type-icon',
  templateUrl: './decal-type-icon.component.html',
  styleUrls: ['./decal-type-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DecalTypeIconComponent {

  @Input()
  public decalColor: string;

  @Input()
  public decalCode: string;

  @Input()
  public decalDescription: string;

  public iconColor:string;
  private decalColorList: IDecalColor[];

  constructor(private decalColorRepo: DecalColorRepository) { }

}
