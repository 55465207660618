import { Injectable } from '@angular/core';
import { IDecalType } from '../models/decal-type.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AutoAssignRepository{
  constructor(private http: HttpClient) {}

  public autoAssignDecals(decalList:IDecalType[]): Observable<Response> {
    return this.http.post<Response>(`${environment.apiUrl}/decal-assignment/start`, decalList);
  }
}
