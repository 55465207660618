<!--<div *ngIf="showHeader" class="vehicle-headers">-->
  <tr *ngIf="showHeader" class="vehicle-headers">
    <td class="list-vehicle-header">
      <h2 class="list-header-vehicle">Vehicle</h2>
    </td>
    <td class="list-year-header">
      <h2 class="list-header-year">Year</h2>
    </td>
    <td class="list-license-header">
      <h2 class="list-header-license">License</h2>
    </td>
    <td class="list-color-header">
      <h2 class="list-header-color">Color</h2>
    </td>
    <td></td>
  </tr>
  <!--<h2 class="list-header-vehicle">Vehicle</h2>
  <h2 class="list-header-year">Year</h2>
  <h2 class="list-header-license">License</h2>
  <h2 class="list-header-color">Color</h2>-->
<!--</div>-->
<!--<div class="wrapper">-->
  <tr *ngFor="let vehicle of vehicleList" data-cy="vehicle-color" class="tableRow {{tableRowFormat}}">
    <td class="list-vehicle-data tableCell {{width}}">{{vehicle.make.text}} {{vehicle.model.text}}</td>
    <!--<div class="vehicleStatus tableCell {{group}}">-->
      <td class="list-year-data tableCell {{group}}">{{vehicle.vehicleYear}}</td>
      <td class="list-license-data tableCell {{group}}">{{vehicle.licenseNumber}}</td>
      <td class="list-color-data tableCell {{group}}">{{vehicle.color.text}}</td>
   <!-- </div>-->
    <td class="buttonWrapper tableCell {{btnFormat}}" [ngClass]="(showDelete || showEdit) ? '' : 'archivedVehiclePadding'">
      <div id="deleteBtn" class="list-button">
        <button type="button" class="deleteButton" *ngIf="showDelete" (click)="deleteVehicle(vehicle)" attr.aria-label="Delete {{vehicle.make.text}} {{vehicle.model.text}} from vehicle list">
          <img id="delete-icon" src="assets/delete.svg" alt="Delete">
          Delete
        </button>
      </div>
      <div class="list-button">
        <button type="button" class="editButton" *ngIf="showEdit" (click)="editVehicle(vehicle)" [attr.aria-label]="'Edit information for' + vehicle.vehicleYear + vehicle.color.text + vehicle.make.text + vehicle.model.text + 'with plate number' + vehicle.licenseNumber">
          <img id="edit-icon" src="assets/edit.svg" alt="">
          Edit
        </button>
      </div>
    </td>
  </tr>
<!--</div>-->
