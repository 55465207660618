export interface IVehicleMake {
  id: number | null,
  vehicleType: IVehicleType,
  text: string | undefined
}

export interface IVehicleType {
  id: number | null,
  text: string | undefined
}

export enum VehicleTypes {
  MOTORCYCLE = 10,
  CAR = 11,
  BIKE = 12,
  MOPED_50CC_OR_GREATER = 14,
  MOPED_LESS_THAN_50CC = 16,
  ELECTRIC_SCOOTER = 22,
}
