import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IVehicle } from "../models/vehicle.model";
import { IStateCode } from "../models/statecode.model";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class VehicleRepository {
  constructor(private http: HttpClient) { }

  public getVehicles(): Observable<IVehicle[]> {
    return this.http.get<IVehicle[]>(`${environment.apiUrl}/user/vehicles`);
  }

  public getStateCodes(): Observable<IStateCode[]> {
    return this.http.get<IStateCode[]>(`${environment.apiUrl}/states`);
  }

  public saveVehicle(vehicle: IVehicle): Observable<IVehicle> {
    return this.http.post<IVehicle>(`${environment.apiUrl}/user/vehicles`, vehicle);
  }

  public updateVehicle(vehicle: IVehicle): Observable<IVehicle> {
    return this.http.put<IVehicle>(`${environment.apiUrl}/user/vehicles/${vehicle.id}`, vehicle);
  }

  public deleteVehicle(vehicleId: number): Observable<{}> {
    return this.http.delete<IVehicle>(`${environment.apiUrl}/user/vehicles/${vehicleId}`);
  }
}
