<h1>Configuration</h1>
<div id="main-content">
  <nav>
    <ul>
      <li [ngClass]="{'active': navLinks['vehicleColor']}">
        <a [routerLink]="['vehicle-color']">Vehicle Color</a>
      </li>
      <li [ngClass]="{'active': navLinks['vehicleMake']}">
        <a [routerLink]="['vehicle-make']">Vehicle Make</a>
      </li>
      <li [ngClass]="{'active': navLinks['vehicleModel']}">
        <a [routerLink]="['vehicle-model']">Vehicle Model</a>
      </li>
      <li [ngClass]="{'active': navLinks['decalColor']}">
        <a [routerLink]="['decal-color']">Decal Color</a>
      </li>
      <li [ngClass]="{'active': navLinks['decalType']}">
        <a [routerLink]="['decal-type']">Decal Type</a>
      </li>
      <li [ngClass]="{'active': navLinks['autoAssign']}">
        <a [routerLink]="['auto-assign']">Auto Assign Decals</a>
      </li>
      <li [ngClass]="{'active': navLinks['rollover']}">
        <a [routerLink]="['rollover']">Roll Over Decals</a>
      </li>
      <li [ngClass]="{'active': navLinks['textConfiguration']}">
        <a [routerLink]="['text-configuration']">Text Configuration</a>
      </li>
    </ul>
  </nav>
  <router-outlet></router-outlet>
</div>
