import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { DecalTypeService } from '../../../core/service/decal-type.service';
import { IDecalType } from '../../../core/models/decal-type.model';
import { RolloverService } from 'src/app/core/service/rollover.service';
import { FormControl, FormGroup } from '@angular/forms';

enum RolloverSuccess{
  dialogTitle = "Rollover Successful",
  dialogMessage = "The selected decals have been rolled over."
}

enum RolloverFailure{
  dialogTitle = "Rollover NOT Successful",
  dialogMessage = "The selected decals have NOT been rolled over."
}

@Component({
  selector: 'app-rollover',
  templateUrl: './rollover.component.html',
  styleUrls: ['./rollover.component.scss']
})
export class RolloverComponent implements OnInit {
  public decals: IDecalType[];
  public selectedDecals: IDecalType[] = [];

  public currentYear = new Date().getUTCFullYear();
  public yearSelectOptions: Array<{range: string, year: number}> = [];
  public yearForm = new FormGroup({
    year: new FormControl(this.currentYear)
  });

  public isSelectAllChecked: boolean = false;
  public isClearAllChecked: boolean = false;
  public isShowInActiveChecked: boolean = false;


  @ViewChild('dialogTemplate')
  public template: TemplateRef<any>;
  public dialogTitle: string;
  public dialogMessage: string;

  constructor(private decalTypeService: DecalTypeService, private rolloverService: RolloverService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.initialize();
  }

  public initialize(){
    this.initYearForm();
    this.setCurrentYear();
    this.initYearSelectOptions();
  }

  private getDecalTypes(selectedYear: number) {
    this.decalTypeService.getDecalTypes(selectedYear).subscribe((decals: IDecalType[])=>{
      this.decals = decals;
    })
  }

  public isDecalSelected(decal: IDecalType): boolean {
    return this.selectedDecals.includes(decal);
  }

  public toggleSelectDecal(decal: IDecalType) {
    if (this.selectedDecals.includes(decal)){
      // remove decal
      var i = this.selectedDecals.indexOf(decal);
      this.selectedDecals.splice(i, 1);
    } else {
      //add decal
      this.selectedDecals.push(decal);
    }
    if (this.selectedDecals.length > 0) {
      this.isClearAllChecked = false;
    }
  }

  public initYearForm(): void {
    this.yearForm.get('year')?.valueChanges.subscribe((year: number | null) => {
      if (year !== null) {
       this.getDecalTypes(year);
      }
    });
  }

  public setCurrentYear(): void {
    if (this.isBeforeJuly()) {
      this.yearForm.get('year')?.setValue(this.currentYear - 1);
    } else {
      this.yearForm.get('year')?.setValue(this.currentYear);
    }
  }

  private isBeforeJuly(): boolean {
    return new Date().getUTCMonth() < 6;
  }

  private initYearSelectOptions(): void {
    for(let year = this.currentYear; year >= this.currentYear - 1; year--) {
      this.yearSelectOptions.push({
        range: year + " - " + (year + 1),
        year: year
      });
    }
  }

  public SelectAllDecalTypes() {
    this.isSelectAllChecked = !this.isSelectAllChecked;
    if (this.isSelectAllChecked) {
        this.decals.forEach((decal)=> {
          if (this.selectedDecals.includes(decal)) {
          }else{
            this.selectedDecals.push(decal);
          }
        });
    } else {
      this.selectedDecals = [];
    }

    if (!this.isShowInActiveChecked) {
       this.removeInactiveDecalsFromSelectedList();
    }
    this.isClearAllChecked = false;
  }


  public ClearAllDecalTypes() {
    this.isClearAllChecked = !this.isClearAllChecked
    if (this.isClearAllChecked) {
      this.selectedDecals = [];
      this.isSelectAllChecked = false;
    }
  }

  public toggleFilterInactive() {
    this.isShowInActiveChecked = !this.isShowInActiveChecked;

    if (!this.isShowInActiveChecked) {
      this.removeInactiveDecalsFromSelectedList();
    }
  }

  private removeInactiveDecalsFromSelectedList() {
    this.decals.forEach((decal)=> {
      if (!decal.active) {
        if (this.selectedDecals.includes(decal)) {
          //select all, but not inactive, so remove inactive decal
          var i = this.selectedDecals.indexOf(decal);
          this.selectedDecals.splice(i, 1);
        }
      }
    });
  }

  public filteredDecalTypes(): Array<IDecalType> {
    return this.decals.filter((decalType: IDecalType) => decalType.active || this.isShowInActiveChecked);
  }

  public rolloverDecals() {
    this.rolloverService.rolloverDecals(this.selectedDecals).subscribe(()=> {
    },
    (error) => {
      console.error(error);
      this.showResponseDialog(RolloverFailure);
      this.clearAfterRollOver();
    },
    () => {
      this.showResponseDialog(RolloverSuccess);
      this.clearAfterRollOver();
    });
  }

  public showResponseDialog(RolloverMessages: typeof RolloverFailure | typeof RolloverSuccess) {
    this.dialogTitle = RolloverMessages.dialogTitle;
    this.dialogMessage = RolloverMessages.dialogMessage;
    this.dialog.open(this.template, {
      disableClose: true,
      hasBackdrop: true,
      minWidth: 400
    });
  }

  public closeDialog() {
    this.dialog.closeAll();
  }

  public clearAfterRollOver() {
    this.selectedDecals = [];
    this.isSelectAllChecked = false;
    this.isClearAllChecked = false;
    this.isShowInActiveChecked = false;
  }

}
