import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { map, Observable, tap } from 'rxjs';
import { filter } from 'rxjs/operators';
import { getCommuterStatusAction } from './core/store/auth/auth.actions';
import { isCommuterLoadingSelector, isCommuterSelector } from './core/store/auth/auth.selectors';

@Injectable({
  providedIn: 'root'
})
class OrderProcessGuard  {
  constructor(
    private store: Store
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {

    this.store.pipe(
      select(isCommuterSelector),
      tap(isCommuter => {
        if(!!isCommuter){
          return true;
        } else {
          this.store.dispatch(getCommuterStatusAction());
          return false;
        }
      })
    ).subscribe();

    return this.store.pipe(
      select(isCommuterLoadingSelector),
      filter(isLoadingCommuter => !isLoadingCommuter),
      map(isLoading => !isLoading)
    );
  }
}


// Export all guards in a single object
export const guards = {
  OrderProcessGuard
};
