<h2 data-cy="title">View Decal Colors</h2>

<div class="headerContainer">
  <h3 class="colorCodeColumn">Code</h3>
  <h3 class="descriptionColumn">Description</h3>
  <h3 class="classNameColumn">Class Name</h3>
  <h3 class="editColumn"></h3>
</div>

<ul *ngIf="decalColors">
  <li *ngFor="let color of decalColors" data-cy="decal-color">
    <div class="colorCodeColumn" data-cy="decal-color-code">{{color.code}}</div>
    <div class="descriptionColumn" data-cy="decal-color-desc">{{color.description}}</div>
    <div class="classNameColumn"  data-cy="decal-color-class">{{color.className}}</div>
    <a class="editColumn" id="right" (click)="openModal(color)">
      <img id="edit-icon" src="assets/edit.svg" alt="Edit">
      <span id="edit-text">Edit</span>
    </a>
  </li>
</ul>

<button *ngIf="decalColors" (click)="openModal(undefined)" data-cy="add-decal-color">Add Decal Color</button>

<app-modal (closeModalEvent)="closeModal()" *ngIf="isModalOpen" appTrapFocus>
  <form class="modalContent" [formGroup]="decalColorForm" (ngSubmit)="submitForm()">
    <h3>Enter Vehicle Decal Color</h3>
    <label>Code</label>
    <input
      type="text"
      formControlName="decalColorCode"
      data-cy="input-code"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['decalColorCode'].errors}"
      required
    >
    <div *ngIf="formSubmitted && form['decalColorCode'].errors" class="invalid-feedback">
      <div *ngIf="form['decalColorCode'].errors['required']">Please enter a code.</div>
    </div>

    <label>Description</label>
    <input
      type="text"
      formControlName="decalColorDescription"
      data-cy="input-desc"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['decalColorDescription'].errors}"
      required
    >
    <div *ngIf="formSubmitted && form['decalColorDescription'].errors" class="invalid-feedback">
      <div *ngIf="form['decalColorDescription'].errors['required']">Please enter a description.</div>
    </div>

    <label>Class Name</label>
    <select
      formControlName="decalColorClassName"
      data-cy="input-class"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['decalColorClassName'].errors}"
      required
    >
    <option *ngFor="let colorClass of decalColors" [ngValue]="colorClass.className" >{{colorClass.className}}</option>
    </select>
    <div *ngIf="formSubmitted && form['decalColorClassName'].errors" class="invalid-feedback">
      <div *ngIf="form['decalColorClassName'].errors['required']">Please enter a class name.</div>
    </div>

    <button type="submit">Submit</button>
  </form>
</app-modal>
