import { Component, OnInit } from '@angular/core';
import { IDecalColor } from '../../../core/models/decal-color.model';
import { DecalColorService } from '../../../core/service/decal-color.service';
import {FormControl, UntypedFormBuilder, Validators} from '@angular/forms';
import { HelloRepository } from '../../../core/repository/hello.repository';

@Component({
  selector: 'app-decal-color',
  templateUrl: './decal-color.component.html',
  styleUrls: ['./decal-color.component.scss']
})
export class DecalColorComponent implements OnInit {
  public decalColors: IDecalColor[] = [];
  public stagedDecalColor: IDecalColor | undefined;
  public isModalOpen: boolean = false;
  public formSubmitted: boolean = false;
  public userName: string | null = null;
  public decalColorForm = this.formBuilder.group({
    decalColorCode: ['', Validators.required],
    decalColorDescription: ['', Validators.required],
    decalColorClassName: ['', Validators.required]
  });

  constructor(private decalColorService: DecalColorService, private formBuilder: UntypedFormBuilder, private helloRepo: HelloRepository) { }

  ngOnInit(): void {
    this.getDecalColors();
    this.helloRepo.getUser().subscribe((user: string) => {
      this.userName = user;
    });
  }

  public get form() {
    return this.decalColorForm.controls;
  }

  public get decalColorCode(): any {
    return this.decalColorForm.get('decalColorCode');
  }

  public get decalColorDescription(): any {
    return this.decalColorForm.get('decalColorDescription');
  }

  public get decalColorClassName(): any {
    return this.decalColorForm.get('decalColorClassName');
  }

  public getDecalColors(): void {
    this.decalColorService.getDecalColors().subscribe((colors: IDecalColor[]) => {
      this.decalColors = colors;
    });
  }

  public openModal(decalColor: IDecalColor | undefined) {
    this.isModalOpen = true;
    if(decalColor !== undefined) {
      this.stagedDecalColor = decalColor;
      let indexOfDecalColor = this.decalColors.findIndex(dc => dc.id === decalColor.id);
      this.decalColorForm.setValue({
        decalColorCode: this.decalColors[indexOfDecalColor].code,
        decalColorDescription: this.decalColors[indexOfDecalColor].description,
        decalColorClassName: this.decalColors[indexOfDecalColor].className
      });
    }
  }

  public closeModal() {
    this.stagedDecalColor = undefined;
    this.formSubmitted = false;
    this.isModalOpen = false;
    this.decalColorForm.reset();
  }

  public submitForm(): void {
    if (this.stagedDecalColor) {
      this.updateDecalColor();
    } else {
      this.addDecalColor();
    }
  }

  public updateDecalColor(): void {
    this.decalColorService
      .updateDecalColor({
        ...this.stagedDecalColor,
        code: this.decalColorCode.value,
        description: this.decalColorDescription.value,
        className: this.decalColorClassName.value,
      } as IDecalColor)
      .subscribe((updatedDecalColor: IDecalColor) => {
        this.decalColors = this.decalColors.map((decalColor: IDecalColor) =>
          decalColor.id === updatedDecalColor.id ? updatedDecalColor : decalColor
        );
        this.closeModal();
      });
  }

  public addDecalColor(): void {
    let newDecalColor: Partial<IDecalColor> = {
      code: this.decalColorCode.value,
      description: this.decalColorDescription.value,
      className: this.decalColorClassName.value,
      username: 'user',
    };

    this.formSubmitted = true;

    this.decalColorService.addDecalColor(newDecalColor).subscribe((decalColor: IDecalColor) => {
      this.decalColors.push(decalColor);

      if (this.decalColorForm.invalid) {
        return;
      }

      this.closeModal();
    });
  }
}
