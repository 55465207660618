import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { DecalRestrictionRepository } from '../repository/decal-restriction.repository';
import { IDecalRestriction } from '../models/decal-restriction.model';
import { IRestrictionType } from '../models/restriction-type.model';

@Injectable({
  providedIn: 'root'
})

export class DecalRestrictionService {
  constructor(private decalRestrictionRepo: DecalRestrictionRepository){};

  // Returns any Restriction type for a particular Decal type
  public getDecalRestriction(decalId: number): Observable<IDecalRestriction[]> {
    return this.decalRestrictionRepo.getDecalRestriction(decalId);
  }

  // Returns Restriction type list
  public getRestrictionTypes(): Observable<IRestrictionType[]> {
    return this.decalRestrictionRepo.getRestrictionTypes();
  }

  public addRestriction(decalRestriction: IDecalRestriction): Observable<IDecalRestriction> {
    return this.decalRestrictionRepo.addRestriction(decalRestriction);
  }

  public updateRestriction(decalRestrictionType: IDecalRestriction): Observable<IDecalRestriction> {
    return this.decalRestrictionRepo.updateRestriction(decalRestrictionType);
  }

  public deleteRestriction(decalRestriction: IDecalRestriction): Observable<{}> {
    return this.decalRestrictionRepo.deleteRestriction(decalRestriction);
  }
}
