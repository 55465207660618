import { createAction, props } from '@ngrx/store';
import { IVehicle } from "../../../core/models/vehicle.model";
import { HttpErrorResponse } from "@angular/common/http";
import { IDecalType } from 'src/app/core/models/decal-type.model';
import { ISelectedVehicleDecalPair } from 'src/app/core/models/selected-vehicle-decal-pair.model';

export const resetOrderProcessAction = createAction(
  '[Order Decals] Reset Order Decal Process'
);

export const setStepNumberAction = createAction(
  '[Order Decals] Set Step Number',
  props<{ stepNum: number }>()
);

export const setVehicleListAction = createAction(
  '[Order Decals] Set Vehicle List',
  props<{ vehicleList: IVehicle[] }>()
);

export const setDecalTypeAction = createAction(
  '[Order Decals] Set Decal Type',
);

export const setDecalTypeSelectedAction = createAction(
  '[Order Decals] Set Decal Type Selected',
  props<{ decalType: IDecalType[], pairs: ISelectedVehicleDecalPair[] }>()
);

export const setAgreementInfoAction = createAction(
  '[Order Decals] Set Agreement Information'
);

export const submitPhoneAction = createAction(
  '[Order Decals] Submit Phone'
);

export const submitPhoneActionSuccess = createAction(
  '[Order Decals] Submit Phone Success'
);

export const submitPhoneActionFailure = createAction(
  '[Order Decals] Submit Phone Failure',
  props<{ error: HttpErrorResponse }>()
);

export const submitGeneralAgreementAction = createAction(
  '[Order Decals] Submit General Agreement'
);

export const submitGeneralAgreementActionSuccess = createAction(
  '[Order Decals] Submit General Agreement Success'
);

export const submitGeneralAgreementActionFailure = createAction(
  '[Order Decals] Submit General Agreement Failure',
  props<{ error: HttpErrorResponse }>()
);

export const submitPayrollAgreementAction = createAction(
  '[Order Decals] Submit Payroll Agreement'
);

export const submitPayrollAgreementActionSuccess = createAction(
  '[Order Decals] Submit Payroll Agreement Success'
);

export const submitPayrollAgreementActionFailure = createAction(
  '[Order Decals] Submit Payroll Agreement Failure',
  props<{ error: HttpErrorResponse }>()
);

export const submitDecalsAction = createAction(
  '[Order Decals] Submit Decals',
  props<{ vehicleDecalPairs: ISelectedVehicleDecalPair[] }>()
);

export const submitDecalsActionSuccess = createAction(
  '[Order Decals] Submit Decals Success',
  props<{ results: ISelectedVehicleDecalPair[] }>()
);

export const submitDecalsActionFailure = createAction(
  '[Order Decals] Submit Decals Failure',
  props<{ error: HttpErrorResponse }>()
);

export const setVehicleListFailureAction = createAction(
  '[Order Decals] Set Vehicle List Failure',
  props<{ error: HttpErrorResponse }>()
);

export const getVehicleListAction = createAction(
  '[Order Decals] Get Vehicle List'
);

export const getVehicleListSuccessAction = createAction(
  '[Order Decals] Get Vehicle List Success',
  props<{ vehicleList: IVehicle[] }>()
);

export const getVehicleListFailureAction = createAction(
  '[Order Decals] Get Vehicle List Failure',
  props<{ error: HttpErrorResponse }>()
);

export const setSelectedTypesAction = createAction(
  '[Order Decals] Set Selected Types'
);

export const setGeneralContractAgreedAction = createAction(
  '[Order Decals] Set General Contract Agreed',
  props<{ hasAgreed: boolean }>()
);

export const setPayrollContractAgreedAction = createAction(
  '[Order Decals] Set Payroll Contract Agreed',
  props<{ hasAgreed: boolean }>()
);

export const setEmergencyPhoneAction = createAction(
  '[Order Decals] Set Emergency Phone',
  props<{ phone: string }>()
);

export const setCarSelectedAction = createAction(
  '[Order Decals] Set Select Car ',
    props<{ hasSelected: boolean }>()
);

export const setMotorcycleSelectedAction = createAction(
  '[Order Decals] Set Select Motorcycle ',
    props<{ hasSelected: boolean }>()
);

export const setBikeSelectedAction = createAction(
  '[Order Decals] Set Select Bike ',
    props<{ hasSelected: boolean }>()
);


