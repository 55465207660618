import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {getCommuterStatusAction, getCommuterStatusSuccessAction, getUserRolesFailureAction} from "../auth.actions";
import {catchError, exhaustMap, map, of, pipe} from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import {UserRepository} from "../../../repository/user.repository";

@Injectable()
export class GetCommuterStatusEffect {

  // noinspection TypeScriptValidateTypes
  getCommuterStatus$ = createEffect(() => this.actions$.pipe(
    ofType(getCommuterStatusAction),
    exhaustMap(() => {
      return this.userRepo.getCommuterStatus().pipe(
        map(response => getCommuterStatusSuccessAction({isCommuter: response})),
        catchError((errorResponse: HttpErrorResponse) => of(getUserRolesFailureAction({error: errorResponse})))
      );
    })
  ));

  constructor(private actions$: Actions,
              private userRepo: UserRepository
              ) {
  }
}
