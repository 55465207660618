import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { ITextBlock } from "../models/textblock.model";
import { environment } from "src/environments/environment";

@Injectable ({
  providedIn: 'root'
})

export class TextblockRepository{
  constructor(private http: HttpClient) {}

  /**
   * get a text block to display based on code type (i.e. PARKIN, PAYROL)
   * @param code
   * @returns textblock
   */
  public getTextblock(code:string): Observable<ITextBlock> {
    return this.http.get<ITextBlock>(`${environment.apiUrl}/text-block?id=${code}`);
  }

  /**
   * updates a text block for a given code (i.e. PARKIN, PAYROL) 
   * @param code
   * @param textblock
   * @returns
   */
  public updateTextblock(code:string, textblock:ITextBlock): Observable<ITextBlock> {
    return this.http.put<ITextBlock>(`${environment.apiUrl}/text-block/${code}`, textblock);
  }
}
