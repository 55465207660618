import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CommonModule, JsonPipe } from '@angular/common';
import { ToastrModule } from "ngx-toastr";
import { InjectorModule } from "./core/service/injector/injector.module";
import { AuthService } from "./core/service/auth/auth.service";
import { HeaderComponent } from "./header/header.component";
import { DecalsComponent } from "./feature/decals/decals.component";
import { VehiclesComponent } from "./feature/vehicles/vehicles.component";
import { NavbarComponent } from './navbar/navbar.component';
import { PayrollDeductionComponent } from './feature/payroll-deduction/payroll-deduction.component';
import { ConfigurationComponent } from './feature/configuration/configuration.component';
import { VehicleColorComponent } from './feature/configuration/vehicle-color/vehicle-color.component';
import { VehicleMakeComponent } from './feature/configuration/vehicle-make/vehicle-make.component';
import { VehicleModelComponent } from './feature/configuration/vehicle-model/vehicle-model.component';
import { DecalColorComponent } from './feature/configuration/decal-color/decal-color.component';
import { DecalTypeComponent } from './feature/configuration/decal-type/decal-type.component';
import { DecalRestrictionComponent } from './feature/configuration/decal-restriction/decal-restriction.component';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { GetUserNameEffect } from "./core/store/auth/effects/getUserName.effect";
import { authReducers } from "./core/store/auth/auth.reducers";
import { OrderProcessModule } from "./feature/order-process/order-process.module";
import { GetCommuterStatusEffect } from "./core/store/auth/effects/getCommuterStatus.effect";
import { SetVehicleListEffect } from './feature/order-process/store/effects/setVehicleList.effect';
import { GetVehicleListEffect } from './feature/order-process/store/effects/getVehicleList.effect';
import { AutoAssignComponent } from './feature/configuration/auto-assign/auto-assign.component';
import { RolloverComponent } from './feature/configuration/rollover/rollover.component';
import { TextConfigurationComponent } from './feature/configuration/text-configuration/text-configuration.component';
import { ImpersonateEffect} from "./core/store/auth/effects/impersonate.effect";
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import {VehicleDecalsEffect} from "./core/store/vehicle-decals/vehicle-decals.effect";
import {vehicleDecalsReducers} from "./core/store/vehicle-decals/vehicle-decals.reducers";

@NgModule({ declarations: [
        AppComponent,
        HeaderComponent,
        DecalsComponent,
        VehiclesComponent,
        NavbarComponent,
        PayrollDeductionComponent,
        ConfigurationComponent,
        VehicleColorComponent,
        VehicleMakeComponent,
        VehicleModelComponent,
        DecalColorComponent,
        DecalTypeComponent,
        DecalRestrictionComponent,
        AutoAssignComponent,
        RolloverComponent,
        TextConfigurationComponent
    ],
    bootstrap: [AppComponent], imports: [CKEditorModule,
        BrowserModule,
        CommonModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            closeButton: true,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
            includeTitleDuplicates: true,
            tapToDismiss: false,
        }),
        InjectorModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        OrderProcessModule,
        StoreModule.forRoot({}, {}), // this has to be before the next line
        !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production, connectInZone: true }) : [],
        EffectsModule.forRoot([GetUserNameEffect, GetCommuterStatusEffect, ImpersonateEffect, SetVehicleListEffect, GetVehicleListEffect, VehicleDecalsEffect]),
        StoreModule.forFeature('auth', authReducers),
        StoreModule.forFeature('vehicleDecals', vehicleDecalsReducers),
        MatDialogModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatProgressBarModule,
        MatTooltipModule,
        MatButtonModule], providers: [
        { provide: JsonPipe },
        { provide: HTTP_INTERCEPTORS, useClass: AuthService, multi: true },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }

