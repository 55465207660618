import { Component, OnInit, ErrorHandler, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, take, tap, Subject, takeUntil } from 'rxjs';
import { IDecalType } from 'src/app/core/models/decal-type.model';
import { ISelectedVehicleDecalPair } from 'src/app/core/models/selected-vehicle-decal-pair.model';
import { VehicleTypes } from 'src/app/core/models/vehicle-make.model';
import { IVehicle } from 'src/app/core/models/vehicle.model';
import { UserRepository } from 'src/app/core/repository/user.repository';
import { setDecalTypeAction, setDecalTypeSelectedAction } from '../store/order-decals.actions';
import { getBikesSelectedSelector, getCarsSelectedSelector, getMotorcyclesSelectedSelector, isDecalStepDataCompleteSelector, isCarTruckDecalSelectedSelector, isMotorcycleDecalSelectedSelector, isBikeDecalSelectedSelector, isDecalStepCompleteSelector } from '../store/order-decals.selectors';

@Component({
  selector: 'app-select-decals',
  templateUrl: './select-decals.component.html',
  styleUrls: ['./select-decals.component.scss']
})
export class SelectDecalsComponent implements OnInit, OnDestroy {
  public text = "PARK2";

  public carTruckList$: Observable<IVehicle[]>;
  public validCarTruckDecalTypes: IDecalType[];
  public selectedCarTruckDecal: IDecalType;

  public motorcycleList$: Observable<IVehicle[]>;
  public validMotorcycleDecalTypes: IDecalType[];
  public selectedMotorcycleDecal: IDecalType;

  public bikeList$: Observable<IVehicle[]>;
  public validBikeDecalTypes: IDecalType[];
  public selectedBikeDecal: IDecalType;

  public vehicleDecalPairs: ISelectedVehicleDecalPair[] = [];
  public selectedDecalTypes: IDecalType[] = [];

  public isEnabled: Observable<boolean>;

  public vehicleLoaded: boolean = false;

  public endSubscriptions$ = new Subject();

  constructor(private store: Store, private userRepo: UserRepository) { }

  ngOnInit(): void {
    this.getCarTruckList();
    this.getMotorcycleList();
    this.getBikeList();

    this.isEnabled = this.store.pipe(select(isDecalStepDataCompleteSelector));

     this.store.pipe(
      take(1),
      select(isCarTruckDecalSelectedSelector)
    ).subscribe(
      isSelected => {
        this.selectedCarTruckDecal = isSelected
      }
     );

     this.store.pipe(
      take(1),
      select(isMotorcycleDecalSelectedSelector)
    ).subscribe(
      isSelected => {
        this.selectedMotorcycleDecal = isSelected
      }
     );

     this.store.pipe(
      take(1),
      select(isBikeDecalSelectedSelector)
    ).subscribe(
      isSelected => {
        this.selectedBikeDecal = isSelected
      }
     );
  }

  public compare(a: IDecalType, b: IDecalType): boolean {
    return a && b ? a.id === b.id : a === b;
  }

  ngOnDestroy(): void {
    this.endSubscriptions$.next(true);
    this.endSubscriptions$.unsubscribe();
  }

  public getCarTruckList() {
    this.carTruckList$ = this.store.pipe(
      select(getCarsSelectedSelector)
    );

    this.store.pipe(
      takeUntil(this.endSubscriptions$),
      select(getCarsSelectedSelector))
       .subscribe(isSelected => {
        if(isSelected) {
          this.userRepo.getAvailableDecalTypes(VehicleTypes.CAR).subscribe(result => {
            this.validCarTruckDecalTypes = result;
            this.vehicleLoaded = true;
          });
        }
    });
  }

  public getMotorcycleList() {
    this.motorcycleList$ = this.store.pipe(
      select(getMotorcyclesSelectedSelector)
    );

    this.store.pipe(
      takeUntil(this.endSubscriptions$),
      select(getCarsSelectedSelector))
       .subscribe(isSelected => {
        if(isSelected) {
          this.userRepo.getAvailableDecalTypes(VehicleTypes.MOTORCYCLE).subscribe(result => {
            this.validMotorcycleDecalTypes = result;
            this.vehicleLoaded = true;
          });
        }
    });
  }

  public getBikeList() {
    this.bikeList$ = this.store.pipe(
      select(getBikesSelectedSelector)
    );

    this.store.pipe(
      takeUntil(this.endSubscriptions$),
      select(getCarsSelectedSelector))
       .subscribe(isSelected => {
        if(isSelected) {
          this.userRepo.getAvailableDecalTypes(VehicleTypes.BIKE).subscribe(result => {
            this.validBikeDecalTypes = result;
            this.vehicleLoaded = true;
          });
        }
    });
  }

  public confirmChosenDecals() {
    this.store.dispatch(setDecalTypeAction());
  }

  private setPairs(vehicleList: Observable<IVehicle[]>, selectedDecal: IDecalType) {
    this.selectedDecalTypes.push(selectedDecal);
    vehicleList.pipe(take(1)).subscribe(vehicleList => {
      this.vehicleDecalPairs.push({ decalType: selectedDecal, vehicleList: vehicleList })
    });
  }

  public toggleSelection() {
     //clear the lists incase the user comes back to this step again
     this.selectedDecalTypes = [];
     this.vehicleDecalPairs = [];

     if (this.selectedCarTruckDecal) {
       this.setPairs(this.carTruckList$, this.selectedCarTruckDecal);
     }

     if (this.selectedMotorcycleDecal) {
       this.setPairs(this.motorcycleList$, this.selectedMotorcycleDecal);
     }

     if (this.selectedBikeDecal) {
       this.setPairs(this.bikeList$, this.selectedBikeDecal);
     }

     if (this.selectedDecalTypes.length && this.vehicleDecalPairs.length) {
      this.store.dispatch(setDecalTypeSelectedAction({ decalType: this.selectedDecalTypes, pairs: this.vehicleDecalPairs }));
    }
  }

}
