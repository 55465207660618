import {IAuthState} from "./auth.state";
import {Action, createReducer, on} from "@ngrx/store";
import {
  endImpersonateUserSuccessAction,
  getCommuterStatusAction,
  getCommuterStatusFailureAction,
  getCommuterStatusSuccessAction,
  getEmployeeStatusFailureAction,
  getEmployeeStatusSuccessAction,
  getUserNameAction,
  getUserNameFailureAction,
  getUserNameSuccessAction,
  getUserRolesAction,
  getUserRolesFailureAction,
  getUserRolesSuccessAction,
  impersonateUserSuccessAction
} from "./auth.actions";

export const initialAuthState: IAuthState = {
  name: undefined,
  isLoadingUser: false,
  isLoadingRoles: false,
  roles: undefined,
  error: undefined,
  isCommuter: undefined,
  isLoadingCommuter: false,
  isEmployee: undefined,
  isLoadingEmployee: false,
};

const authReducer = createReducer(
  initialAuthState,

  /*
  This checks to see if the name property has already been loaded into the state.
  If it has, then we just return the current state. We know that the effect on this action won't try to load anything
  from the API, so we don't want our state to show that it's loading anything.
  Otherwise, we'll set the loading indicator because we're going to have to load it, and then either the Success or
  Failure will reset it once it is finished.
   */
  on(getUserNameAction,
    (state): IAuthState => {
      return !!state.name ? state : ({
        ...state,
        isLoadingUser: true
      })
    }
  ),

  on(getUserNameSuccessAction,
    impersonateUserSuccessAction,
    endImpersonateUserSuccessAction,
    (state, action): IAuthState => ({
      ...state,
      isLoadingUser: false,
      name: action.data,
      roles: undefined,
      isLoadingRoles: true,
      isEmployee: undefined,
      isLoadingEmployee: true,
    })
  ),

  on(getUserNameFailureAction,
    (state, action): IAuthState => ({
      ...state,
      isLoadingUser: false,
      error: action.error.error
    })
  ),

  on(getUserRolesAction,
    (state): IAuthState => {
      return !!state.roles ? state : ({
        ...state,
        isLoadingRoles: true
      })
    }
  ),

  on(getUserRolesSuccessAction,
    (state, action): IAuthState => ({
      ...state,
      isLoadingRoles: false,
      roles: action.data
    })
  ),

  on(getUserRolesFailureAction,
    (state, action): IAuthState => ({
      ...state,
      isLoadingRoles: false,
      error: action.error.error
    })
  ),

  on(getCommuterStatusAction,
    (state): IAuthState => ({
      ...state,
        isLoadingCommuter: true
    })
  ),

  on(getCommuterStatusSuccessAction,
    (state, action): IAuthState => ({
      ...state,
      isLoadingCommuter: false,
      isCommuter: action.isCommuter
    })
  ),

  on(getCommuterStatusFailureAction,
    (state, action): IAuthState => ({
      ...state,
      isLoadingCommuter: false,
      error: action.error.error
    })
  ),

  on(getEmployeeStatusSuccessAction,
    (state, action): IAuthState => ({
      ...state,
      isLoadingEmployee: false,
      isEmployee: action.isEmployee
    })
  ),

  on(getEmployeeStatusFailureAction,
    (state, action): IAuthState => ({
      ...state,
      isLoadingEmployee: false,
      error: action.error.error
    })
  ),
);

export function authReducers(state: IAuthState, action: Action) {
  return authReducer(state, action);
}
