import { Component, OnInit } from '@angular/core';
import { select, Store } from "@ngrx/store";
import { UserRepository } from "../../../core/repository/user.repository";
import { Observable, take } from 'rxjs';
import { setAgreementInfoAction, setGeneralContractAgreedAction, setPayrollContractAgreedAction, setEmergencyPhoneAction } from '../store/order-decals.actions';
import { isEmployeeSelector } from "../../../core/store/auth/auth.selectors";
import { ITextBlock } from "../../../core/models/textblock.model";
import { TextblockRepository } from "../../../core/repository/textblock.repository";
import { getTotalDecalCost, getVehicleDecalPairsSelector, isAgreementStepCompleteSelector, isSubmittingSelector, isAgreementStepDataCompleteSelector } from '../store/order-decals.selectors';
import { ISelectedVehicleDecalPair } from 'src/app/core/models/selected-vehicle-decal-pair.model';
import { Validators, UntypedFormBuilder} from '@angular/forms';

@Component({
  selector: 'app-agreements',
  templateUrl: './agreements.component.html',
  styleUrls: ['./agreements.component.scss']
})
export class AgreementsComponent implements OnInit {

  phoneValue: string;
  isEmployee$: Observable<boolean>;
  generalAgreement$: Observable<ITextBlock>;
  payrollAgreement$: Observable<ITextBlock>;
  isSubmitting$: Observable<boolean>;
  agreedGeneral = false;
  agreedPayroll = false;

  public regex = /^[ \.\+\(\-\)xX]*(?:\d[ \.\+\(\-\)xX]*){10,}$/;
  readonly re = /^[(]?([0-9]{3})[)]?[\-]?[\.]?[ ]?([0-9]{3})[\-]?[\.]?[ ]?([0-9]{4})$|^[0-9 \+]{10,16}$/;

  public phoneForm = this.formBuilder.group({
    phone: ['', [Validators.required, Validators.pattern(this.re)]]
  })

  isEnabled: Observable<boolean>;

  vehicleDecalPairs$: Observable<ISelectedVehicleDecalPair[]>;
  areAgreementsComplete$: Observable<boolean>;
  totalDecalcost$: Observable<number>;

  constructor(private store: Store,
    private userRepo: UserRepository,
    private textRepo: TextblockRepository,
    private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.userRepo.getEmergencyPhone().pipe(
      take(1),
    ).subscribe(value => {
      this.phoneValue = value?.phone || '';
      this.store.dispatch(setEmergencyPhoneAction({ phone: value?.phone }));
      if (this.phoneValue) {
        this.phoneForm.setValue({
          phone: this.phoneValue,
        });
      } else {
        this.phoneForm.setValue({
          phone: '',
        });
      }
    });

    this.isSubmitting$ = this.store.pipe(select(isSubmittingSelector));

    this.vehicleDecalPairs$ = this.store.pipe(select(getVehicleDecalPairsSelector));

    this.areAgreementsComplete$ = this.store.pipe(select(isAgreementStepCompleteSelector));

    this.totalDecalcost$ = this.store.pipe(select(getTotalDecalCost));

    this.loadAgreements();

    this.isEnabled = this.store.pipe(select(isAgreementStepDataCompleteSelector));
  }

  get form(){
    return this.phoneForm.controls;
  }

  loadAgreements(): void {
    this.generalAgreement$ = this.textRepo.getTextblock('PARKIN');
    this.payrollAgreement$ = this.textRepo.getTextblock('PAYROL');
    this.isEmployee$ = this.store.pipe(select(isEmployeeSelector));
  }

  public togglePhone($event: any) {
    let phoneString = $event.target.value as string;

    if (this.re.test(phoneString)) {
      this.store.dispatch(setEmergencyPhoneAction({ phone: $event.target.value }))
    } else {
      this.store.dispatch(setEmergencyPhoneAction({ phone: "" }))
    }
  }

  public formatPhoneNo(field: any) {
    let phoneNumDigits = field.value.replace(/\D/g, '');
    let formattedNumber = phoneNumDigits;

    if (phoneNumDigits.length === 0) {
      phoneNumDigits = '';
    } else if (phoneNumDigits.length <= 3) {
      formattedNumber = phoneNumDigits.replace(/^(\d{0,3})/, '$1');
    } else if (phoneNumDigits.length <= 6) {
      formattedNumber = phoneNumDigits.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
    } else if (phoneNumDigits.length <= 10) {
      formattedNumber = phoneNumDigits.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    } else {
      formattedNumber = phoneNumDigits.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1$2$3');
    }
   field.value = formattedNumber;
  }

  public toggleGeneral($event: any) {
    this.store.dispatch(setGeneralContractAgreedAction({ hasAgreed: $event.target.checked }));
  }

  public togglePayroll($event: any) {
    this.store.dispatch(setPayrollContractAgreedAction({ hasAgreed: $event.target.checked }));
  }

  checkInput(): void {
    this.store.dispatch(setAgreementInfoAction());
  }

  openDecalMap(url: string) {
    window.open(url, "_blank")
  }
}
