import { Injectable } from "@angular/core";
import { AutoAssignRepository } from "../repository/auto-assign.repository";
import { IDecalType } from 'src/app/core/models/decal-type.model';
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class AutoAssignService{

  constructor(private autoAssignRepo: AutoAssignRepository) {}

  public autoAssignDecals(decalList: IDecalType[]): Observable<Response> {
    return this.autoAssignRepo.autoAssignDecals(decalList);
  }

}
