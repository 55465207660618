import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { IDecalRestriction } from '../../../core/models/decal-restriction.model';
import { IRestrictionType } from '../../../core/models/restriction-type.model';
import { IDecalType } from '../../../core/models/decal-type.model';
import { DecalTypeService } from '../../../core/service/decal-type.service';
import { DecalRestrictionService } from '../../../core/service/decal-restriction.service';
import { UntypedFormBuilder, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-decal-restriction',
  templateUrl: './decal-restriction.component.html',
  styleUrls: ['./decal-restriction.component.scss']
})

export class DecalRestrictionComponent implements OnInit {
  public decalTypes: IDecalType[] = [];
  public decalRestrictionTypes: IDecalRestriction[] = [];
  public restrictionTypes: IRestrictionType[] = [];
  public stagedDecalRestriction: IDecalRestriction | undefined;
  public isModalOpen: boolean = false;
  public modalTitle: string = '';
  public restrictionTypeForm = this.formBuilder.group({
    restrictionType: {
      id: '',
      description: ''
    }
  });

  public decalType!: IDecalType;

constructor(private decalRestrictionService: DecalRestrictionService, private decalTypeService: DecalTypeService, private formBuilder: UntypedFormBuilder, private route: ActivatedRoute
) { }

  ngOnInit(): void {
   this.getRestrictionTypes();
   this.initRestrictions();
  }

  public get restrictionType(): IRestrictionType {
    return this.restrictionTypeForm.get('restrictionType')?.value;
  }

  public changeRestrictionType(rtype: IRestrictionType) {
    this.restrictionTypeForm.get('restrictionTypeForm')?.setValue(rtype);
  }

  public getRestrictionTypes(): void {
    this.decalRestrictionService.getRestrictionTypes().subscribe((types: IRestrictionType[]) => {
      this.restrictionTypes = types;
    });
  }

  public getDecalType(decalId: number): void {
    this.decalTypeService.getDecalType(decalId).subscribe((decalType: IDecalType) => {
      this.decalType = decalType;
    });
  }

  public getDecalRestriction(decalId: number): void {
    this.decalRestrictionService.getDecalRestriction(decalId).subscribe((decalRestrictionTypes: IDecalRestriction[]) => {
      this.decalRestrictionTypes = decalRestrictionTypes;
      this.restrictionTypeForm.setValue({
       restrictionType: this.restrictionTypes[0]
      });
    });
  }

  public submitForm(): void {
     if (this.stagedDecalRestriction) {
        this.decalRestrictionService.updateRestriction({
          ...this.stagedDecalRestriction,
          decalType: this.decalType,
          restrictionType: this.restrictionType
        }).subscribe((updatedRestriction: IDecalRestriction) => {
          this.decalRestrictionTypes = this.decalRestrictionTypes.map((decalRestriction: IDecalRestriction) => decalRestriction.id === updatedRestriction.id ? updatedRestriction : decalRestriction);
          this.closeModal();
        })
     } else {
       let newRestrictionType: IDecalRestriction = {
        id: 0,
        decalType: this.decalType,
        restrictionType: this.restrictionType
       }
       this.decalRestrictionService.addRestriction(newRestrictionType).subscribe((decalRestriction: IDecalRestriction) => {
        this.decalRestrictionTypes.push(decalRestriction);
        this.closeModal();
       });
     }
  }

  public deleteRestriction(decalRestriction: IDecalRestriction) {
    this.decalRestrictionService.deleteRestriction(decalRestriction).subscribe(() => {
      this.initRestrictions();
    });
  }

  public openModal(decalRestrictionType: IDecalRestriction | undefined, modalTitle: string) {
    this.isModalOpen = true;
    if (decalRestrictionType !== undefined) {
      this.stagedDecalRestriction = decalRestrictionType;

      let indexOfRestrictionType = this.restrictionTypes.findIndex(rtypes => rtypes.id === decalRestrictionType.restrictionType.id);

      this.restrictionTypeForm.setValue({
       restrictionType: this.restrictionTypes[indexOfRestrictionType]
      });
    }
  }

  public closeModal() {
    this.stagedDecalRestriction = undefined;
    this.isModalOpen = false;
    this.restrictionTypeForm.get('restrictionType')?.setValue(this.restrictionTypes[0]);
  }

  public initRestrictions(): void {
    this.route.params.forEach((params: Params) => {
      let decalTypeId = params['id'];
      this.getDecalRestriction(decalTypeId);
      this.getDecalType(decalTypeId);
    });
  }
}
