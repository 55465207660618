import {IVehicleDecalsState} from "./vehicle-decals.state";
import {Action, createReducer, on} from "@ngrx/store";
import {
  clearVehicleDecalsAction,
  getVehicleDecalsAction,
  getVehicleDecalsFailureAction,
  getVehicleDecalsSuccessAction
} from "./vehicle-decals.actions";

export const initialVehicleDecalsState: IVehicleDecalsState = {
  vehicleDecals: undefined,
  isLoading: false
};

const vehicleDecalsReducer = createReducer(
  initialVehicleDecalsState,

  on(getVehicleDecalsAction,
    (state): IVehicleDecalsState => {
      return !!state.vehicleDecals ? state : ({
        ...state,
        isLoading: true
      })
    }
  ),

  on(getVehicleDecalsSuccessAction,
    (state, action) : IVehicleDecalsState => ({
      ...state,
      isLoading: false,
      vehicleDecals: action.data
    })
  ),

  on(getVehicleDecalsFailureAction,
    (state) : IVehicleDecalsState => ({
      ...state,
      isLoading: false
    })
  ),

  on(clearVehicleDecalsAction,
    () : IVehicleDecalsState => ({
      ...initialVehicleDecalsState
    })
  ),
);

export function vehicleDecalsReducers(state: IVehicleDecalsState, action: Action) {
  return vehicleDecalsReducer(state, action);
}
