import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IStateCode } from "../models/statecode.model";
import { IVehicle } from "../models/vehicle.model";
import { VehicleRepository } from "../repository/vehicle.repository";

@Injectable({
    providedIn: 'root'
})
export class VehicleService {

  constructor(private vehicleRepo: VehicleRepository) { }

  public getVehicles(): Observable<IVehicle[]> {
    return this.vehicleRepo.getVehicles();
  }

  public getStateCodes(): Observable<IStateCode[]> {
    return this.vehicleRepo.getStateCodes();
  }

  public saveVehicle(vehicle: IVehicle): Observable<IVehicle> {
    return this.vehicleRepo.saveVehicle(vehicle);
  }

  public updateVehicle(vehicle: IVehicle): Observable<IVehicle> {
    return this.vehicleRepo.updateVehicle(vehicle);
  }

  public deleteVehicle(vehicleId: number): Observable<{}> {
    return this.vehicleRepo.deleteVehicle(vehicleId);
  }
}
