import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { Observable, debounceTime, tap } from "rxjs";

@Component({
    selector: 'app-loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class LoadingSpinner implements OnInit {

    @Input()
    public givenFlag$: Observable<boolean>;

    public showModal: boolean = false;

    constructor() { }
    ngOnInit(): void {
        this.givenFlag$.pipe(
            debounceTime(250),
            tap(flag => {
                this.setShowModal(flag)
            })
        ).subscribe()
    }

    public setShowModal(flag: boolean): void {
        this.showModal = flag
    }

}