import { Component, OnInit, Output } from '@angular/core';
import {IVehicleMake, IVehicleType} from "../../../core/models/vehicle-make.model";
import {VehicleMakeService} from "../../../core/service/vehicle-make.service";
import {AbstractControl, FormControl, UntypedFormBuilder} from "@angular/forms";

@Component({
  selector: 'app-vehicle-make',
  templateUrl: './vehicle-make.component.html',
  styleUrls: ['./vehicle-make.component.scss']
})
export class VehicleMakeComponent implements OnInit {
  public vehicleMakes: IVehicleMake[] = [];
  public vehicleTypes: IVehicleType[] = [];
  public stagedMake: IVehicleMake | undefined;
  public isModalOpen: boolean = false;

  public makeForm = this.formBuilder.group({
    vehicleMake: '',
    vehicleType: ''
  });

  constructor(private vehicleMakeService: VehicleMakeService, private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.getVehicleMakes();
    this.getVehicleTypes();
  }

  public get vehicleMake(): any {
    return this.makeForm.get('vehicleMake');
  }

  public get vehicleType(): any {
    return this.makeForm.get('vehicleType');
  }

  public changeVehicleType(vt: any) {
    this.vehicleType.setValue(vt.value);
  }

  /**
   * Get list of vehicle makes for staff configuration.
   * @private
   */
  public getVehicleMakes(): void {
    this.vehicleMakeService.getVehicleMakes().subscribe((makes: IVehicleMake[]) => {
      this.vehicleMakes = this.sortVehicleMakesByType(makes);
    });
  }

  /**
   * Get list of vehicle types for staff configuration.
   * @private
   */
  public getVehicleTypes(): void {
    this.vehicleMakeService.getVehicleTypes().subscribe((vehicleTypes: IVehicleType[]) => {
      this.vehicleTypes = vehicleTypes;
      this.makeForm.setValue({vehicleType: this.vehicleTypes[0], vehicleMake: ''});
    });
  }

  /**
   * Opens a modal for creating and editing vehicle makes for submission.
   * @param make
   */
  public openModal(make: IVehicleMake | undefined) {
    this.isModalOpen = true;
    if (make !== undefined) {
      this.stagedMake = make;
      let indexOfVehicleType = this.vehicleTypes.findIndex(vt => vt.id === make.vehicleType.id)
      this.makeForm.setValue({vehicleMake: make.text, vehicleType: this.vehicleTypes[indexOfVehicleType]});
    }
  }

  /**
   * Close the modal if there is one open in the view.
   */
  public closeModal() {
      this.stagedMake = undefined;
      this.isModalOpen = false;
      this.makeForm.reset();
  }

  /**
   * Submit a form with the staged make if there is one.
   */
  public submitForm(): void {
    if (this.stagedMake) {
      this.vehicleMakeService.update({...this.stagedMake, text: this.vehicleMake.value, vehicleType: this.vehicleType.value})
        .subscribe((updatedMake: IVehicleMake) => {
          this.vehicleMakes = this.vehicleMakes.map((make: IVehicleMake) => make.id === updatedMake.id ? updatedMake : make);
          this.closeModal();
      });
    } else {

      let newMake: IVehicleMake = {
        id: null,
        vehicleType: this.vehicleType.value,
        text: this.vehicleMake.value,
      };

      this.vehicleMakeService.add(newMake).subscribe((make: IVehicleMake) => {
        this.vehicleMakes.push(make);
        this.closeModal();
      })
    }
  }

  /**
   * Sort a list of vehicle makes by their attached vehicle type.
   * @param vehicleMakes
   * @private
   */
  private sortVehicleMakesByType(vehicleMakes: IVehicleMake[]): IVehicleMake[] {
    return vehicleMakes.sort((a, b) => {
      if (a.vehicleType.text && b.vehicleType.text) {
        return a.vehicleType.text.localeCompare(b.vehicleType.text);
      } else {
        return 0;
      }
    });
  }
}
