<div>

  <div data-cy="failure-text" *ngIf="sucessfulDecals?.length == 0 && failedDecals?.length == 0">
    <p class="failure-text">Order could not be processed. Duplicate request already exists.</p>
  </div>

  <div *ngIf="sucessfulDecals?.length">

    <div class="table-holder">
      <div class="blank-cell">Item/Description</div>
      <div class="header-text">Price</div>
    </div>

    <hr>
    <div *ngFor="let pair of sucessfulDecals">
      <div class="table-holder">
        <div class="header-decal">
          <app-decal-type-icon [decalColor]="pair.decalType.decalColor.code" [decalCode]="pair.decalType.code" [decalDescription]="pair.decalType.description"></app-decal-type-icon>
          {{pair.decalType.description}}
        </div>
        <div class="header-item">
          <ng-template [ngIf]="pair.decalType.imageUrl.slice(0,pair.decalType.imageUrl.lastIndexOf('.'))" [ngIfElse]="blankSpace">
            <button (click)="openDecalMap(pair.decalType.imageUrl)" class="link-button">View Map</button>
          </ng-template>
          <ng-template #blankSpace></ng-template>
        </div>
        <div class="header-item">{{pair.decalType.description}} - ${{pair.decalType.price}}</div>
      </div>

      <div class="tableWrapper">
        <table class="table table-responsive table-responsive-sm">
          <tbody>
          <tr *ngFor=" let vehicle of pair.vehicleList" class="vehicle-data">
            <td class="list-vehicle-data">{{vehicle.make.text}} {{vehicle.model.text}}</td>
            <td class="list-decal-valid">
              <img class="filter-green" id="valid-icon" src="assets/valid icon.svg" alt="Edit">
              <span class="valid-text">
                  Valid
              </span>
            </td>
            <td class="list-year-data">{{vehicle.vehicleYear}}</td>
            <td class="list-license-data">{{vehicle.licenseNumber}}</td>
            <td class="list-color-data">{{vehicle.color.text}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <hr>
    </div>

    <div class="table-holder">
      <div class="blank-cell"></div>
      <div class="header-text">Total - ${{successfulDecalCost}}</div>
    </div>
  </div>

  <hr>

  <div *ngIf="failedDecals?.length">
    <div class="failure-text">The following decals could not be registered:</div>

    <div *ngFor="let pair of failedDecals">
      <div class="table-holder">
        <div class="header-decal">
          <app-decal-type-icon [decalColor]="pair.decalType.decalColor.code" [decalCode]="pair.decalType.code" [decalDescription]="pair.decalType.description"></app-decal-type-icon>
          {{pair.decalType.description}}
        </div>
      </div>

      <div *ngFor="let vehicle of pair.vehicleList" class="vehicle-data">
        <div class="list-vehicle-data">{{vehicle.make.text}} {{vehicle.model.text}}</div>
        <div class="list-year-data">{{vehicle.vehicleYear}}</div>
        <div class="list-license-data">{{vehicle.licenseNumber}}</div>
        <div class="list-color-data">{{vehicle.color.text}}</div>
      </div>
    </div>
    <div class="failure-text">Please contact LUPD for assistance.</div>
    <hr>
  </div>

  <div>
    <h2><b>Thank You</b></h2>
    <p>If you need to alter a request, or have any other questions regarding your decal requests or current decal,
      please contact LUPD by email at <a [href]="lupdEmail">luparking&#64;liberty.edu</a>
      or by phone at 434-592-3697.</p>
  </div>

  <div class="bottom-container">
    <div class="nav-button-container">
      <button data-cy="finishedButton" class="nextButton" (click)="redirectToDecals()">Finished</button>
      <button data-cy="printReceiptButton" class="printButton" (click)="printReciept()">Print</button>
    </div>
  </div>
</div>
<!-- END OF UI PAGE -->

<!-- RECIEPT SECTION -->
<div id="reciept" class="reciept">
  <div style="display: flex; border-bottom: 3px; ">
    <span style="width: 80%; font-weight: bold; font-size: large;">Liberty University</span>
    <span style="font-weight: bold; font-size: large;"><i>Parking Decal</i></span>
  </div>
  <hr>

  <h2 style="margin-top: 20px; padding: 0px 10px;"><b>Receipt - Decal Order</b></h2>
  <h3 style="padding: 0px 10px;">{{user$ | async}} - {{luid}}</h3>
  <hr style="padding: 0px 10px;">

  <div style="display: flex; margin-top: 20px; padding: 0px 10px;">
    <div style="width: 85%; font-size: large; font-weight: bold;">Item/Description</div>
    <div style="font-size: large; font-weight: bold;">Price</div>
  </div>

  <hr style="padding: 0px 10px;">

  <div *ngFor="let pair of sucessfulDecals" style="padding: 0px 10px;">
    <div style="display: flex;">
      <div style="width: 85%;">{{pair.decalType.code}} - {{pair.decalType.description}}</div>
      <div style="padding: 4px 0px;">${{pair.decalType.price}}.00</div>
    </div>

    <div *ngFor=" let vehicle of pair.vehicleList" style="display: flex;">
      <div style="width: 20%; font-weight: bold;">{{vehicle.make.text}} {{vehicle.model.text}}</div>
      <div style="width: 15%;">{{vehicle.vehicleYear}}</div>
      <div style="width: 15%;">{{vehicle.licenseNumber}}</div>
      <div style="width: 15%;">{{vehicle.color.text}}</div>
    </div>
    <hr>
  </div>

  <div style="display: flex; padding: 0px 10px;">
    <div style="width: 85%; font-size: large; font-weight: bold;">Total</div>
    <div style="font-size: large; font-weight: bold;">${{successfulDecalCost}}.00</div>
  </div>

  <hr style="padding: 0px 10px;">

  <div style="padding: 0px 10px;">
    <h2 data-cy="receipt-title"><b>Thank You</b></h2>
    <p data-cy="receipt-message">If you need to alter a request,
      or have any other questions regarding your decal requests or current decal, please contact LUPD by email at <a [href]="lupdEmail">luparking&#64;liberty.edu</a>
      or by phone at 434-592-7641.</p>
  </div>

</div>
