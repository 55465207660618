import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {ObjectUnsubscribedError, Observable} from "rxjs";
import {IVehicleColor} from "../models/vehicle-color.model";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class VehicleColorRepository {

  constructor(private http: HttpClient) { }

  public getVehicleColors(): Observable<IVehicleColor[]> {
    return this.http.get<IVehicleColor[]>(`${environment.apiUrl}/vehicle-colors`);
  }

  public add(color: string): Observable<IVehicleColor> {
      return this.http.post<IVehicleColor>(`${environment.apiUrl}/vehicle-colors`, {text: color});
    }

  public update(color: IVehicleColor): Observable<IVehicleColor> {
     return this.http.put<IVehicleColor>(`${environment.apiUrl}/vehicle-colors/${color.id}`, color);
  }
}
