import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

const BASE_URL: string = `${environment.apiUrl}/hello`

@Injectable({ providedIn: 'root' })
export class HelloRepository {

  constructor(private http: HttpClient){}

/**
 * Takes the pidm of the user as input and returns a short greeting message to the student if that
 * student is found.
 *
 * @param pidm
 * @returns {Observable<string>}
 * @memberof HelloRepository
 */
  public getHello(pidm: number): Observable<string> {
    return this.http.get(`${BASE_URL}/person?pidm=${pidm}`, {responseType: 'text'});
  }

  public getUser(): Observable<any> {
    return this.http.get(`${BASE_URL}/user`, {responseType: 'text'});
  }
}
