import { createFeatureSelector, createSelector } from "@ngrx/store";
import { IOrderDecalsState } from "./order-decals.state";
import { isEmployeeSelector } from "../../../core/store/auth/auth.selectors";
import { VehicleTypes } from "src/app/core/models/vehicle-make.model";
import { IVehicle } from "src/app/core/models/vehicle.model";

export const orderDecalsFeatureSelector = createFeatureSelector<IOrderDecalsState>('order-decals');

export const getStepNumberSelector = createSelector(
  orderDecalsFeatureSelector,
  state => state.stepNumber
);

export const getDecalTypesSelector = createSelector(
  orderDecalsFeatureSelector,
  state => state.decalTypes
);

export const getVehicleListSelector = createSelector(
  orderDecalsFeatureSelector,
  state => state.vehicleList
);

export const getEmergencyPhone = createSelector(
  orderDecalsFeatureSelector,
  state => state.emergencyPhone
);

export const isCarSelectedSelector = createSelector(
  orderDecalsFeatureSelector,
  state => state.carsSelected
);

export const isMotorcycleSelectedSelector = createSelector(
  orderDecalsFeatureSelector,
  state => state.motorcyclesSelected
);

export const isBikeSelectedSelector = createSelector(
  orderDecalsFeatureSelector,
  state => state.bikesSelected
);

export const isStep1DisabledSelector = createSelector(
  orderDecalsFeatureSelector,
  state => state.isStep1Disabled
);

export const isVehicleStepDataCompleteSelector = createSelector(
  orderDecalsFeatureSelector,
  state => state.carsSelected || state.motorcyclesSelected || state.bikesSelected
);

export const isVehicleStepCompleteSelector = createSelector(
  orderDecalsFeatureSelector,
  state => state.isVehicleStepComplete
);

export const isDecalStepDataCompleteSelector = createSelector(
  orderDecalsFeatureSelector,
  state => (!!state.decalTypes && !!state.decalTypes.length)
    && (!!state.vehicleDecalPairs && !!state.vehicleDecalPairs.length)
);

export const isDecalStepCompleteSelector = createSelector(
  orderDecalsFeatureSelector,
  state => state.isDecalStepComplete
);

export const isPayrollCompletedSelector = createSelector(
  orderDecalsFeatureSelector,
  isEmployeeSelector,
  (orderState, isEmployee) => {
    return !isEmployee || (isEmployee && orderState.payrollContractAgreed)
  }
);

export const isAgreementStepDataCompleteSelector = createSelector(
  orderDecalsFeatureSelector,
  isPayrollCompletedSelector,
  (orderState, payrollComplete) => {
    return !!orderState.emergencyPhone && orderState.generalContractAgreed && payrollComplete;
  }
);

export const isAgreementStepCompleteSelector = createSelector(
  orderDecalsFeatureSelector,
  isEmployeeSelector,
  (orderState, isEmployee) => {
    return orderState.phoneSubmitted
      && orderState.generalAgreementSubmitted
      && (!isEmployee || orderState.payrollAgreementSubmitted)
      && orderState.decalsSubmitted;
  }
);

export const getDecalSubmittedSelector = createSelector(
  orderDecalsFeatureSelector,
  state => state.decalsSubmitted
)

export const isSubmittingSelector = createSelector(
  orderDecalsFeatureSelector,
  state => state.isDecalsSubmitting
    || state.isPhoneSubmitting
    || state.isGeneralAgreementSubmitting
    || state.isPayrollAgreementSubmitting
)

export const getCarListSelector = createSelector(
  getVehicleListSelector,
  vehicleList => {
    return vehicleList.filter(vehicle => vehicle.vehicleType.id == VehicleTypes.CAR && vehicle.active == 'Y')
  }
)

export const getMotorcycleListSelector = createSelector(
  getVehicleListSelector,
  vehicleList => {
    return vehicleList.filter(vehicle => vehicle.vehicleType.id == VehicleTypes.MOTORCYCLE && vehicle.active == 'Y')
  }
)

export const getBikeListSelector = createSelector(
  getVehicleListSelector,
  vehicleList => {
    return vehicleList.filter(vehicle =>
      (vehicle.vehicleType.id == VehicleTypes.BIKE || vehicle.vehicleType.id == VehicleTypes.MOPED_50CC_OR_GREATER ||
        vehicle.vehicleType.id == VehicleTypes.MOPED_LESS_THAN_50CC || vehicle.vehicleType.id == VehicleTypes.ELECTRIC_SCOOTER)
      && vehicle.active == 'Y')
  }
)

export const getSelectedVehiclesSelector = createSelector(
  orderDecalsFeatureSelector,
  getBikeListSelector,
  getCarListSelector,
  getMotorcycleListSelector,
  (state, bikes, cars, motorcycles) => {
    let filteredVehicles: IVehicle[] = [];
    if (state.carsSelected) {
      filteredVehicles = filteredVehicles.concat(cars);
    }
    if (state.motorcyclesSelected) {
      filteredVehicles = filteredVehicles.concat(motorcycles)
    }
    if (state.bikesSelected) {
      filteredVehicles = filteredVehicles.concat(bikes);
    }

    return filteredVehicles;
  }
)

export const getCarsSelectedSelector = createSelector(
  orderDecalsFeatureSelector,
  getCarListSelector,
  (state, cars) => {
    let selectedCars: IVehicle[] = []
    if (state.carsSelected) {
      selectedCars = cars;
    }
    return selectedCars;
  }
)

export const getMotorcyclesSelectedSelector = createSelector(
  orderDecalsFeatureSelector,
  getMotorcycleListSelector,
  (state, motorcycles) => {
    let selectedMotorcycles: IVehicle[] = []
    if (state.motorcyclesSelected) {
      selectedMotorcycles = motorcycles;
    }
    return selectedMotorcycles;
  }
)

export const getBikesSelectedSelector = createSelector(
  orderDecalsFeatureSelector,
  getBikeListSelector,
  (state, bikes) => {
    let selectedBikes: IVehicle[] = []
    if (state.bikesSelected) {
      selectedBikes = bikes;
    }
    return selectedBikes;
  }
)

export const getVehicleDecalPairsSelector = createSelector(
  orderDecalsFeatureSelector,
  state => state.vehicleDecalPairs
)

export const getDecalSubmissionResultsSelector = createSelector(
  orderDecalsFeatureSelector,
  state => state.decalSubmissionResults
)

export const getTotalDecalCost = createSelector(
  getDecalTypesSelector,
  (decalType) => {
    let sum: number = 0;
    decalType.forEach(decal => sum += decal.price)
    return sum;
  }
)

export const getTotalSucessFulDecalCostSelector = createSelector(
  getDecalSubmissionResultsSelector,
  (decals) => {
    let sum: number = 0;
    decals.forEach(decal => sum += decal.decalType.price)
    return sum;
  }
)

export const getFailedDecalRequestsSelector = createSelector(
  getVehicleDecalPairsSelector,
  getDecalSubmissionResultsSelector,
  (requested, registered) => {
    return requested.filter((decal) => registered.includes(decal));
  }
)

export const getPayrollContractAgreedSelector = createSelector(
  orderDecalsFeatureSelector,
  state => state.payrollContractAgreed
);

export const areAllStepsCompleteSelector = createSelector(
  isVehicleStepCompleteSelector,
  isDecalStepCompleteSelector,
  isAgreementStepCompleteSelector,
  (vehicleStepComplete, decalStepComplete, agreementStepComplete) => {
    return vehicleStepComplete && decalStepComplete && agreementStepComplete
  }
)

export const isCarTruckDecalSelectedSelector = createSelector(
 getVehicleDecalPairsSelector,

  pairs => {
    let vehiclePair = pairs.filter(pair => pair.vehicleList[0]?.vehicleType.id == VehicleTypes.CAR)
    return vehiclePair[0]?.decalType
  }
);

export const isMotorcycleDecalSelectedSelector = createSelector(
 getVehicleDecalPairsSelector,

  pairs => {
    let vehiclePair = pairs.filter(pair => pair.vehicleList[0]?.vehicleType.id == VehicleTypes.MOTORCYCLE)
    return vehiclePair[0]?.decalType
  }
);

export const isBikeDecalSelectedSelector = createSelector(
 getVehicleDecalPairsSelector,

  pairs => {
    let vehiclePair = pairs.filter(pair => pair.vehicleList[0]?.vehicleType.id == VehicleTypes.BIKE)
    return vehiclePair[0]?.decalType
  }
);

  // EXAMPLE: parameterized selector
  // export const getSelectedVehiclesSelector = (hasCars: boolean, hasMotorcycles: boolean, hasBikes: boolean) => createSelector(
  //   getBikeListSelector,
  //   getCarListSelector,
  //   getMotorcycleListSelector,
  //   (bikes, cars, motorcycles) => {
  //     let filteredVehicles: IVehicle[] = [];
  //     if (hasCars) {
  //       filteredVehicles = filteredVehicles.concat(cars);
  //     }
  //     if (hasMotorcycles) {
  //       filteredVehicles = filteredVehicles.concat(motorcycles)
  //     }
  //     if (hasBikes) {
  //       filteredVehicles = filteredVehicles.concat(bikes);
  //     }

  //     return filteredVehicles;
  //   }
