import { Action, createReducer, on } from "@ngrx/store";
import { IOrderDecalsState } from "./order-decals.state";
import {
  resetOrderProcessAction,
  setDecalTypeAction,
  setStepNumberAction,
  setVehicleListAction, submitDecalsAction, submitDecalsActionFailure, submitDecalsActionSuccess,
  submitGeneralAgreementAction,
  submitGeneralAgreementActionFailure,
  submitGeneralAgreementActionSuccess,
  submitPayrollAgreementAction, submitPayrollAgreementActionFailure, submitPayrollAgreementActionSuccess,
  submitPhoneAction,
  submitPhoneActionFailure,
  submitPhoneActionSuccess,
  getVehicleListSuccessAction,
  setSelectedTypesAction,
  setGeneralContractAgreedAction,
  setPayrollContractAgreedAction,
  setEmergencyPhoneAction,
  setCarSelectedAction,
  setMotorcycleSelectedAction,
  setBikeSelectedAction,
  setDecalTypeSelectedAction
} from "./order-decals.actions";
import { getCommuterStatusSuccessAction } from "../../../core/store/auth/auth.actions";

export const initialOrderDecalsState: IOrderDecalsState = {
  decalTypes: [],
  stepNumber: 1,
  vehicleList: [],
  isStep1Disabled: false,
  emergencyPhone: undefined,
  generalContractAgreed: false,
  payrollContractAgreed: false,
  vehicleDecalPairs: [],
  decalSubmissionResults: [],

  carsSelected: false,
  motorcyclesSelected: false,
  bikesSelected: false,
  isVehicleStepComplete: false,
  isDecalStepComplete: false,

  isPhoneSubmitting: false,
  phoneSubmitted: false,
  isGeneralAgreementSubmitting: false,
  generalAgreementSubmitted: false,
  isPayrollAgreementSubmitting: false,
  payrollAgreementSubmitted: false,

  decalsSubmitted: false,
  isDecalsSubmitting: false,
};

const orderDecalsReducer = createReducer(
  initialOrderDecalsState,

  on(resetOrderProcessAction,
    (state): IOrderDecalsState => ({
      ...initialOrderDecalsState,
      isStep1Disabled: state.isStep1Disabled
    })
  ),

  on(setStepNumberAction,
    (state, action): IOrderDecalsState => ({
      ...state,
      stepNumber: action.stepNum
    })
  ),

  on(setVehicleListAction,
    (state, action): IOrderDecalsState => ({
      ...state,
      vehicleList: action.vehicleList
    })
  ),

  on(getVehicleListSuccessAction,
    (state, action): IOrderDecalsState => ({
      ...state,
      vehicleList: action.vehicleList
    })
  ),

  on(setDecalTypeSelectedAction,
    (state, action): IOrderDecalsState => ({
      ...state,
      decalTypes: action.decalType,
      vehicleDecalPairs: action.pairs
    })
  ),

  on(setDecalTypeAction,
    (state): IOrderDecalsState => ({
      ...state,
      isDecalStepComplete: true
    })
  ),

  on(getCommuterStatusSuccessAction,
    (state, action): IOrderDecalsState => ({
      ...state,
      isStep1Disabled: !action.isCommuter
    })
  ),

  on(submitPhoneAction,
    (state): IOrderDecalsState => ({
      ...state,
      isPhoneSubmitting: true
    })
  ),

  on(submitPhoneActionSuccess,
    (state): IOrderDecalsState => ({
      ...state,
      isPhoneSubmitting: false,
      phoneSubmitted: true
    })
  ),

  on(submitPhoneActionFailure,
    (state): IOrderDecalsState => ({
      ...state,
      isPhoneSubmitting: false
    })
  ),

  on(submitGeneralAgreementAction,
    (state): IOrderDecalsState => ({
      ...state,
      isGeneralAgreementSubmitting: true
    })
  ),

  on(submitGeneralAgreementActionSuccess,
    (state): IOrderDecalsState => ({
      ...state,
      isGeneralAgreementSubmitting: false,
      generalAgreementSubmitted: true
    })
  ),

  on(submitGeneralAgreementActionFailure,
    (state): IOrderDecalsState => ({
      ...state,
      isGeneralAgreementSubmitting: false
    })
  ),

  on(submitPayrollAgreementAction,
    (state): IOrderDecalsState => ({
      ...state,
      isPayrollAgreementSubmitting: true
    })
  ),

  on(submitPayrollAgreementActionSuccess,
    (state): IOrderDecalsState => ({
      ...state,
      isPayrollAgreementSubmitting: false,
      payrollAgreementSubmitted: true
    })
  ),

  on(submitPayrollAgreementActionFailure,
    (state): IOrderDecalsState => ({
      ...state,
      isPayrollAgreementSubmitting: false
    })
  ),

  on(submitDecalsAction,
    (state): IOrderDecalsState => ({
      ...state,
      isDecalsSubmitting: true
    })
  ),

  on(submitDecalsActionSuccess,
    (state, action): IOrderDecalsState => ({
      ...state,
      isDecalsSubmitting: false,
      decalsSubmitted: true,
      decalSubmissionResults: action.results
    })
  ),

  on(submitDecalsActionFailure,
    (state): IOrderDecalsState => ({
      ...state,
      isDecalsSubmitting: false
    })
  ),

  on(setSelectedTypesAction,
    (state, action): IOrderDecalsState => ({
      ...state,
      isVehicleStepComplete: true
    })
  ),

  on(setGeneralContractAgreedAction,
    (state, action): IOrderDecalsState =>({
      ...state,
      generalContractAgreed: action.hasAgreed
    })
  ),

  on(setPayrollContractAgreedAction,
    (state, action): IOrderDecalsState =>({
      ...state,
      payrollContractAgreed: action.hasAgreed
    })
  ),

  on(setEmergencyPhoneAction,
    (state, action): IOrderDecalsState =>({
      ...state,
      emergencyPhone: action.phone
    })
  ),

  on (setCarSelectedAction,
    (state, action): IOrderDecalsState =>({
      ...state,
      carsSelected: action.hasSelected
    })
  ),

  on (setMotorcycleSelectedAction,
    (state, action): IOrderDecalsState =>({
      ...state,
      motorcyclesSelected: action.hasSelected
    })
  ),

  on (setBikeSelectedAction,
    (state, action): IOrderDecalsState =>({
      ...state,
     bikesSelected: action.hasSelected
    })
  ),

  /*
  This checks to see if the name property has already been loaded into the state.
  If it has, then we just return the current state. We know that the effect on this action won't try to load anything
  from the API, so we don't want our state to show that it's loading anything.
  Otherwise, we'll set the loading indicator because we're going to have to load it, and then either the Success or
  Failure will reset it once it is finished.
   */
  // on(getUserNameAction,
  //   (state): IAuthState => {
  //     return !!state.name ? state : ({
  //       ...state,
  //       isLoadingUser: true
  //     })
  //   }
  // ),


);

export function orderDecalsReducers(state: IOrderDecalsState, action: Action) {
  return orderDecalsReducer(state, action);
}
