import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatChipsModule} from '@angular/material/chips';
import { MatDatepickerModule } from "@angular/material/datepicker";
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSliderModule} from '@angular/material/slider';
import { MatSortModule } from "@angular/material/sort";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import { FocusTrapDirective } from './focus-trap/focus-trap.directive';
import { ModalComponent } from "./modal/modal.component";
import { TextblockComponent } from './textblock/textblock.component';
import { VehicleListComponent } from './vehicle-list/vehicle-list.component';
import { VehicleAddModal } from './modal/vehicle-add-modal/vehicle-add-modal.component';
import { VehicleDeleteModal } from './modal/vehicle-delete-modal/vehicle-delete-modal.component';
import { DecalTypeIconComponent } from './decal-type-icon/decal-type-icon.component';
import { IconComponent } from './icon/icon.component';
import { LoadingSpinner } from './loading-spinner/loading-spinner.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatSelectModule,
    MatRadioModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatSliderModule,
    MatCardModule,
    MatChipsModule,
    MatMenuModule,
    MatTooltipModule,
    MatProgressSpinnerModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    FocusTrapDirective,
    ReactiveFormsModule,
    MatTableModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatSelectModule,
    MatRadioModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatSliderModule,
    MatCardModule,
    MatChipsModule,
    ModalComponent,
    TextblockComponent,
    VehicleListComponent,
    VehicleAddModal,
    VehicleDeleteModal,
    MatMenuModule,
    DecalTypeIconComponent,
    IconComponent,
    MatTooltipModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    LoadingSpinner,
  ],
  declarations: [
    FocusTrapDirective,
    ModalComponent,
    TextblockComponent,
    VehicleListComponent,
    VehicleAddModal,
    VehicleDeleteModal,
    DecalTypeIconComponent,
    IconComponent,
    LoadingSpinner,
  ]
})
export class SharedModule { }
