import { Injectable } from '@angular/core';
import { IDecalType } from '../models/decal-type.model';
import { IDecalRestriction } from '../models/decal-restriction.model';
import { Observable } from 'rxjs';
import { DecalTypeRepository } from '../repository/decal-type.repository';

@Injectable({
  providedIn: 'root'
})

export class DecalTypeService {
  constructor(private decalTypeRepo: DecalTypeRepository) {}

  public getDecalTypes(year: number): Observable<IDecalType[]> {
    return this.decalTypeRepo.getDecalTypes(year);
  }

  public getDecalType(decalId: number): Observable<IDecalType> {
    return this.decalTypeRepo.getDecalType(decalId);
  }

  public getDecalRestriction(decalId: number): Observable<IDecalRestriction[]> {
    return this.decalTypeRepo.getDecalRestriction(decalId);
  }

  public addDecalType(decalType: IDecalType): Observable<IDecalType> {
    return this.decalTypeRepo.addDecalType(decalType);
  }

  public updateDecalType(decalType: IDecalType): Observable<IDecalType> {
    return this.decalTypeRepo.updateDecalType(decalType);
  }
}
