import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {UserRepository} from "../../../repository/user.repository";
import {
  endImpersonateUserAction,
  endImpersonateUserFailureAction,
  endImpersonateUserSuccessAction, getCommuterStatusAction,
  impersonateUserAction,
  impersonateUserFailureAction,
  impersonateUserSuccessAction
} from "../auth.actions";
import {exhaustMap, map, switchMap} from "rxjs/operators";
import {catchError, of, tap} from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {resetOrderProcessAction} from "../../../../feature/order-process/store/order-decals.actions";

@Injectable()
export class ImpersonateEffect {

  // noinspection TypeScriptValidateTypes
  impersonateUser$ = createEffect(() => this.actions$.pipe(
    ofType(impersonateUserAction),
    exhaustMap(({username}) => {
      return this.userRepo.impersonateUser(username).pipe(
        map(response => impersonateUserSuccessAction({data: response})),
        catchError((errorResponse: HttpErrorResponse) => of(impersonateUserFailureAction({error: errorResponse})))
      );
    })
  ));

  // noinspection TypeScriptValidateTypes
  endImpersonateUser$ = createEffect(() => this.actions$.pipe(
    ofType(endImpersonateUserAction),
    exhaustMap(() => {
      return this.userRepo.stopImpersonating().pipe(
        map(response => endImpersonateUserSuccessAction({data: response})),
        catchError((errorResponse: HttpErrorResponse) => of(endImpersonateUserFailureAction({error: errorResponse})))
      );
    })
  ));

  // noinspection TypeScriptValidateTypes
  redirectAfterImpersonate$ = createEffect(() => this.actions$.pipe(
    ofType(
      impersonateUserSuccessAction,
      endImpersonateUserSuccessAction
    ),
    tap(() => this.router.navigateByUrl('/'))
  ), {dispatch: false});

  // noinspection TypeScriptValidateTypes
  resetOrderAfterImpersonate$ = createEffect(() => this.actions$.pipe(
    ofType(
      impersonateUserSuccessAction,
      endImpersonateUserSuccessAction
    ),
    switchMap(() => of(resetOrderProcessAction()))
  ));

  // noinspection TypeScriptValidateTypes
  updateCommuterAfterImpersonate$ = createEffect(() => this.actions$.pipe(
    ofType(
      impersonateUserSuccessAction,
      endImpersonateUserSuccessAction
    ),
    switchMap(() => of(getCommuterStatusAction()))
  ));

  constructor(private actions$: Actions,
              private router: Router,
              private store: Store,
              private userRepo: UserRepository) {
  }
}
