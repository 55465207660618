<h1><b>Vehicles</b></h1>
<div>This is a list of your registered vehicles. To add a new vehicle, click the "Add Vehicle" button at the bottom of this list. You should add new vehicles prior to ordering new decals.</div>

<div *ngIf="userVehicleList" class="vehicleListWrapper">
  <table class="table table-responsive table-responsive-sm">
    <tbody>
      <app-vehicle-list [vehicleList]="userVehicleList" [showHeader]=true [showDelete]="true" [showEdit]="true" (handleDeleteEvent)="deleteVehicle($event)" (handleEditEvent)="openModal($event)"></app-vehicle-list>
    </tbody>
  </table>
</div>

<div class="add-button-container">
  <button type="button" class="addButton" (click)="openModal(undefined)">Add Vehicle</button>
</div>

<hr>

<div *ngIf="archivedVehicleList">
  <div class="archived-header">
    <h1><b>Archived Vehicles</b></h1>
  </div>
  <div id="archivedVehicleList" class="vehicleListWrapper">
    <table class="table table-responsive table-responsive-sm">
        <tbody>
          <app-vehicle-list [vehicleList]="archivedVehicleList" [showHeader]=true [showDelete]="false" [showEdit]="false"></app-vehicle-list>
        </tbody>
    </table>
  </div>
</div>

<app-modal (closeModalEvent)="closeModal()" *ngIf="isModalOpen" appTrapFocus>
  <form class="modalContent" [formGroup]="vehicleAddForm" (ngSubmit)="submitAddForm()">
    <h3 [innerHtml]="modalTitle"></h3>
    <label for="vehicleType">Vehicle Type</label>
    <select
      id="vehicleType"
      formControlName="vehicleType"
      (change)="changeVehicleType(vehicleType)"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['vehicleType'].errors}"
      required
      data-cy="vehicleType-select"
    >
      <option [ngValue]="selectedVehicleOption" selected disabled>Select Vehicle Type</option>
      <option *ngFor="let vehicleType of vehicleTypes" [ngValue]="vehicleType">{{vehicleType.text}}</option>
    </select>
    <div *ngIf="formSubmitted && form['vehicleType'].errors" class="invalid-feedback">
      <div *ngIf="form['vehicleType'].errors['required']">Please select a vehicle type.</div>
    </div>

    <label for="vehicleMake">Vehicle Make</label>
    <select
      id="vehicleMake"
      formControlName="vehicleMake"
      (change)="changeVehicleMake(vehicleMake)"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['vehicleMake'].errors}"
      required
      data-cy="vehicleMake-select"
    >
      <option [ngValue]="selectedVehicleOption" selected disabled>Select Vehicle Make</option>
      <option *ngFor="let vehicleMake of filteredMakes" [ngValue]="vehicleMake" >{{vehicleMake.text}}</option>
    </select>
    <div *ngIf="formSubmitted && form['vehicleMake'].errors" class="invalid-feedback">
      <div *ngIf="form['vehicleMake'].errors['required']">Please select a vehicle make. If you cannot find your Make, select "Other".</div>
    </div>

    <label for="vehicleModel">Vehicle Model</label>
    <select
      id="vehicleModel"
      formControlName="vehicleModel"
      (change)="changeVehicleModel(vehicleModel)"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['vehicleModel'].errors}"
      required
      data-cy="vehicleModel-select"
    >
      <option [ngValue]="selectedVehicleOption" selected disabled>Select Vehicle Model</option>
      <option *ngFor="let vehicleModel of vehicleModels" [ngValue]="vehicleModel">{{vehicleModel.text}}</option>
    </select>
    <div *ngIf="formSubmitted && form['vehicleModel'].errors" class="invalid-feedback">
      <div *ngIf="form['vehicleModel'].errors['required']">Please select a vehicle model. If you cannot find your Model, select "Other".</div>
    </div>

    <label for="otherModal" *ngIf="showOtherModel">Other Model</label>
    <input
      id="otherModal"
      *ngIf="showOtherModel"
      type="text"
      placeholder=""
      formControlName="otherModel"
      class="form-control"
      data-cy="otherModel-text"
    />

    <label for="vehicleColor">Vehicle Color</label>
    <select
      id="vehicleColor"
      formControlName="vehicleColor"
      (change)="changeVehicleColor(vehicleColor)"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['vehicleColor'].errors}"
      required
      data-cy="vehicleColor-select"
    >
      <option [ngValue]="selectedVehicleOption" selected disabled>Select Vehicle Color</option>
      <option *ngFor="let vehicleColor of vehicleColors" [ngValue]="vehicleColor">{{vehicleColor.text}}</option>
    </select>
    <div *ngIf="formSubmitted && form['vehicleColor'].errors" class="invalid-feedback">
      <div *ngIf="form['vehicleColor'].errors['required']">Please select a vehicle color.</div>
    </div>

    <label for="vehicleYear">Vehicle Year</label>
    <input
      id="vehicleYear"
      type="text"
      placeholder="Year"
      formControlName="yearOfVehicle"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['yearOfVehicle'].errors}"
      required
      data-cy="yearOfVehicle-text"
    />
    <div *ngIf="formSubmitted && form['yearOfVehicle'].errors" class="invalid-feedback">
      <div *ngIf="form['yearOfVehicle'].errors['required']">Please enter a 4 digit year.</div>
    </div>

    <label for="vehicleLicense">License Plate Number</label>
    <input
      id="vehicleLicense"
      type="text"
      placeholder="License Plate #"
      formControlName="licenseNumber"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['licenseNumber'].errors}"
      required
      data-cy="licenseNumber-text"
    />
    <div *ngIf="formSubmitted && form['licenseNumber'].errors" class="invalid-feedback">
      <div *ngIf="form['licenseNumber'].errors['required']">Please enter your license plate number.</div>
    </div>

    <label for="vehicleState">License State</label>
    <select
      id="vehicleState"
      formControlName="vehicleStateCode"
      (change)="changeStateCode(vehicleStateCode)"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['vehicleStateCode'].errors}"
      required
      data-cy="vehicleStateCode-select"
    >
      <option [ngValue]="selectedVehicleOption" selected disabled>Select State</option>
      <option *ngFor="let vehicleStateCode of stateCodes" [ngValue]="vehicleStateCode.abbreviation">{{vehicleStateCode.name}}</option>
    </select>
    <div *ngIf="formSubmitted && form['vehicleStateCode'].errors" class="invalid-feedback">
      <div *ngIf="form['vehicleStateCode'].errors['required']">Please select a State/Province.</div>
    </div>

    <fieldset>
      <label for="vehicleInsured" class="insured">
        <input id="vehicleInsured" type="checkbox" formControlName="vehicleInsured" [value]="vehicleInsured" [checked]="vehicleInsured" data-cy="vehicleInsured-select"/>
        This vehicle has insurance
      </label>
    </fieldset>
    <label for="vehicleElectric">Electric</label>
    <select
      id="vehicleElectric"
      formControlName="electricVehicle"
      (change)="changeElectricVehicle(electricVehicle)"
      class="form-control"
      [ngClass]="{'is-invalid': formSubmitted && form['electricVehicle'].errors}"
      required
      data-cy="electricVehicle-select"
    >
      <option [ngValue]="selectedVehicleOption" selected disabled>Is this an electric vehicle?</option>
      <option *ngFor="let electricVehicle of electricVehicles" [ngValue]="electricVehicle">{{electricVehicle}}</option>
    </select>
    <div *ngIf="formSubmitted && form['electricVehicle'].errors" class="invalid-feedback">
      <div *ngIf="form['electricVehicle'].errors['required']">Please select yes or no.</div>
    </div>

    <button type="submit">Save</button>
  </form>
</app-modal>
