import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserRepository } from '../../repository/user.repository';
import { MessageService } from '../message.service';

@Injectable({providedIn: 'root'})
export class AuthService implements HttpInterceptor {

  constructor(private userRepository: UserRepository, private messageService: MessageService) { }

  /**
   * Intercept all requests to ensure user is authenticated
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    request = request.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        credentials: 'include'
      },
      withCredentials: true,
    });

    return next.handle(request).pipe(
      catchError(response => this.handleError(response)));
  }

  private handleError(response: HttpErrorResponse):Observable<any> {
    if (response.status == 401 && !(response.url? response.url.indexOf(this.userRepository.impersonateUrl) >= 0: null)) {
      this.redirect(this.userRepository.unauthenticatedUrl);
      /* should never get here - only here for tests */
      return of(response);
    } else if (response.status == 401) {
      return this.showError('You must be logged in to perform this action.', response);
    } else if (response?.error?.message) {
      return this.showError(response.error.message, response);
    } else if (response?.error?.error) {
      return this.showError(response.error.error, response);
    } else {
      return this.showError(response.message, response);
    }
  }

  redirect(toUrl: string) {
    return window.location.replace(toUrl);
  }

  private showError(errorMessage:string, error: HttpErrorResponse): Observable<any> {
    this.messageService.showErrorMessage(`Error: ${errorMessage}`);
    return throwError(() => error);
  }
}
