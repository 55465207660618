import { createFeatureSelector, createSelector } from "@ngrx/store";
import { IAuthState } from "./auth.state";

export const authFeatureSelector = createFeatureSelector<IAuthState>('auth');

export const getUserNameSelector = createSelector(
  authFeatureSelector,
  state => state.name
);

export const getUserRolesSelector = createSelector(
  authFeatureSelector,
  state => state.roles
);

export const isAdminSelector = createSelector(
  authFeatureSelector,
  state => !!state.roles && state.roles.includes('ROLE_LUPD_ADMIN')
)

export const isLupdStaffSelector = createSelector(
  authFeatureSelector,
  state => !!state.roles && state.roles.includes('ROLE_LUPD_STAFF')
)

export const canImpersonateSelector = createSelector(
  authFeatureSelector,
  state => !!state.roles && state.roles.includes('ROLE_LUPD_SWITCH_USER')
)

export const isCommuterSelector = createSelector(
  authFeatureSelector,
  state => state.isCommuter
)

export const isCommuterLoadingSelector = createSelector(
  authFeatureSelector,
  state => state.isLoadingCommuter
)

export const isEmployeeSelector = createSelector(
  authFeatureSelector,
  state => !!state.isEmployee
)

export const isImpersonatingSelector = createSelector(
  authFeatureSelector,
  state => !!state.roles && state.roles.some(role => role.includes("ROLE_PREVIOUS_ADMINISTRATOR"))
)

export const isLoadingUserOrRolesSelector = createSelector(
  authFeatureSelector,
  state => state.isLoadingUser || state.isLoadingRoles
)

export const hasDecalSelectionRoleSelector = createSelector(
  authFeatureSelector,
  state => !!state.roles && state.roles.some(role => role.includes("ROLE_LUPD_DECAL_SELECTION"))
)
