import { Injectable } from '@angular/core';
import {IVehicleColor} from "../models/vehicle-color.model";
import {Observable} from "rxjs";
import {VehicleColorRepository} from "../repository/vehicle-color.repository";

@Injectable({
  providedIn: 'root'
})
export class VehicleColorService {

  constructor(private vehicleColorRepo: VehicleColorRepository) { }

  public getVehicleColors(): Observable<IVehicleColor[]> {
    return this.vehicleColorRepo.getVehicleColors();
  }

  public add(color: string): Observable<IVehicleColor> {
    return this.vehicleColorRepo.add(color);
  }

  public update(color: IVehicleColor): Observable<IVehicleColor> {
    return this.vehicleColorRepo.update(color);
  }
}
