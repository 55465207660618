<h1 data-cy="confirm-commuter-title">Confirm Your Address</h1>

<div class="intro" data-cy="intro-line">
  {{address ? 'This is your current' : 'You do not have a '  }}
  <b>Local Commuter address</b> on file.
  If you are a commuter student, this is the address that will be used when your decal is mailed to you.
  {{address ?
    'You may update your address at any time before the decal is mailed using the button below.' :
    'To proceed, you must first update your address using the link below. Then, refresh this page to continue. '}}

</div>

<div class="address" data-cy="address-line-1" *ngIf="address && address.street1">
  {{address.street1}}
</div>
<div  class="address" data-cy="address-line-2" *ngIf="address && address.street2">
  {{address.street2}}
</div>
<div  class="address" data-cy="address-line-3" *ngIf="address && address.street3">
  {{address.street3}}
</div>
<div class="address" data-cy="address-city-state-zip" *ngIf="address && address.city">
  {{address.city}}, {{address.stateCode}} {{address.zip}}
</div>

<div class="mylu-link">
  <a mat-button color="primary" href="https://mylu.liberty.edu/settings/contact" target="_blank" rel="noopener noreferrer">
    <mat-icon>edit</mat-icon> Update address on myLU
  </a>
</div>

<div class="next">
  <button class="nextButton" mat-button matStepperNext [disabled]="(isCommuter$ | async) && !address">Select Vehicles</button>
</div>
